<div class="gender-container">
	<label class="custom-radio-button__container">
		<input type="radio" name="radio" value="1">
		<span class="custom-radio-button designer">
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" class="svg-gender">
				<g transform="translate(-1056.24 -5091.465)">
					<circle class="a" cx="7.586" cy="7.586" r="7.586" transform="translate(1057.24 5097.292)"/>
					<line class="a" x1="7.807" y2="7.807" transform="translate(1069.433 5092.465)"/>
					<path class="a" d="M1094.923,5092.465h5.521v5.521" transform="translate(-23.203)"/>
				</g>
			</svg>
			Uomo
		</span>
	</label>

	<label class="custom-radio-button__container">
		<input type="radio" name="radio" value="2">
		<span class="custom-radio-button designer">
			<svg xmlns="http://www.w3.org/2000/svg" width="21.707" height="21.708" viewBox="0 0 21.707 21.708">
				<g transform="translate(-1131.446 -5091.758)">
					<circle class="a" cx="7.323" cy="7.323" r="7.323" transform="translate(1132.446 5097.82)"/>
					<line class="a" x1="7.536" y2="7.536" transform="translate(1144.91 5092.465)"/>
					<line class="a" x1="5.978" y1="5.978" transform="translate(1146.469 5092.465)"/>
				</g>
			</svg>
			Donna
		</span>
	</label>

	<label class="custom-radio-button__container">
		<input type="radio" name="radio" value="3">
		<span class="custom-radio-button designer">
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.861" viewBox="0 0 22 21.861">
				<g transform="translate(-1199.966 -5091.465)">
					<circle class="a" cx="7.272" cy="7.272" r="7.272" transform="translate(1200.966 5097.782)"/>
					<line class="a" x1="7.483" y2="7.483" transform="translate(1213.343 5092.465)"/>
					<line class="a" x1="5.936" y1="5.936" transform="translate(1213.343 5094.013)"/>
					<path class="a" d="M1239.008,5092.466h5.292v5.291" transform="translate(-23.334 0)"/>
				</g>
			</svg>
			Altro
		</span>
	</label>
</div>