<div class="fileinput text-center w-100 p-2">
  <div class="thumbnail img-raised overlay-photo">

    <div *ngIf="isLoadingImage" class="skeleton-image"></div>

    <img *ngIf="state.imageUploaded != null" [src]="state.imageUploaded" alt="image-url-preview" (load)='isLoadingImage=false'/>

    <img *ngIf="state.imageUploaded == null" [src]="previousImage ? ((assetURL + previousImage) | jwtFilePipe | async) : placeholder" class="img-preview-overlay" (load)='isLoadingImage=false' alt="image-preview"/>

    <div *ngIf="state.imageUploaded == null && previousImage" class="overlay-photo-btn button-circle w-100 d-flex flex-column justify-content-center">
      <div class="overlay-photo-btn-area" (click)="openModal(assetURL + previousImage)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
              <g id="Circle_Button" data-name="Circle Button" transform="translate(-0.475)">
                  <g id="Area" transform="translate(0.475)" fill="#A7061E" stroke="none" stroke-width="2">
                      <rect width="40" height="40" rx="24" stroke="none"/>
                      <rect x="1" y="1" width="38" height="38" rx="23" fill="none"/>
                  </g>
                  <g id="Icon" transform="translate(10 10)">
                      <rect id="Area-2" data-name="Area" width="12" height="12" transform="translate(0.475)" fill="#fcfcfc" opacity="0"/>
                      <g id="Icon-2" data-name="Icon" transform="translate(0.833 3.333)">
                          <path id="Path" d="M.833,10S4.167,3.333,10,3.333,19.167,10,19.167,10,15.833,16.667,10,16.667.833,10,.833,10Z" transform="translate(-0.833 -3.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.667"/>
                          <circle id="Path-2" data-name="Path" cx="2.5" cy="2.5" r="2.5" transform="translate(6.667 4.167)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.667"/>
                      </g>
                  </g>
              </g>
          </svg>
          <span>Visualizza</span>
      </div>
    </div>

  </div>
  <div>

    <div class="button-circle">
      <!--<button class="btn btn-circle" (click)="handleClick()" *ngIf="!this.state.file">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="svg-icons size-20 white"><g transform="translate(-944 -347)"><line class="a" y2="12" transform="translate(951 348)"/><line class="a" x2="12" transform="translate(945 354)"/></g></svg>
      </button>-->
      <button class="btn btn-primary btn-round btn-icon" (click)="handleClick()" *ngIf="!this.state.file"><i class="now-ui-icons ui-1_simple-add"></i></button>
      <button class="btn btn-primary btn-round btn-icon" (click)="downloadFile()" *ngIf="image"><i class="now-ui-icons arrows-1_cloud-download-93"></i></button>
      <button class="btn btn-primary btn-round btn-icon" (click)="handleRemove()" *ngIf="this.state.file"><i class="now-ui-icons ui-1_simple-remove"></i></button>
      <span class="text-right">{{label}}</span>
    </div>

    <!--<button
      class="btn btn-raised btn-round btn-default btn-file"
      (click)="handleClick()"
      *ngIf="this.state.file"
    >
      Change
    </button>
    -->

  </div>
  <input #input type="file" [name]="formCtrlName" (change)="handleImageChange($event)"/>
</div>


<!-- Modal component used to show the image clicked -->
<!--<ngbd-modal-component [path]="path | jwtFilePipe | async" [useFilePipe]="false"></ngbd-modal-component>-->
<ngbd-modal-component [path]=" path ? (path | jwtFilePipe | async) : undefined " [useFilePipe]="false"></ngbd-modal-component>