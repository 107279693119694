<button
  class="btn btn-primary border-radius-small mb-2 d-flex align-items-center justify-content-between p-2 px-3 add-button"
  (click)="openModal('addExerciseModal')">
  <p class="m-0" style="font-size: 15px; font-weight: 600">Aggiungi</p>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" class="svg-icons dark ml-1">
    <g transform="translate(-944 -347)">
      <line class="a" y2="12" transform="translate(951 348)" />
      <line class="a" x2="12" transform="translate(945 354)" />
    </g>
  </svg>
</button>

<!-- Modal to add exercise -->
<ng-template #addExerciseModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-between">
    <h6 class="modal-title">Aggiungi esercizio al piano di allenamento</h6>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"><i class="now-ui-icons ui-1_simple-remove"></i></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-9">
        <!--<span class="badge badge-pill badge-outline mr-1 badge-type-food" (click)="loadIndexExercise()">Tutti</span>-->
        <span
          class="badge badge-pill badge-outline mr-1 badge-type-food"
          [class.active]="selectedGroup == item.id"
          *ngFor="let item of gruppiMuscolari"
          id="{{ item.id }}"
          (click)="loadIndexExercise(item.id)">
          {{ item.name }}
        </span>
      </div>
      <div class="col-sm-3">
        <app-store-exercise *ngIf="currentUser.is_admin"></app-store-exercise>
      </div>
    </div>
    <div class="">
      <ng2-smart-table
        *ngIf="addExercise_Settings"
        [settings]="addExercise_Settings"
        [source]="addExercise_Source | filter : selectedGroup"
        (rowSelect)="deselectFirstRow()"></ng2-smart-table>
    </div>
  </div>
</ng-template>

<!-- Modal to select an exercise name-->
<ng-template #selectExerciseModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-between">
    <h6 class="modal-title">Seleziona un esercizio</h6>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"><i class="now-ui-icons ui-1_simple-remove"></i></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-9">
        <span
          class="badge badge-pill badge-outline mr-1 badge-type-food"
          [class.active]="selectedGroup == item.id"
          *ngFor="let item of gruppiMuscolari"
          id="{{ item.id }}"
          (click)="loadIndexExercise(item.id)">
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="">
      <ng2-smart-table
        *ngIf="selectExercise_Settings"
        [settings]="selectExercise_Settings"
        [source]="addExercise_Source | filter : selectedGroup"
        (rowSelect)="deselectFirstRow()"></ng2-smart-table>
    </div>
  </div>
</ng-template>

<!-- Modal to pass data and assign exercise -->
<ng-template #assignExerciseModal let-c="close" let-d="dismiss">
  <div class="modal-header dark-modal border-top-rad">
    <div class="d-flex align-items-center col-6 nav-link__wrap">
      <h5 class="mr-3 mb-0">{{ exerciseName }}</h5>
      <div class="nav-link__wrap-btn">
        <button class="btn btn-circle btn-edit" (click)="exerciseSelect()">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.252" height="22.017" viewBox="0 0 22.252 22.017">
            <g id="Icon" transform="translate(1.009 1.008)">
              <rect id="Area" width="20" height="20" transform="translate(0)" fill="#fcfcfc" opacity="0" />
              <g id="Icon-2" data-name="Icon" transform="translate(0 0)">
                <path
                  id="_06136649-4ab5-4f99-b5ff-60c5d3a39d14"
                  data-name="06136649-4ab5-4f99-b5ff-60c5d3a39d14"
                  d="M17.147,3.026a2.853,2.853,0,0,1,4.035,4.035L7.565,20.677,2.017,22.19,3.53,16.643Z"
                  transform="translate(-2.017 -2.191)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.017" />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <button type="button" class="close" (click)="d('Cross click')" style="color: #fff">
      <i class="now-ui-icons ui-1_simple-remove"></i>
    </button>
  </div>
  <div class="modal-body dark-modal border-bottom-rad">
    <form>
      <div class="my-2">
        <select
          class="dark-input"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="selectedSeries"
          (change)="selectSeries()"
          [disabled]="exerciseTypeId == 13">
          <option *ngIf="exerciseTypeId != 13" value="0" disabled>Numero di serie</option>
          <option value="1">1</option>
          <option *ngIf="exerciseTypeId != 13" value="2">2</option>
          <option *ngIf="exerciseTypeId != 13" value="3">3</option>
          <option *ngIf="exerciseTypeId != 13" value="4">4</option>
          <option *ngIf="exerciseTypeId != 13" value="5">5</option>
          <option *ngIf="exerciseTypeId != 13" value="6">6</option>
          <option *ngIf="exerciseTypeId != 13" value="7">7</option>
          <option *ngIf="exerciseTypeId != 13" value="8">8</option>
          <option *ngIf="exerciseTypeId != 13" value="9">9</option>
          <option *ngIf="exerciseTypeId != 13" value="10">10</option>
        </select>
      </div>
      <div *ngFor="let n of arraySelectedSeries; let i = index; let isFirst = first" class="row align-items-center mt-1" id="row_series_{{ i }}">
        <div class="col-md-2">
          <input type="number" min="0" placeholder="Serie {{ i + 1 }}" readonly class="dark-input w-100" />
        </div>
        <div class="col-md-2">
          <div class="form-check">
            <label class="form-check-label">
              <input
                class="form-check-input"
                [(ngModel)]="exercisesData[i].is_time"
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
                id="s{{ i }}_is_time"
                [disabled]="exerciseTypeId == 13" />
              <span class="form-check-sign"></span>
              a tempo
            </label>
          </div>
        </div>
        <div class="col-md-2">
          <input
            type="text"
            [(ngModel)]="exercisesData[i].repetitions"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="exercisesData[i].is_time ? 'Tempo in minuti' : 'Ripetizioni'"
            class="white-input w-100"
            id="ripetizioni_or_time_{{ i }}" />
        </div>
        <div class="col-md-2">
          <input
            *ngIf="isFirst"
            type="number"
            min="0"
            [(ngModel)]="exercisesData[i].cooldown"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="exerciseTypeId != 13 ? 'Recupero' : 0"
            class="white-input w-100"
            [readOnly]="exerciseTypeId == 13" />
        </div>
        <div class="col-md-4">
          <angular2-multiselect
            id="s{{ i }}_technique"
            [data]="dropdownListHowTo"
            [(ngModel)]="exercisesData[i].technique"
            [ngModelOptions]="{ standalone: true }"
            [settings]="dropdownSettingsHowTo"></angular2-multiselect>
        </div>
      </div>
      <div class="form-group bckg-body mt-3">
        <label class="label-textarea">Note</label>
        <textarea
          class="custom-textarea bckg-body"
          name="note"
          id="note"
          value="{{ exerciseNote }}"
          placeholder="Scrivi qui..."
          style="border: 0px"></textarea>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
          <g transform="translate(0 1)">
            <rect class="a" width="15" height="15" transform="translate(0 0)" />
            <g transform="translate(1.518 0)">
              <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)" />
              <path
                class="b"
                d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z"
                transform="translate(-3 -1.5)" />
            </g>
          </g>
        </svg>
      </div>

      <div class="mt-2 float-right">
        <button *ngIf="!edit" type="button" class="btn dark-btn border-radius-small" (click)="assignExercise()">
          <span class="btn__text m-0">Assegna</span>
        </button>
        <button *ngIf="edit" type="button" class="btn dark-btn border-radius-small" (click)="editExercise()">
          <span class="btn__text m-0">Assegna</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
