<div class="main-section">
    <div *ngIf="currentUser.is_admin" class="row justify-content-end">
        <div class="col-md-4 col-lg-3">
            <button class="btn btn-primary border-radius-large m-0 w-100" (click)="openModalNewSupplement()">
                <svg xmlns="http://www.w3.org/2000/svg" width="23.14" height="23.14" viewBox="0 0 23.14 23.14" class="svg-icons dark mb-1">
                    <g id="Raggruppa_160163" data-name="Raggruppa 160163" transform="translate(21.726 21.726) rotate(180)">
                        <rect id="Rettangolo_140008" data-name="Rettangolo 140008" width="8.188" height="19.897" rx="4.094" transform="translate(14.069 0.453) rotate(45)" fill="none" stroke="#a7061e" stroke-width="2"/>
                        <path id="Rettangolo_140009" data-name="Rettangolo 140009" d="M4.383,0h0A4.383,4.383,0,0,1,8.766,4.383v6.268a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.383A4.383,4.383,0,0,1,4.383,0Z" transform="translate(14.113 0) rotate(45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                    </g>
                </svg>
                <p>Aggiungi nuovo integratore</p>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="container-table-custom col-sm-12 mt-3">
            <ng2-smart-table [ngClass]="{'d-none': isLoadingTableData}" [settings]="settings" [source]="source" (rowSelect)="deselectFirstRow()"></ng2-smart-table>
            
            <ng-container *ngIf="isLoadingTableData">
                <div *ngFor="let i of toIterator(10)" class="skeleton-text mb-2"></div>
            </ng-container>    
        </div>
    </div>
</div>

<!--     modal supplement      -->
<ng-template #supplementModal let-d="dismiss">
    <div class="modal-header justify-content-between">
        <div class="d-flex align-items-center">
            <svg class="mr-2" id="Livello_1" data-name="Livello 1" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.32 383.75">
                <path d="M386.6,103.34c-3.78-34.96-21.06-62.17-50.43-81.51-15.26-10.05-32.18-15.6-50.4-17.11-.59-.05-1.15-.33-1.73-.5h-15.4c-5.99,1.04-12.05,1.79-17.97,3.16-20.88,4.85-38.61,15.36-53.71,30.47-50.44,50.45-100.9,100.87-151.33,151.32-19.16,19.17-30.43,42.15-33.08,69.22-.08,.83-.36,1.64-.55,2.46v15.4c1.4,7.41,2.32,14.95,4.3,22.21,7.25,26.52,22.67,47.4,45.43,62.61,21.21,14.16,44.77,20.17,70.25,18.08,27.54-2.25,50.89-13.56,70.36-33.02,50.55-50.51,101.14-100.98,151.58-151.6,25.11-25.2,36.52-55.77,32.69-91.19Zm-253.62,70.21c-.89,1.43-2.1,2.69-3.3,3.9-17.05,17.17-34.31,34.12-51.11,51.54-9.95,10.31-14.67,23.15-15.31,37.48-.09,2.04-.39,4.18-1.17,6.04-1.41,3.37-5.06,5.03-8.8,4.47-3.64-.55-6.39-3.27-6.86-7.07-.13-1.02-.11-2.05-.08-3.09-.05-1.33,.04-2.68,.04-4.02,1.02-20.19,9.04-37.23,23.26-51.45,16.16-16.16,32.32-32.32,48.49-48.46,4.21-4.2,8.29-5.11,12.05-2.77,2.95,1.83,4.41,4.92,4.02,8.01,.24,1.8-.15,3.69-1.23,5.42Zm197.73-4.22c-22.79,23.68-46.38,46.58-69.62,69.82-.25,.25-.58,.44-.74,.56-36.26-36.24-72.28-72.23-108.45-108.39,.28-.31,.6-.68,.94-1.06-.31-.31-.63-.63-.94-.94,.55-.61,1.24-1.43,1.99-2.18,22.28-22.29,44.61-44.55,66.86-66.88,17.53-17.6,38.7-26.11,63.35-23.38,30.32,3.36,52.35,19.57,63.48,47.97,4.19,10.7,6.17,21.11,6,31.13,.23,19.39-7.51,37.4-22.87,53.35Z" style="fill: #A7061E;"/>
            </svg>
            <div>
                <h6 class="title" *ngIf="updateSupplement === false">Nuovo integratore</h6>
                <h6 class="title" *ngIf="updateSupplement === true">Modifica integratore: {{nameSupplementSelected}}</h6>
            </div>
        </div>
        <button type="button" class="close" (click)="closeModal()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body general-modal">
        <form [formGroup]="createSupplementForm">
            
            <div class="d-flex justify-content-between align-items-center w-100 custom-color">
                <input type="text" name="name" formControlName="name" id="name" class="form-control square-input bckg-body mr-2 pl-3" placeholder="Nome integratore" required/>
            </div>

            <div class="d-flex justify-content-start w-100 mt-2">
                <select id="type_id" class="p-2 dark-input col-5" formControlName="type_id">
                    <option value="" selcted disabled>Tipo integratore</option>
                    <option [value]="gs.id" *ngFor="let gs of groupSupplements">{{gs.name}}</option>
                </select>
                <select id="unitaMisura" class="p-2 dark-input col-5 ml-2" formControlName="unitaMisura">
                    <option value="" selcted disabled>Unità di misura</option>
                    <option value="g">g</option>
                    <option value="ml">ml</option>
                    <option value="unità">unità</option>
                    <option value="cucchiaio">cucchiaio</option>
                    <option value="bustina">bustina</option>
                    <option value="fiala">fiala</option>
                    <option value="capsule/compresse">capsule/compresse</option>
                    <option value="misurino">misurino</option>
                </select>
            </div>

            <div class="d-flex custom-color">

                <div class="form-group bckg-body w-100 mt-2 rounded pt-2 pl-3">
                    <span *ngIf="updateSupplement === true">Calorie:</span>
                    <input type="number" formControlName="calories" id="calories" name="calories" class="form-control square-input mr-2" placeholder="Calorie"/>
                </div>
                <div class="form-group bckg-body ml-2 w-100 rounded pt-2 pl-3 mt-2">
                    <span *ngIf="updateSupplement === true">Carboidrati:</span>
                    <input type="number" formControlName="carbohydrates" id="carbohydrates" name="carbohydrates" class="form-control square-input mr-2" placeholder="Carboidrati"/>
                </div>
            </div>

            <div class="d-flex custom-color">
                <div class="form-group bckg-body w-100 mt-2 rounded pt-2 pl-3">
                    <span *ngIf="updateSupplement === true">Proteine:</span>
                    <input type="number" formControlName="proteins" id="proteins" name="proteins" class="form-control square-input mr-2" placeholder="Proteine"/>
                </div>
                <div class="form-group bckg-body ml-2 w-100 rounded pt-2 pl-3 mt-2">
                    <span *ngIf="updateSupplement === true">Grassi:</span>
                    <input type="number" formControlName="fats" id="fats" name="fats" class="form-control square-input mr-2" placeholder="Grassi"/>
                </div>
            </div>

            <div class="mt-2 d-flex custom-color">
                <div class="form-group bckg-body w-100 mt-2 rounded pt-2 pl-3">
                    <span *ngIf="updateSupplement === true">Link:</span>
                    <input type="text" name="url" formControlName="url" id="url" class="form-control square-input bckg-body mr-2" placeholder="Link integratore" required/>
                    <small class="d-block mt-0" *ngIf="updateSupplement === false">inserire url completa. ES: "https://www.link.it</small>
                </div>
            </div>

            <div class="float-right">
                <button type="button" class="btn btn-primary border-radius-small w-100" *ngIf="updateSupplement === false" (click)="createNewSupplement()">
                    <span class="btn__text m-0">Aggiungi</span>
                </button>
            </div>

            <div class="float-right">
                <button type="button" class="btn btn-primary border-radius-small d-flex justify-content-center pl-5 pr-5" *ngIf="updateSupplement === true" (click)="updateSupplementSelected()">
                    <span class="btn__text m-0">Modifica</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>
