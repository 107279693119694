import { MessageFormatterService } from './../../../services/messageFormatter.service';
import { ValueFilterComponent } from '../../../components/value-filter/value-filter.component';
import { HeaderService } from './../../../services/header.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ServerDataSource } from "ng2-smart-table";
import { Subscription } from "rxjs";
import {PianoIntegrazioneService} from "../piano-integrazione.service";
import {ActionTableComponent} from "../../../components/action-table/action-table.component";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../../../components/confirm-dialog/confirm-dialog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-lista-integratori',
  templateUrl: './lista-integratori.component.html',
  styleUrls: ['./lista-integratori.component.css']
})
export class ListaIntegratoriComponent implements OnInit {

	//current user actually logged
	currentUser:any;

  source:  ServerDataSource;
	isLoadingTableData: boolean = true;
  settings: any;
  subscriptions: Subscription[] = [];
  @ViewChild('supplementModal') supplementModal: ElementRef;
  updateSupplement: boolean = false;
  nameSupplementSelected: string = "";
  createSupplementForm: FormGroup;
  groupSupplements = [];
  supplementSelectedForUpdate;
  request;
  supplementIdSelectedForUpdate: number;

  constructor(
      private pianoIntegrazioneService: PianoIntegrazioneService,
      private toastr: ToastrService,
      private messageFormatter: MessageFormatterService,
      private confirmDialogService: ConfirmDialogService,
      private modalService: NgbModal,
      private fb: FormBuilder,
      private headerService: HeaderService
  ) { }

  ngOnInit(): void {
		this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    this.loadSupplementList();
    this.loadTableSettings();
    this.setupFormCreateSupplement();
		this.headerService.setHeaderTitle('Lista integratori');
  }

  loadSupplementList() {
    this.source= this.pianoIntegrazioneService.getSupplements();
		this.source.onChanged().subscribe(() => {
			this.isLoadingTableData=false;
		});
  }

  loadTableSettings() {
    this.settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      columns: {
        name: {
          title: 'Nome piano',
          type: "html",
					filter: {
						type: 'custom',
						component: ValueFilterComponent
					},
          sort: false,
          valuePrepareFunction: (cell) => {
            if (cell) {
              return '<span class="black-bold"><img src="../../../assets/img/coffie.png" alt="">' + cell + '</span>';
            }
          },
        },
        dettaglio: {
          title: this.currentUser.is_admin ? "Azioni" : '',
          type: "custom",
          filter: false,
          sort: false,
          position: "right",
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            instance.actionEmit.subscribe(value => {
              if (value === "elimina") {
                this.deleteSupplement(instance.rowData.id, instance.rowData.name);
              } else if (value === "modifica") {
                this.updateSupplement = true;
                this.updateClickSupplement(instance.rowData.id, instance.rowData.name);
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const actions = [
              {
                text: "modifica",
                link: "",
                emit: "modifica",
                title: "Modifica piano",
                btnId: "modifica_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-edit ",
                classList: "action-btn btn",
              },
              {
                text: "elimina",
                link: "",
                emit: "elimina",
                title: "Elimina alimento",
                btnId: "elimina_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-times ",
                classList: "action-btn btn",
              },
            ];
            if(this.currentUser.is_admin) {
              return actions;
            } else {
              return null;
            }
          },
        },
      }
    };
  }

  openModalNewSupplement() {
    this.modalService.open(this.supplementModal, { size: 'lg', backdrop: 'static', windowClass: 'mediumModal', centered: true});
    this.loadTypesSupplements();
  }

  closeModal(){
    this.modalService.dismissAll();
    this.updateSupplement = false;
    this.createSupplementForm.reset();
    this.createSupplementForm.get('type_id').setValue("");
    this.createSupplementForm.get('unitaMisura').setValue("");
  }

  updateClickSupplement(idSupplement, nameSupplement){
    this.nameSupplementSelected = nameSupplement;
    this.getDetailSupplement(idSupplement);
    this.openModalNewSupplement();
 }

  loadTypesSupplements() {
    this.pianoIntegrazioneService.getTypesSupplements().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.groupSupplements = res.data;
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }});
  }

  getDetailSupplement(idFood){
    this.pianoIntegrazioneService.getDetailsSupplement(idFood).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.supplementSelectedForUpdate = res.data;
          this.supplementIdSelectedForUpdate = this.supplementSelectedForUpdate.id;
          this.createSupplementForm.patchValue({
            name: this.supplementSelectedForUpdate.name,
            unitaMisura: this.supplementSelectedForUpdate.weight_type,
            url: this.supplementSelectedForUpdate.url,
            calories: this.supplementSelectedForUpdate.calories,
            carbohydrates: this.supplementSelectedForUpdate.carbohydrates,
            proteins: this.supplementSelectedForUpdate.proteins,
            fats: this.supplementSelectedForUpdate.fats,
          });
          this.createSupplementForm.get('type_id').setValue(this.supplementSelectedForUpdate.type.id);
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }});
  }

  setupFormCreateSupplement() {
    this.createSupplementForm = this.fb.group({
      type_id: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      calories: new FormControl(""),
      carbohydrates: new FormControl(""),
      proteins: new FormControl(""),
      fats: new FormControl(""),
      unitaMisura: new FormControl("", [Validators.required]),
      url: new FormControl("", [Validators.required]),
    });
  }

  buildRequestToCreate(type_id, name, calories, carbohydrates, proteins, fats,  weight_type, url) {
    return {
      type_id: type_id,
      name: name,
      calories: calories,
      carbohydrates: carbohydrates,
      proteins: proteins,
      fats: fats,
      weight_type: weight_type,
      url: url
    };
  }

  getDataForm(){
    const type_id = this.createSupplementForm.get("type_id").value || null;
    const name = this.createSupplementForm.get("name").value || null;
    const unitaMisura = this.createSupplementForm.get("unitaMisura").value || null;
    const url = this.createSupplementForm.get("url").value || null;
    const calories = this.createSupplementForm.get("calories").value || null;
    const carbohydrates = this.createSupplementForm.get("carbohydrates").value || null;
    const proteins = this.createSupplementForm.get("proteins").value || null;
    const fats = this.createSupplementForm.get("fats").value || null;

    this.request = this.buildRequestToCreate(
        type_id, name, calories, carbohydrates, proteins, fats,  unitaMisura, url
    );
  }

  createNewSupplement(){
    this.getDataForm();
    const sub = this.pianoIntegrazioneService.newSupplement(this.request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modalService.dismissAll();
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(),{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
          this.loadSupplementList();
        } else {
          this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
        }
      }
    });
    this.subscriptions.push(sub);
  }

  updateSupplementSelected(){
    this.getDataForm();
    const sub = this.pianoIntegrazioneService.updateSupplement(this.request, this.supplementIdSelectedForUpdate).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modalService.dismissAll();
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(),{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
          this.loadSupplementList();
        } else {
          this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
        }
      }
    });
    this.subscriptions.push(sub);
  }

  deleteSupplement(idFood, nameFood) {
    this.confirmDialogService.confirm(
        'Stai per eliminare l\'alimento: '+ nameFood,
        'Sei sicuro di voler continuare?'
    )
        .then((res) => {
          if(res){
            this.pianoIntegrazioneService.deleteSupplement(idFood).subscribe({
              next: (resp: any) => {
                if (resp.success) {
                  this.toastr.success(resp.message, this.messageFormatter.getSuccessTitle(),{
                    positionClass: 'toast-bottom-left',
                    tapToDismiss: true,
                  });
                  this.loadSupplementList();
                } else {
                  this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), resp.message)
                }
              }
            });
          }
        })
        .catch(() => console.log('Error'));
  }
  
	toIterator(n: number): number[] {
		return Array(n).fill(0);
	}

  deselectFirstRow() {
    setTimeout( function() {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach(t => {
        for (let i = 0, row; row = t.rows[i]; i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }

}
