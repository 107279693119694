import { MessageFormatterService } from './../../services/messageFormatter.service';
import { PianoIntegrazioneService } from './../../pages/integrazione/piano-integrazione.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-store-piano-integrazione',
  templateUrl: './store-piano-integrazione.component.html',
  styleUrls: ['./store-piano-integrazione.component.css']
})
export class StorePianoIntegrazioneComponent implements OnInit {

	@ViewChild('theModalPlanTemplate') theModalPlanTemplate: ElementRef;
	formNewPlan: FormGroup;
  	subscriptions: Subscription[] = [];

	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder,
		private router: Router,
		private messageFormatter: MessageFormatterService,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private pianoIntegrazioneService: PianoIntegrazioneService,

	) { }

	ngOnInit(): void {
		this.setupForm();
	}

	createPlan(){

		const titleNewPlan = this.formNewPlan.get("titleNewPlan").value || null;
		const noteNewPlan = this.formNewPlan.get("noteNewPlan").value || null;
	
	
		const request = this.buildRequest(
			titleNewPlan,
			noteNewPlan
		);
	
		const sub = this.pianoIntegrazioneService.newSupplementPlan(request).subscribe({
		next: (res: any) => {
			if (res.success) {
				this.modalService.dismissAll();
				this.router.navigate(['/integrazione/dettaglio-integrazione/' + res.data.id]);
				this.toastr.success(res.message, 'Operazione eseguita con successo!',{
					positionClass: 'toast-bottom-left',
					tapToDismiss: true,
				});
			}
		},
		error: err => {
			let message = err.error.message + ": </br>";
			if (err.error.data) {
			  for (const error in err.error.data.errors) {
				if (typeof (err.error.data.errors[error]) !== "undefined") {
				  message += err.error.data.errors[error][0] + " ";
				}
			  }
			}
			this.toastr.error(message, this.messageFormatter.getErrorTitle(),{
				positionClass: 'toast-bottom-left',
				tapToDismiss: true,
				enableHtml: true
			});
		}
		});
		this.subscriptions.push(sub);
	}

	buildRequest(name, note) {
		return {
		  name: name,
		  note: note
		};
	}

	open(){
		this.modalService.open(this.theModalPlanTemplate, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
	}

	setupForm() {
		this.formNewPlan = this.fb.group({
		  titleNewPlan: new FormControl("", [Validators.required]),
		  noteNewPlan: new FormControl(""),
		});
	}

}
