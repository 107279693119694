<button class="btn btn-primary border-radius-large m-0 d-flex align-items-center justify-content-between btn-clone"
    (click)="open()">
    <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143"
        class="svg-icons dark mb-2">
        <g transform="translate(4.433 3.071)">
            <path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571"
                transform="translate(-5.576 -3.071)" />
            <path class="a" d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z"
                transform="translate(-5.576 -3.071)" />
            <line class="a" y2="3.429" transform="translate(1.281 -1.928)" />
            <line class="a" y2="3.429" transform="translate(5.853 -1.928)" />
            <line class="a" y2="3.429" transform="translate(10.424 -1.928)" />
        </g>
    </svg>
    <p class="mb-0 ml-2 small">Aggiungi <br>alimentazione</p>
</button>

<ng-template #theModalPlanTemplate let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title" *ngIf="newGroupPlan">Nuovo piano alimentare</h6>
        <h6 class="title" *ngIf="newSinglePlan">Aggiungi piano alimentare</h6>
    </div>
    <div class="modal-body">
        <form [formGroup]="formNewPlan">
            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <input type="text" name="titleNewPlan" formControlName="titleNewPlan" id="titleNewPlan"
                        class="form-control square-input" placeholder="Nome piano" required />
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143"
                        class="svg-icons primary form-group__svg">
                        <g transform="translate(4.433 3.071)">
                            <path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571"
                                transform="translate(-5.576 -3.071)" />
                            <path class="a"
                                d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z"
                                transform="translate(-5.576 -3.071)" />
                            <line class="a" y2="3.429" transform="translate(1.281 -1.928)" />
                            <line class="a" y2="3.429" transform="translate(5.853 -1.928)" />
                            <line class="a" y2="3.429" transform="translate(10.424 -1.928)" />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-sm-8 pr-0">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="noteNewPlan" formControlName="noteNewPlan"
                            id="noteNewPlan" rows="" cols="" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                            class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)">
                                <rect class="a" width="15" height="15" transform="translate(0 0)" />
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15"
                                        transform="translate(-3 -1.5)" />
                                    <path class="b"
                                        d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z"
                                        transform="translate(-3 -1.5)" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="createPlan()">
                        <span class="btn__text m-0">Aggiungi</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>