<div class="wrapper">
  <app-notifications-sidebar></app-notifications-sidebar>
  <app-navbar></app-navbar>
  <app-sidebar></app-sidebar>
  <div class="main-panel">

    <div class="wrap-spinner d-flex w-100 align-items-center justify-content-center" *ngIf="loading$ | async">
<!--    <div class="wrap-spinner d-flex w-100 align-items-center justify-content-center" *ngIf="loading$">-->
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div [ngClass]="{'d-none': loading$ | async}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
