import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ViewCell} from "ng2-smart-table";

@Component({
  selector: 'app-checkbox-ng2-smart-table',
  templateUrl: './checkbox-ng2-smart-table.component.html',
  styleUrls: ['./checkbox-ng2-smart-table.component.css']
})
export class CheckboxNg2SmartTableComponent implements OnInit {

  renderValue: boolean;
  @Input() value: boolean;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.renderValue = this.value;
  }

  onClick() {
    this.rowData.uiDataModelIsSelected = !this.rowData.uiDataModelIsSelected;
  }

}
