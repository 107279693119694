<button class="btn btn-primary border-radius-large" (click)="open()" *ngIf="isWorkoutTamplate">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons dark mb-1">
        <g transform="translate(-941.5 -862)">
            <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
            <line class="a" y2="10.597" transform="translate(946.465 863)"/>
            <line class="a" y2="10.597" transform="translate(958.535 863)"/>
            <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
            <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
        </g>
    </svg>
    <p>Aggiungi nuovo programma</p>
</button>

<button class="btn btn-primary border-radius-small w-100 mb-3 d-flex justify-content-between align-items-center" *ngIf="isWorkoutTamplateUser" (click)="open()">
    <span class="btn__text">Personalizzato</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" class="svg-icons primary bg-white"><g class="a"><rect class="d" width="48" height="48" rx="24"/><rect class="e" x="1" y="1" width="46" height="46" rx="23"/></g><g transform="translate(14 14)"><g class="b"><rect class="d" width="20" height="20"/><rect class="e" x="0.5" y="0.5" width="19" height="19"/></g><g transform="translate(0.833 3.333)"><line class="c" y1="6.364" transform="translate(1.894 8.485)"/><line class="c" y1="6.364" transform="translate(1.894 -1.515)"/><line class="c" y1="8.182" transform="translate(9.167 6.667)"/><line class="c" y1="4.545" transform="translate(9.167 -1.515)"/><line class="c" y1="4.545" transform="translate(16.44 10.303)"/><line class="c" y1="8.182" transform="translate(16.44 -1.515)"/><line class="c" x2="5.455" transform="translate(-0.833 8.485)"/><line class="c" x2="5.455" transform="translate(6.44 3.031)"/><line class="c" x2="5.455" transform="translate(13.712 10.303)"/></g></g></svg>
</button>

<ng-template #theModalPlanTemplate let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Nuovo piano di allenamento</h6>
    </div>
    <div class="modal-body">
        <form [formGroup]="workoutPlanForm">
            <div class="row align-items-end">
                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <input type="text" name="titleNewPlanWorkout" formControlName="titleNewPlanWorkout" id="titleNewPlanWorkout" class="form-control square-input" placeholder="Nome piano allenamento" required/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons primary form-group__svg">
                            <g transform="translate(-941.5 -862)">
                                <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
                                <line class="a" y2="10.597" transform="translate(946.465 863)"/>
                                <line class="a" y2="10.597" transform="translate(958.535 863)"/>
                                <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
                                <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div class="col-sm-8" *ngIf="isWorkoutTamplateUser"> <!-- visisbile solo nella sezione utente -->
                    <div class="form-group bckg-body">
                        <div class="col-12 px-0">
                            <div class="dp-hidden position-absolute">
                                <div class="input-group">
                                    <input
                                            name="datepickerWorkout"
                                            class="invisible"
                                            ngbDatepicker
                                            #datepicker="ngbDatepicker"
                                            [autoClose]="'outside'"
                                            (dateSelect)="onDateSelection($event, datepicker)"
                                            [displayMonths]="2"
                                            [dayTemplate]="t"
                                            outsideDays="hidden"
                                            [startDate]="fromDate!"
                                            tabindex="-1">
                                    <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                          [class.focused]="focused"
                                          [class.range]="isRange(date)"
                                          [class.faded]="isHovered(date) || isInside(date)"
                                          (mouseenter)="hoveredDate = date"
                                          (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    #dpFromDate
                                    class="form-control square-input" placeholder="Inizio"
                                    name="dpWorkoutFromDate"
                                    [value]="formatter.format(fromDate)"
                                    (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="col-12 px-0">
                            <div class="form-group">
                                <input
                                    #dpToDate
                                    class="form-control square-input" placeholder="Fine"
                                    name="dpWorkoutToDate"
                                    [value]="formatter.format(toDate)"
                                    (input)="toDate = validateInput(toDate, dpToDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-8 ">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="noteNewPlanWorkout" formControlName="noteNewPlanWorkout" id="noteNewPlanWorkout" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="createWorkoutPlan()">
                        <span class="btn__text m-0">Aggiungi</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
