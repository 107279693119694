import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerDataSource } from "ng2-smart-table";
import { Observable, Subject } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { environment } from "./../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PianoAllenamentoService {
  private HTTPheaders;
  private options;

  planWorkoutId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  sessionActivated$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  editExercises$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  subjectNotifier: Subject<null> = new Subject<null>();
  private _idExerciseForUpdate: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  itemExercise$ = this._idExerciseForUpdate.asObservable();
  exerciseNotifier: Subject<null> = new Subject<null>();

  constructor(private http: HttpClient) {
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };

    this.options = {
      headers: this.HTTPheaders,
    };
  }

  getWorkoutPlanGroup({ trainer_id }: { trainer_id?: String } = {}): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/workout_plan?" + (trainer_id ? "trainer_id=" + trainer_id : ""),
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getExercise(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/exercise",
      filterFieldKey: "name",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getExercisesTitles(): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/exercise", this.options);
  }

  deleteExerciseSelected(exerciseId: number) {
    return this.http.delete(environment.baseUrl + "admin/exercise/" + exerciseId, this.options);
  }

  getShortListUsers(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/get-list",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getTemplate(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/workout_plan/get-templates",
      dataKey: "data",
    });
  }

  getTypesExercise(): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/exercise/get-types", this.options);
  }

  getIndexExercise(typeId: number = null): ServerDataSource {
    let query = typeId ? "?type=" + typeId : "";
    return new ServerDataSource(this.http, {
      filterFieldKey: "name",
      endPoint: environment.baseUrl + "admin/exercise" + query,
      dataKey: "data",
    });
  }

  newExercise(formData: any): Observable<ArrayBuffer> {
    const option_video = this.options;
    option_video.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/exercise", formData, option_video);
  }

  updateExerciseSelected(formData: any, idExercise: number) {
    const option_video = this.options;
    formData.append("_method", "PUT");
    option_video.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/exercise/" + idExercise, formData, option_video);
  }

  newPlanWorkout(request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/workout_plan", request, this.options);
  }

  showPlanWorkout(workoutPlanId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/workout_plan/" + workoutPlanId, this.options);
  }

  showPlanWorkoutOfUser(registryId: number, workoutPlanId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/workout_plan/" + workoutPlanId, this.options);
  }

  updatePlanWorkoutId(value: number) {
    this.planWorkoutId$.next(value);
  }

  updateSessionActivated(value: number) {
    this.sessionActivated$.next(value);
  }

  editExercises(data: any) {
    this.editExercises$.next(data);
  }

  clearEditExercisesQueue() {
    this.editExercises$ = new BehaviorSubject<any>(null);
  }

  newSessionToPlanWorkout(workoutPlanId: number, request): Observable<Object> {
    return this.http.post(environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session", request, this.options);
  }

  getDetailsSingleSessionWorkoutPlan(workoutPlanId: number, sessionId: number): Observable<any> {
    return this.http.get(environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId, this.options);
  }

  assignExerciseToUser(workoutPlanId: number, sessionId: number, request): Observable<Object> {
    return this.http.post(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/add-exercise",
      request,
      this.options
    );
  }

  notifyAboutChange() {
    this.subjectNotifier.next();
  }

  newSuperserie(workoutPlanId: number, sessionId: number, request): Observable<Object> {
    return this.http.post(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/add-superserie",
      request,
      this.options
    );
  }

  deleteWorkoutPlan(id: number) {
    return this.http.delete(environment.baseUrl + "admin/workout_plan/" + id, this.options);
  }

  assignWorkoutPlan(request: any, planId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/workout_plan/" + planId + "/clone-assign/", request, this.options);
  }

  assignPDFWorkoutPlan(request: any): Observable<ArrayBuffer> {
    const option = this.options;
    option.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/workout_plan/", request, option);
  }

  getWorkoutPlanIdwithUser(userId: number, foodPlanGroupId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/registry/" + userId + "/workout_plan/" + foodPlanGroupId, this.options);
  }

  updateWorkoutPlan(request: any, planId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/workout_plan/" + planId, request, this.options);
  }

  updateWorkoutPlanPdf(formData: any, planId: number, userId?: number): Observable<any> {
    const option_video = this.options;
    formData.append("_method", "PUT");
    option_video.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/workout_plan/" + planId, formData, option_video);
  }

  updateSessionSingle(request: any, workoutPlanId: number, sessionId: number) {
    return this.http.put(environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId, request, this.options);
  }

  deleteSessionSingle(workoutPlanId: number, sessionId: number) {
    return this.http.delete(environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId, this.options);
  }

  updateExercise(request: any, workoutPlanId: number, sessionId: number, exerciseSessionId: number) {
    return this.http.put(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/update-exercise/" + exerciseSessionId,
      request,
      this.options
    );
  }

  updateExerciseTitle(workoutPlanId: number, sessionId: number, exerciseId, request: any) {
    return this.http.put(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/switch-exercise/" + exerciseId,
      request,
      this.options
    );
  }

  deleteExercise(workoutPlanId: number, sessionId: number, exerciseSessionId: number) {
    return this.http.delete(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/remove-exercise/" + exerciseSessionId,
      this.options
    );
  }

  deleteSuperSerie(workoutPlanId: number, sessionId: number, superSerieId: number) {
    return this.http.delete(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/remove-superserie/" + superSerieId,
      this.options
    );
  }

  reorderSeries(workoutPlanId: number, sessionId: number, exercises) {
    return this.http.post(
      environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/session/" + sessionId + "/reorder-exercises",
      exercises,
      this.options
    );
  }

  reorderSessions(workoutPlanId: number, sessions) {
    return this.http.post(environment.baseUrl + "admin/workout_plan/" + workoutPlanId + "/reorder-sessions", sessions, this.options);
  }

  listVideoHowTo(): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/how_to_video", this.options);
  }

  clickBtnUpdateExercise(updateExercise) {
    this._idExerciseForUpdate.next(updateExercise);
  }

  notifyExerciseChange() {
    this.exerciseNotifier.next();
  }

  getDetailsExercise(idExercise: number) {
    return this.http.get(environment.baseUrl + "admin/exercise/" + idExercise, this.options);
  }
}
