import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtentiService } from './../../pages/utenti/utenti.service';

@Component({
  selector: 'app-widget-info-trainers',
  templateUrl: './widget-info-trainers.component.html',
  styleUrls: ['./widget-info-trainers.component.css']
})
export class WidgetInfoTrainersComponent implements OnInit {

	subscriptions: Subscription[] = [];
	widgetInfo: any;

	constructor(
        private utentiService: UtentiService,
	) { }

	ngOnInit(): void {
	}


}
