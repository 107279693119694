import { MessageFormatterService } from './../../services/messageFormatter.service';
import { ConfirmDialogService } from './../confirm-dialog/confirm-dialog.service';
import { PianoAllenamentoService } from './../../pages/piano-allenamento/piano-allenamento.service';
import { ActionTableComponent } from './../action-table/action-table.component';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ServerDataSource } from 'ng2-smart-table';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateCustomParserFormatter } from './../../dateformat';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-plan-workout-for-user',
  templateUrl: './modal-plan-workout-for-user.component.html',
  styleUrls: ['./modal-plan-workout-for-user.component.css'],
  providers: [
	{provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter} // formatta la data in dd/mm/yyyy
]
})
export class ModalPlanWorkoutForUserComponent implements OnInit {
	
	@Output() updateList: EventEmitter<any> = new EventEmitter();

	@ViewChild('theModalChoose') theModalChoose: ElementRef;
	@ViewChild('theModalPlanTemplate') theModalPlanTemplate: ElementRef;
	@ViewChild('AssignTemplateModal') AssignTemplateModal: ElementRef;
	@ViewChild('DataTemplateModal') DataTemplateModal: ElementRef;
	@ViewChild("AssignPDFModal") AssignPDFModal: ElementRef;
	@ViewChild("input") input: ElementRef;

	templateSource:  ServerDataSource;
  	templateSettings: any;
  	routeSub: Subscription;
	userId: number;
	workoutPlanId: number;
	initialTemplateName: string;
  	typePlanWorkout = 'templateUserPersonalized';
	dataTemplatePlan: FormGroup;
	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	subscriptions: Subscription[] = [];

	//pdf choice vars
	filePDF: File;
	PDFPlanName = "";
	filePDFName = null;
	
	constructor(
		private modalService: NgbModal,
		private pianoAllenamentoService: PianoAllenamentoService,
		private route: ActivatedRoute,
		private messageFormatter: MessageFormatterService,
		private fb: FormBuilder,
		public formatter: NgbDateParserFormatter,
		private calendar: NgbCalendar,
		private confirmDialogService: ConfirmDialogService,
		private router: Router,
		private toastr: ToastrService,

	) { }

	ngOnInit(): void {
		this.routeSub = this.route.params.subscribe(params => {
			this.userId = params["idUser"];
		});
		this.loadTemplateList();
		this.loadTableSettings();
		this.setupForm();
	}

	loadTableSettings() {
		this.templateSettings = {
			noDataMessage: "Nessun dato da visualizzare",
			mode: "external",
			hideSubHeader: false,
			actions: false,
			sort: false,
			columns: {
				name: {
					title: 'Nome',
					filter: true,
				},
				dettaglio: {
					type: "custom",
					filter: false,
					sort: false,
					renderComponent: ActionTableComponent,
					onComponentInitFunction: (instance) => {
						const ref = this;
						instance.actionEmit.subscribe(value => {
							if (value === "seleziona") {
								// ref.assignFoodPlanToUser(instance.rowData.id, instance.rowData.name);
								ref.openModalToPassData();
								this.workoutPlanId = instance.rowData.id;
								this.initialTemplateName = instance.rowData.name;
							} 
						});
					},
					valuePrepareFunction: (cell, row) => {
						const ritorno = [
							{
								text: "seleziona",
								link: "",
								emit: "seleziona",
								title: "Seleziona template",
								btnId: "seleziona_" + row.id,
								stackIcon: false,
								stackClass: null,
								iconClassList: [],
								iconClass: "fas fa-edit ",
								classList: "action-btn btn",
							},
						
						];
						return ritorno;
					},
				},
		  	},
		};
	}

	loadTemplateList(): void {
		this.templateSource = this.pianoAllenamentoService.getTemplate();
	}

	assignFoodPlanToUser(){

		
		const name = this.dataTemplatePlan.get("name").value || null;
		const note = this.dataTemplatePlan.get("note").value || null;
		
		if(typeof(this.fromDate) != "undefined"){
			const a = this.fromDate;
			let anno_from = a.year;
			let mese_from = a.month.toString();
			if(mese_from.length < 2){mese_from= "0"+mese_from;}
			let giorno_from = a.day.toString();
			if(giorno_from.length < 2){giorno_from= "0"+giorno_from;}
			var start = anno_from+'-'+mese_from+'-'+giorno_from;
		}
		
		if(typeof(this.toDate)!= "undefined"){
			const b = this.toDate;
			let anno_to = b.year;
			let mese_to = b.month.toString();
			if(mese_to.length < 2){mese_to= "0"+mese_to;}
			let giorno_to = b.day.toString();
			if(giorno_to.length < 2){giorno_to= "0"+giorno_to;}
			var end = anno_to+'-'+mese_to+'-'+giorno_to;
		}

		const request = this.buildRequest(
			Number(this.userId),
			name,
			start,
			end,
			note
		);
		
		const sub = this.pianoAllenamentoService.assignWorkoutPlan(request, this.workoutPlanId).subscribe({
			next: (res: any) => {
				if (res.success) {
					this.modalService.dismissAll();
					this.toastr.success(res.message, 'Operazione eseguita con successo!',{
						positionClass: 'toast-bottom-left',
						tapToDismiss: true,
					});
					this.router.navigate(['/dettaglio-utente/'+this.userId+'/piano-allenamento/' + res.data.id]);
				} else {
					this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
				}
			}});
		this.subscriptions.push(sub);

	}

	buildRequest(registry_id, name, start, end, note) {
		return {
			registry_id: registry_id,
			name: name,
			start: start,
			end: end,
			note: note
		};
	}

	openChoosePlan() {
		this.modalService.open(this.theModalChoose, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
	}

	openStorePlanWorkout() {
		this.modalService.open(this.theModalPlanTemplate, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
	}

	openModalToPassData(){
		this.modalService.open(this.DataTemplateModal, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
	}

  	openModalToAssigntemplate(){
		this.modalService.open(this.AssignTemplateModal, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
	}

	setupForm() {
		this.dataTemplatePlan = this.fb.group({
		  name: new FormControl("", [Validators.required]),
		  dpFromDate: new FormControl("", [Validators.required]),
		  dpToDate: new FormControl("", [Validators.required]),
		  note: new FormControl(""),
		});
	}

  	closeModal(){
		this.modalService.dismissAll();
	}

	openModalPDF() {
		this.modalService.open(this.AssignPDFModal, {
		  size: "md",
		  backdrop: "static",
		  windowClass: "mediumModal",
		  centered: true,
		});
	  }
	  
	async uploadPDF(): Promise<void> {
		if (typeof this.fromDate != "undefined") {
			const a = this.fromDate;
			let anno_from = a.year;
			let mese_from = a.month.toString();
			if (mese_from.length < 2) {
			mese_from = "0" + mese_from;
			}
			let giorno_from = a.day.toString();
			if (giorno_from.length < 2) {
			giorno_from = "0" + giorno_from;
			}
			var start = anno_from + "-" + mese_from + "-" + giorno_from;
		}

		if (typeof this.toDate != "undefined") {
			const b = this.toDate;
			let anno_to = b.year;
			let mese_to = b.month.toString();
			if (mese_to.length < 2) {
			mese_to = "0" + mese_to;
			}
			let giorno_to = b.day.toString();
			if (giorno_to.length < 2) {
			giorno_to = "0" + giorno_to;
			}
			var end = anno_to + "-" + mese_to + "-" + giorno_to;
		}
		let formData = new FormData();
		formData.append("pdf", this.filePDF, this.filePDF.name);
		formData.append("name", this.PDFPlanName);
		formData.append("pdf_template", "1");
		formData.append("start", start);
		formData.append("end", end);
		formData.append("registry_id", this.userId.toString());

		try {
			const sub = this.pianoAllenamentoService.assignPDFWorkoutPlan(formData).subscribe({
			next: (res: any) => {
				if (res.success) {
				this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
					positionClass: "toast-bottom-left",
					tapToDismiss: true,
				});
				this.modalService.dismissAll();
				this.updateList.emit(true);
				} else {
				this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
				}
			},
			});
			this.subscriptions.push(sub);
		} catch (error) {
			console.error("Error uploading PDF:", error);
		}
	}
	
	handleClick() {
		if (this.input) {
			this.input.nativeElement.value = "";
			this.input.nativeElement.click();
		}
	}

	onFileSelected(event: any): void {
		if (event.target.files.length > 0) {
			this.filePDF = event.target.files[0];
			this.filePDFName = this.filePDF.name;
		}
	}

	onDateSelection(date: NgbDate, datepicker: any) {
		if (!this.fromDate && !this.toDate) {
		  this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
		  this.toDate = date;
		  datepicker.close();
		} else {
		  this.toDate = null;
		  this.fromDate = date;
		}

	}
	
	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
		date.before(this.hoveredDate);
	}
	
	isInside(date: NgbDate) { 
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}
	
	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
		this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}


  	deselectFirstRow() {
		setTimeout( function() {
			const tabelle = Array.from(document.getElementsByTagName("table"));
			tabelle.forEach(t => {
				for (let i = 0, row; row = t.rows[i]; i++) {
					if (row.classList.contains("selected")) {
						row.classList.remove("selected");
					}
				}
			});
		}, 200);
	}

}
