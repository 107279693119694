import { Component, Input, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FilePipePipe } from "../pipe/file-pipe.pipe";

@Component({
  selector: "ngbd-modal-component",
  templateUrl: "./modal.component.html",
  providers: [FilePipePipe]
})
export class NgbdModalBasic {

  @Input() path: string;
  @Input() useFilePipe?: boolean = true;
  
  @ViewChild('theModalImage') theModalImage: NgbdModalBasic;

  constructor(private modalService: NgbModal) {}

  open() {
      this.modalService.open(this.theModalImage);
  }
}
