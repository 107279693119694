<button class="btn btn-primary border-radius-large m-0 w-100 d-flex align-items-center justify-content-between btn-clone mt-2" (click)="clonePreviousPlan()">
    <svg xmlns="http://www.w3.org/2000/svg" width="18.7" height="18.7" viewBox="0 0 18.7 18.7" class="svg-icons dark">
        <g transform="translate(0.85 0.951)">
        <rect class="a" width="17" height="17" transform="translate(0 -0.101)"/>
        <g transform="translate(1.408 1.404)">
        <rect class="b" width="11.05" height="11.05" rx="2" transform="translate(4.542 4.445)" style="fill:none" />
        <path class="b" d="M4.25,12.75H3.4a1.7,1.7,0,0,1-1.7-1.7V3.4A1.7,1.7,0,0,1,3.4,1.7h7.65a1.7,1.7,0,0,1,1.7,1.7v.85" transform="translate(-3.108 -3.205)"/>
        </g>
        </g>
    </svg>
    <p class="mb-0 ml-2 small">Clona <br>alimentazione <br> precedente</p>
</button>
