<button class="btn btn-primary border-radius-large m-0 d-flex align-items-center justify-content-between btn-clone" (click)="open()">
    <svg xmlns="http://www.w3.org/2000/svg" width="23.14" height="23.14" viewBox="0 0 23.14 23.14" class="svg-icons dark mb-2">
        <g id="Raggruppa_160163" data-name="Raggruppa 160163" transform="translate(21.726 21.726) rotate(180)">
          <rect id="Rettangolo_140008" data-name="Rettangolo 140008" width="8.188" height="19.897" rx="4.094" transform="translate(14.069 0.453) rotate(45)" fill="none" stroke="#a7061e" stroke-width="2"/>
          <path id="Rettangolo_140009" data-name="Rettangolo 140009" d="M4.383,0h0A4.383,4.383,0,0,1,8.766,4.383v6.268a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.383A4.383,4.383,0,0,1,4.383,0Z" transform="translate(14.113 0) rotate(45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
        </g>
    </svg>
    <p class="mb-0 ml-2 small">Aggiungi <br>integrazione</p>
</button>

<ng-template #theModalPlanTemplate let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Nuovo piano integrazione</h6>
        <!-- <h6 class="title" *ngIf="newSinglePlan">Aggiungi piano alimentare</h6> -->
    </div>
    <div class="modal-body">
        <form [formGroup]="formNewPlan">
            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <input type="text" name="titleNewPlan" formControlName="titleNewPlan" id="titleNewPlan" class="form-control square-input" placeholder="Nome piano" required/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.14" height="23.14" viewBox="0 0 23.14 23.14" class="svg-icons primary form-group__svg">
                        <g id="Raggruppa_160163" data-name="Raggruppa 160163" transform="translate(21.726 21.726) rotate(180)">
                          <rect id="Rettangolo_140008" data-name="Rettangolo 140008" width="8.188" height="19.897" rx="4.094" transform="translate(14.069 0.453) rotate(45)" fill="none" stroke="#a7061e" stroke-width="2"/>
                          <path id="Rettangolo_140009" data-name="Rettangolo 140009" d="M4.383,0h0A4.383,4.383,0,0,1,8.766,4.383v6.268a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.383A4.383,4.383,0,0,1,4.383,0Z" transform="translate(14.113 0) rotate(45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-sm-8 pr-0">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="noteNewPlan" formControlName="noteNewPlan" id="noteNewPlan"  rows="" cols="" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="createPlan()">
                        <span class="btn__text m-0">Aggiungi</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>