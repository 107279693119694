import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
	NgbCalendar,
	NgbDate,
	NgbModal,
	NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from "../../dateformat";

@Component({
	selector: 'app-edit-plan-modal',
	templateUrl: './edit-plan-modal.component.html',
	styleUrls: ['./edit-plan-modal.component.css'],
	providers: [
		{provide: NgbDateCustomParserFormatter} // formatta la data in dd/mm/yyyy
	]
})
export class EditPlanModalComponent implements OnInit {

	@ViewChild("editPlanModal", {static: true}) editPlanModal: TemplateRef<EditPlanModalComponent>;
	@Input() data: any = null;

	private modalRef: NgbModalRef = null;

	planName: string;
	planNote: string;
	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	planWorkout = false;
	planSupplement = false;
	planFood = false;

	onSave: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private modalService: NgbModal,
		private formatter: NgbDateCustomParserFormatter,
		private calendar: NgbCalendar,
	) { }

	ngOnInit(): void {
		this.planName = "";
		this.planNote = "";
		this.planWorkout = false;
		this.planSupplement = false;
		this.planFood = false;
	}

	show(planType: string) {

		switch (planType){
			case "workout":{
				this.planWorkout = true;
				break;
			}
			case "supplement":{
				this.planSupplement = true;
				break;
			}
			case "food":{
				this.planFood = true;
				break;
			}
		}

		this.modalRef = this.modalService.open(this.editPlanModal, { size: 'lg', backdrop: 'static', windowClass: 'mediumModal', centered: true})
		this.planName = "COPIA_"+this.data.name;
		this.fromDate = new NgbDate(
			this.formatter.parse(this.data.start).year,
			this.formatter.parse(this.data.start).month,
			this.formatter.parse(this.data.start).day
		)
		this.toDate = new NgbDate(
			this.formatter.parse(this.data.end).year,
			this.formatter.parse(this.data.end).month,
			this.formatter.parse(this.data.end).day
		)
		this.planNote = this.data.note;
	}

	hide() {
		this.modalRef.close();
	}

	save() {
		this.onSave.emit({
			name: this.planName,
			start: this.formatter.format_dash(this.fromDate),
			end: this.formatter.format_dash(this.toDate),
			note: this.planNote
		});
	}


	onDateSelection(date: NgbDate, datepicker: any) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
			datepicker.close();
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}
	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
	}
}
