<div class="widget-container">
  <a
    class="btn btn-primary border-radius-large btn-aggiungi-cliente"
    routerLink="nuovo-trainer"
  >
    <svg
      _ngcontent-evw-c91=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      class="svg-icons size-20 dark"
    >
      <g _ngcontent-evw-c91="" transform="translate(1 0.101)">
        <rect
          _ngcontent-evw-c91=""
          width="17"
          height="17"
          transform="translate(0 -0.101)"
          class="a"
        ></rect>
        <g _ngcontent-evw-c91="" transform="translate(1.408 1.404)">
          <path
            _ngcontent-evw-c91=""
            d="M12.364,16.227V14.682a3.091,3.091,0,0,0-3.091-3.091H3.864A3.091,3.091,0,0,0,.773,14.682v1.545"
            transform="translate(-2.181 -2.278)"
            class="b"
          ></path>
          <circle
            _ngcontent-evw-c91=""
            cx="3.091"
            cy="3.091"
            r="3.091"
            transform="translate(1.296 0.041)"
            class="b"
          ></circle>
          <line
            _ngcontent-evw-c91=""
            y2="4.636"
            transform="translate(13.274 3.904)"
            class="b"
          ></line>
          <line
            _ngcontent-evw-c91=""
            x1="4.636"
            transform="translate(10.956 6.222)"
            class="b"
          ></line>
        </g>
      </g>
    </svg>
    <p>Aggiungi nuovo Trainer</p>
  </a>
</div>
