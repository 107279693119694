import { environment } from "./../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServerDataSource } from "ng2-smart-table";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UtentiService {
  private HTTPheaders;
  private options;

  currentUserData: any;

  constructor(private http: HttpClient) {
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };

    this.options = {
      headers: this.HTTPheaders,
    };
  }

  getUtentiList(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/",
      dataKey: "data.registries.data",
      totalKey: "data.registries.total", // this is total records count in response, this will handle pager
      perPage: "data.registries.per_page",
      pagerPageKey: "page", // this should be page number param name in endpoint (request not response) for example 'page'
      pagerLimitKey: "per_page", // this should page size param name in endpoint (request not response)
    });
  }

  getUserList(currentPage: number = 0, nRows: number = 10, searchParam: string = ""): Observable<any> {
    return this.http.get(
      environment.baseUrl + "admin/registry/" + "?page=" + currentPage + "&per_page=" + nRows + "&name_like=" + searchParam,
      this.options
    );
  }

  getUtentiListBankTransfers(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/bank-transfers",
      dataKey: "data.bank_tranfers.data",
      totalKey: "data.bank_tranfers.total", // this is total records count in response, this will handle pager
      perPage: "data.bank_tranfers.per_page",
      pagerPageKey: "page", // this should be page number param name in endpoint (request not response) for example 'page'
      pagerLimitKey: "per_page", // this should page size param name in endpoint (request not response)
    });
  }

  updatePaymentStatus(bankTransferId: number, request: any) {
    return this.http.put(environment.baseUrl + "admin/bank-transfers/" + bankTransferId, request, this.options);
  }

  widgetInfo(): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/registry/index-widget", this.options);
  }

  tipologicheAnagrafica(): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "registry/dropdown-data", this.options);
  }

  createUser(formData: any): Observable<ArrayBuffer> {
    const option_pdf = this.options;
    option_pdf.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry", formData, option_pdf);
  }

  updateUser(formData: any, userId: number): Observable<ArrayBuffer> {
    const option = this.options;
    option.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry/" + userId, formData, option);
  }

  getUserNotes(userId: number): Observable<any> {
    return this.http.get(environment.baseUrl + "admin/registry/" + userId + "/note", this.options);
  }

  getProgressi(): Observable<any> {
    return this.http.get<any>("https://mocki.io/v1/6687c8f7-8857-4d00-aec4-fc965c6bf300");
  }

  getProgress(userId: number, fromDate: string, toDate: string): Observable<any> {
    return this.http.get(
      environment.baseUrl + "admin/registry/" + userId + "/compare-progress?start_date=" + fromDate + "&end_date=" + toDate,
      this.options
    );
  }

  getProgressDates(userId: number): Observable<any> {
    return this.http.get(environment.baseUrl + "admin/registry/" + userId + "/calendar-progress", this.options);
  }

  saveProgressDates(userId: number, request: any): Observable<any> {
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/calendar-progress", request, this.options);
  }

  saveUserNote(formData: FormData, userId: number): Observable<any> {
    const option = this.options;
    option.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/note", formData, this.options);
  }

  anagraficaUser(id: number): Observable<ArrayBuffer> {
    const req = this.http.get(environment.baseUrl + "admin/registry/" + id, this.options);
    return req.pipe(
      map((data) => {
        this.currentUserData = data;
        return data;
      })
    );
  }

  showFile(file: any): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/file/" + file, this.options);
  }

  getWorkoutUser(userId: number): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/" + userId + "/workout_plan",
      dataKey: "data",
    });
  }

  getFoodPlanUser(userId: number): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/" + userId + "/food_plan_group",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getProgressUser(userId: number): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/" + userId + "/progress",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  sendProgressUser(formData: any, userId: number): Observable<ArrayBuffer> {
    const option = this.options;
    option.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/progress", formData, option);
  }

  toggleBanUser(id: number) {
    return this.http.put(environment.baseUrl + "admin/registry/" + id + "/toggle-ban-user", this.options);
  }

  toggleSubcriptionUser(id: number) {
    return this.http.put(environment.baseUrl + "admin/registry/" + id + "/toggle-subcription-status", this.options);
  }

  sendNotification(request: any): Observable<any> {
    const option_pdf = this.options;
    option_pdf.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/massive-push-notification/send", request, this.options);
  }

  deleteUser(id: number) {
    return this.http.delete(environment.baseUrl + "admin/registry/" + id, this.options);
  }

  deleteFoodPlanGroup(idUser: number, idFoodPlan) {
    return this.http.delete(environment.baseUrl + "admin/registry/" + idUser + "/food_plan_group/" + idFoodPlan, this.options);
  }

  deleteWorkoutPlanGroup(idWorkoutPlan) {
    return this.http.delete(environment.baseUrl + "admin/workout_plan/" + idWorkoutPlan, this.options);
  }

  showProgressDetails(registryId: number, progressId: number): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/progress/" + progressId, this.options);
  }

  showMainCheck(registryId: number, progressId: number): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/main-checks/" + progressId, this.options);
  }

  showQuestions(): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/main-checks/get-questions", this.options);
  }

  getSubscription(registryId: number): Observable<any> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/subscription", this.options);
  }

  changeNutritionistStatus(registryId: number) {
    return this.http.put(environment.baseUrl + "admin/registry/" + registryId + "/toggle-nutritionist-to-active-subcription", this.options);
  }

  getDetailedSubscriptionsList(registryId: number): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/" + registryId + "/subscription-data",
      dataKey: "data.history.data",
      totalKey: "data.history.total",
      perPage: "data.history.per_page",
      pagerPageKey: "page",
      pagerLimitKey: "per_page",
    });
  }

  getCouponList(registryId: number): ServerDataSource {
    let query = registryId ? "?type=" + registryId : "";
    return new ServerDataSource(this.http, {
      filterFieldKey: "code",
      endPoint: environment.baseUrl + "admin/registry/" + registryId + "/coupon" + query,
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getGenericCouponList(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/coupon",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }
  storeGenericCoupon(request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/coupon", request, this.options);
  }

  deleteGenericCoupon(couponId: number) {
    return this.http.delete(environment.baseUrl + "admin/coupon/" + couponId, this.options);
  }

  updateGenericCoupon(couponId: number, request: any): Observable<ArrayBuffer> {
    return this.http.put(environment.baseUrl + "admin/coupon/" + couponId, request, this.options);
  }

  bundles(): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/bundle", this.options);
  }

  storeCoupon(registryId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + registryId + "/coupon", request, this.options);
  }

  deleteCoupon(registryId: number, couponId: number) {
    return this.http.delete(environment.baseUrl + "admin/registry/" + registryId + "/coupon/" + couponId, this.options);
  }

  updateCoupon(registryId: number, couponId: number, request: any): Observable<ArrayBuffer> {
    return this.http.put(environment.baseUrl + "admin/registry/" + registryId + "/coupon/" + couponId, request, this.options);
  }

  showCoupon(registryId: number, couponId: number): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/coupon/" + couponId, this.options);
  }

  storeSubscription(registryId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + registryId + "/subscription", request, this.options);
  }

  updateSubscription(registryId: number, request: any): Observable<ArrayBuffer> {
    return this.http.put(environment.baseUrl + "admin/registry/" + registryId + "/update-active-subcription/", request, this.options);
  }

  showSubscription(registryId: number, subscriptionId: number): Observable<ArrayBuffer> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/subscription/" + subscriptionId, this.options);
  }

  deleteSubscription(registryId: number, subscriptionId: number) {
    return this.http.delete(environment.baseUrl + "admin/registry/" + registryId + "/subscription/" + subscriptionId, this.options);
  }

  firstActivationSubscription(registryId: number) {
    return this.http.put(environment.baseUrl + "admin/registry/" + registryId + "/activate-subscription", this.options);
  }

  getIntegrationPlansList(registryId: number): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/" + registryId + "/supplement_plan",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  showSupplementPlanUser(registryId: number, supplementPlan: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/registry/" + registryId + "/supplement_plan/" + supplementPlan, this.options);
  }

  deleteSubscriptionId(supplementPlanId: number) {
    return this.http.delete(environment.baseUrl + "admin/supplement_plan/" + supplementPlanId, this.options);
  }

  cloneWorkoutPlan(registryId: number, planId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + registryId + "/workout_plan/" + planId + "/clone", request, this.options);
  }

  cloneFoodPlan(registryId: number, planId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + registryId + "/food_plan_group/" + planId + "/clone", request, this.options);
  }

  cloneSupplementPlan(registryId: number, planId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + registryId + "/supplement_plan/" + planId + "/clone", request, this.options);
  }

  // ******* start sidebar notifications api ********
  getNotifications(type: "New" | "Readed", page: number, perPage: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "notifications" +
        "?readed=" +
        (type === "Readed" ? 1 : 0) +
        (perPage ? "&per_page=" + perPage : "") +
        (page ? "&page=" + page : ""),
      this.options
    );
  }

  notificationReaded(notificationId: number): Observable<any> {
    return this.http.post(environment.baseUrl + "notifications/" + notificationId + "/mark-as-read", this.options);
  }
  //********* end sidebar notifications api **********
}
