import { Component } from "@angular/core";
import { environment } from '../environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app";

  message:any = null;
  
  constructor() {}

  ngOnInit(): void {
  }

}
if (environment.production === true) {
  console.log = function() {};
}
