import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  	constructor(
		private modalService: NgbModal
	) { }

	public confirm(
		title: string,
		message: any,
		btnOkText: string = 'OK',
		btnCancelText: string = 'ANNULLA',
		dialogSize: 'sm'|'md' = 'md'): Promise<boolean> {
			const modalRef = this.modalService.open(ConfirmDialogComponent, { size: dialogSize, windowClass: 'primaryModal', });
			modalRef.componentInstance.title = title;
			modalRef.componentInstance.message = message;
			modalRef.componentInstance.btnOkText = btnOkText;
			modalRef.componentInstance.btnCancelText = btnCancelText;
	
			return modalRef.result;
	  	}
}
