import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { ServerDataSource } from "ng2-smart-table";
import { Observable, Subject } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PianoAlimentareService {
  private HTTPheaders;
  private options;

  private _dataFood: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  item$ = this._dataFood.asObservable();

  planGroupId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  singlePlanActivated$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  mealActivated$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  /*private _updateFood: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  itemFood$ = this._updateFood.asObservable();*/
  $updateFood: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  updateEventFood: EventEmitter<boolean> = new EventEmitter();

  subjectNotifier: Subject<null> = new Subject<null>();
  subjectUpdateNotifier: Subject<any> = new Subject<null>();

  constructor(private http: HttpClient) {
    this.HTTPheaders = {
      "Content-Type": "application/json",
    };

    this.options = {
      headers: this.HTTPheaders,
    };
  }

  getFoodPlanGroup({ trainer_id }: { trainer_id?: string } = {}): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/food_plan_group?" + (trainer_id ? "trainer_id=" + trainer_id : ""),
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  getShortListUsers(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/registry/get-list",
      dataKey: "data",
      //   totalKey: "total",
      //   perPage: "per_page",
      //   pagerPageKey: "page",
      //   pagerLimitKey: "per_page",
    });
  }

  newPlanGroupFood(request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food_plan_group", request, this.options);
  }

  getGroupPlanFoodId(foodPlanGroupId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId, this.options);
  }
  getGroupPlanFoodIdwithUser(userId: number, foodPlanGroupId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/registry/" + userId + "/food_plan_group/" + foodPlanGroupId, this.options);
  }

  newPlanSingleFood(request: any, foodPlanGroupId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan", request, this.options);
  }

  newMealPlanSingleFood(foodPlanGroupId: number, foodPlanId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal",
      request,
      this.options
    );
  }

  getDetailsSinglePlanFood(foodPlanGroupId: number, foodPlanId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId, this.options);
  }

  getDetailsMeal(foodPlanGroupId: number, foodPlanId: number, mealId: number): Observable<Object> {
    return this.http.get(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId,
      this.options
    );
  }

  getTypesFood(): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/food/get-types", this.options);
  }

  getIndexFood(typeId: number = null): ServerDataSource {
    let query = typeId ? "?type=" + typeId : "";
    return new ServerDataSource(this.http, {
      filterFieldKey: "name",
      endPoint: environment.baseUrl + "admin/food" + query,
      dataKey: "data",
    });
  }

  changeTitle(dataFood: any) {
    this._dataFood.next(dataFood);
  }

  updateSinglePlanActivated(value: number) {
    this.singlePlanActivated$.next(value);
  }

  updatemealActivated(value: number) {
    this.mealActivated$.next(value);
  }

  updatePlanGroupId(value: number) {
    this.planGroupId$.next(value);
  }

  associatesFoodToMeal(foodPlanGroupId: number, foodPlanId: number, mealId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId + "/add-food",
      request,
      this.options
    );
  }

  notifyAboutChange(update) {
    this.subjectNotifier.next(update);
  }

  notifyUpdateChange() {
    this.subjectUpdateNotifier.next({ edit: true });
  }

  newFood(request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food", request, this.options);
  }

  editFood(foodId: number, request: any): Observable<ArrayBuffer> {
    return this.http.put(environment.baseUrl + "admin/food/" + foodId, request, this.options);
  }

  getTemplate(): ServerDataSource {
    return new ServerDataSource(this.http, {
      endPoint: environment.baseUrl + "admin/food_plan_group/get-templates",
      dataKey: "data",
    });
  }

  assignTemplateFoodPlan(request: any, foodPlanId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food_plan_group/" + foodPlanId + "/clone-assign/", request, this.options);
  }

  assignEmptyFoodPlan(request: any, userId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/food_plan_group/", request, this.options);
  }

  assignPDFFoodPlan(request: any, userId: number): Observable<ArrayBuffer> {
    const option = this.options;
    option.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/food_plan_group/", request, option);
  }

  clonePreviousPlan(foodPlanGroupId: number, request: any): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/assign-food-plan", request, this.options);
  }

  deleteFoodPlan(id: number) {
    return this.http.delete(environment.baseUrl + "admin/food_plan_group/" + id, this.options);
  }

  /**
   * Remove the meal from the single food plan
   * @param foodPlanGroupId
   * @param foodPlanId
   * @param mealId
   * @param foodId
   * @returns Observable
   */
  dissociateFood(foodPlanGroupId: number, foodPlanId: number, mealId: number, foodId: number): Observable<ArrayBuffer> {
    return this.http.delete(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId + "/remove-food/" + foodId,
      this.options
    );
  }

  updateAssociatedFood(foodPlanGroupId: number, foodPlanId: number, mealId: number, foodId: number, request: any): Observable<ArrayBuffer> {
    return this.http.put(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId + "/update-food/" + foodId,
      request,
      this.options
    );
  }

  updateFoodPlanUser(request: any, planId: number, userId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/food_plan_group/" + planId, request, this.options);
  }

  updateFoodPlanUserPDF(formData: any, planId: number, userId: number): Observable<any> {
    const option_video = this.options;
    formData.append("_method", "PUT");
    option_video.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.post(environment.baseUrl + "admin/registry/" + userId + "/food_plan_group/" + planId, formData, option_video);
  }

  updateFoodPlan(request: any, planId: number): Observable<ArrayBuffer> {
    return this.http.post(environment.baseUrl + "admin/food_plan_group/" + planId, request, this.options);
  }

  updateFoodPlanSingle(request: any, foodPlanGroupId: number, foodPlanId: number) {
    return this.http.put(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId, request, this.options);
  }

  deleteFoodPlanSingle(foodPlanGroupId: number, foodPlanId: number) {
    return this.http.delete(environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId, this.options);
  }

  updateMealToPlanSingle(request: any, foodPlanGroupId: number, foodPlanId: number, mealId: number) {
    return this.http.put(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId,
      request,
      this.options
    );
  }

  deleteMealToPlanSingle(foodPlanGroupId: number, foodPlanId: number, mealId: number) {
    return this.http.delete(
      environment.baseUrl + "admin/food_plan_group/" + foodPlanGroupId + "/food_plan/" + foodPlanId + "/meal/" + mealId,
      this.options
    );
  }

  deleteFood(id: number) {
    return this.http.delete(environment.baseUrl + "admin/food/" + id, this.options);
  }

  getDetailsFood(foodId: number): Observable<Object> {
    return this.http.get(environment.baseUrl + "admin/food/" + foodId, this.options);
  }

  clickBtnUpdateFood(foodId: number, edit: boolean) {
    this.$updateFood.next({
      foodId: foodId,
      edit: edit,
    });
  }

  clearUpdateFoodQueue() {
    this.$updateFood = new BehaviorSubject<any>(null);
  }
}
