import { HeaderService } from './../../services/header.service';
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PushNotification } from '../../services/PushNotification.model';
import { FirebaseService } from '../../services/firebase.service';
import { Subscriber } from 'rxjs';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  private listTitles: any[];
  public location: Location;
  private sidebarVisible: boolean;
  private mobile_menu_visible: any = 0;
  private toggleButton: any;
  
  subscriptions = {};
  newNotifications: boolean = false;

  title: string;
  backBtnConf = {
    disabled: true,
    path: null
  };

    
  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    public headerService: HeaderService,
    private firebaseService: FirebaseService
  ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
       var $layer: any = document.getElementById('bodyClick');
       if ($layer) {
         $layer.remove();
         this.mobile_menu_visible = 0;
       }
   });
   this.headerService.currentTitle$.subscribe(currentTitle => {
    this.title = currentTitle;
   });
   this.headerService.backBtnConf$.subscribe( (backBtnConf: any) => {
    this.backBtnConf = backBtnConf;
   });
   this.listenForNewPushNotifications();
  }
  
  collapse(){
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    }else{
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }
  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
      const html = document.getElementsByTagName('html')[0];

      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);

      html.classList.add('nav-open');

      this.sidebarVisible = true;
  };
  sidebarClose() {
      const html = document.getElementsByTagName('html')[0];
      this.toggleButton.classList.remove('toggled');
      const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];

      this.sidebarVisible = false;
      html.classList.remove('nav-open');
  };
  sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const html = document.getElementsByTagName('html')[0];
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];

      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
      const html = document.getElementsByTagName('html')[0];

      if (this.mobile_menu_visible == 1) {
          // $('html').removeClass('nav-open');
          html.classList.remove('nav-open');
          if ($layer) {
              $layer.remove();
          }
          setTimeout(function() {
              $toggle.classList.remove('toggled');
          }, 400);

          this.mobile_menu_visible = 0;
      } else {
          setTimeout(function() {
              $toggle.classList.add('toggled');
          }, 430);

          var $layer = document.createElement('div');
          $layer.setAttribute('id', 'bodyClick');


          if (html.getElementsByTagName('body')) {
              document.getElementsByTagName('body')[0].appendChild($layer);
          }

          $layer.onclick = function() { //asign a function
            html.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            setTimeout(function() {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
            const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];

            if (window.innerWidth < 991) {
              setTimeout(function(){
                mainPanel.style.position = '';
              }, 500);
            }
          }.bind(this);

          html.classList.add('nav-open');
          this.mobile_menu_visible = 1;

      }
  };

  listenForNewPushNotifications() {
    this.subscriptions["newNotifications"] = this.firebaseService.listenForPushNotifications().subscribe(notification => {
      this.newNotifications = true;
    });
  }

  goToPage(path: string) {
    if(!path) return;
    this.router.navigate([path]);
    //restore default conf
    this.headerService.setHeaderBackBtnConf({
			disabled: true,
			path: null
		});
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach((subscription: Subscriber<any>) => {
      if (subscription && 'unsubscribe' in subscription) {
        subscription.unsubscribe();
      }
    });
  }

    logout() {
        localStorage.clear();
        this.router.navigate(['/pages/login']);
    }

    openNav() {
        document.getElementById("notificationsSidepanel").classList.add("active");
        const body = document.querySelector("body");
        body.classList.add("notificationsSidepanel-activated");
        this.newNotifications = false;
    }

}
