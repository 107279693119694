import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, ElementRef, OnInit, ViewChild, Input} from '@angular/core';
import {NgbDate, NgbDateParserFormatter, NgbModal, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbDateCustomParserFormatter } from "../../dateformat";
import {Subscription} from "rxjs";
import { ToastrService } from "ngx-toastr";
import {PianoAllenamentoService} from "../../pages/piano-allenamento/piano-allenamento.service";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-store-piano-allenamento',
  templateUrl: './store-piano-allenamento.component.html',
  styleUrls: ['./store-piano-allenamento.component.css'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter} // formatta la data in dd/mm/yyyy
  ]
})
export class StorePianoAllenamentoComponent implements OnInit {

  @Input() typePlanWorkout : string;
  @ViewChild('theModalPlanTemplate') theModalPlanTemplate: ElementRef;
  isWorkoutTamplate: boolean = false;
  isWorkoutTamplateUser: boolean = false;
  workoutPlanForm: FormGroup;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  userId: number | null;
  routeSub: Subscription;
  subscriptions: Subscription[] = [];
  draft: number | null;

  constructor(
      private modalService: NgbModal,
      private fb: FormBuilder,
      private calendar: NgbCalendar,
      private messageFormatter: MessageFormatterService,
      public formatter: NgbDateParserFormatter,
      private planWorkoutSv: PianoAllenamentoService,
      private route: ActivatedRoute,
      private toastr: ToastrService,
      private router: Router,
  ) { }

  ngOnInit(): void {
    switch(this.typePlanWorkout) {
      case 'template':{
        this.isWorkoutTamplate = true;
        this.isWorkoutTamplateUser = false;
        break;
      }
      case 'templateUserPersonalized':{
        this.isWorkoutTamplateUser = true;
        this.isWorkoutTamplate = false;
        this.routeSub = this.route.params.subscribe(params => {
          this.userId = params["idUser"];
        });
        break;
      }
      default: {
        break;
      }
    }
    this.setupForm();
  }

  open() {
    this.modalService.open(this.theModalPlanTemplate, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  }

  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
        date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
        this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  setupForm() {
    this.workoutPlanForm = this.fb.group({
      titleNewPlanWorkout: new FormControl("", [Validators.required]),
      dpWorkoutFromDate:   new FormControl(""),
      dpWorkoutToDate:     new FormControl(""),
      noteNewPlanWorkout:  new FormControl(""),
    });
  }

  buildRequestNewWorkoutPlan(registry_id, name, start, end, note) {
    return {
      registry_id: registry_id,
      name: name,
      start: start,
      end: end,
      note: note,
    };
  }

  createWorkoutPlan() {
    const titleNewPlanWorkout = this.workoutPlanForm.get("titleNewPlanWorkout").value || null;
    if(typeof(this.fromDate) != "undefined"){
      const a = this.fromDate;
      let anno_from = a.year;
      let mese_from = a.month.toString();
      if(mese_from.length < 2){mese_from= "0"+mese_from;}
      let giorno_from = a.day.toString();
      if(giorno_from.length < 2){giorno_from= "0"+giorno_from;}
      var start = anno_from+'-'+mese_from+'-'+giorno_from;
    }
    if(typeof(this.toDate)!= "undefined"){
      const b = this.toDate;
      let anno_to = b.year;
      let mese_to = b.month.toString();
      if(mese_to.length < 2){mese_to= "0"+mese_to;}
      let giorno_to = b.day.toString();
      if(giorno_to.length < 2){giorno_to= "0"+giorno_to;}
      var end = anno_to+'-'+mese_to+'-'+giorno_to;
    }
    const noteNewPlanWorkout = this.workoutPlanForm.get("noteNewPlanWorkout").value || null;

    const request = this.buildRequestNewWorkoutPlan(
        this.userId,
        titleNewPlanWorkout,
        start,
        end,
        noteNewPlanWorkout,
    );

    const sub = this.planWorkoutSv.newPlanWorkout(request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modalService.dismissAll();
          switch(this.typePlanWorkout) {
            case 'template':{
              this.router.navigate(['/piano-allenamento/dettaglio-piano-allenamento/' + res.data.id]);
              this.planWorkoutSv.updatePlanWorkoutId(res.data.id);
              break;
            }
            case 'templateUserPersonalized':{
              this.router.navigate(['/dettaglio-utente/' + this.userId + '/piano-allenamento/' + res.data.id]);
              this.planWorkoutSv.updatePlanWorkoutId(res.data.id);
              break;
            }
            default: {
              break;
            }
          }
          this.toastr.success(res.message, 'Operazione eseguita con successo!',{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
        }
      },
      error: err => {
        let message = err.error.message + ": </br>";
        if (err.error.data) {
          for (const error in err.error.data.errors) {
            if (typeof (err.error.data.errors[error]) !== "undefined") {
              message += err.error.data.errors[error][0] + " ";
            }
          }
        }
        this.toastr.error(message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
          enableHtml: true
        });
      }
    });
    this.subscriptions.push(sub);
  }

}
