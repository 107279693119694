<div class="sidebar" data-color="red">

  <div class="sidebar-wrapper">
    <ul class="nav">
      <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
        <!--If is a single link-->
        <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" style="display: flex; align-items: center;">
          <i *ngIf="menuitem.icontype" class="now-ui-icons {{ menuitem.icontype }}"></i>
          <img *ngIf="menuitem.iconPath" class="svg-side-icons" width="25px" height="25px" [src]="menuitem.iconPath"
            alt="">
          <p>{{ menuitem.title }}</p>
        </a>
        <!--If it have a submenu-->
        <a data-toggle="collapse" href="#{{ menuitem.collapse }}" *ngIf="menuitem.type === 'sub'" (click)="
            myFunc($event, menuitem);
            menuitem.isCollapsing === undefined &&
              (menuitem.isCollapsed = !menuitem.isCollapsed)
          " [attr.aria-expanded]="!menuitem.isCollapsed">
          <i *ngIf="menuitem.icontype !== null" class="now-ui-icons {{ menuitem.icontype }}"></i>
          <p>{{ menuitem.title }}<b class="caret"></b></p>
        </a>

        <!--Display the submenu items-->
        <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
          [ngbCollapse]="menuitem.isCollapsed">
          <ul class="nav">
            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
              <a [routerLink]="[menuitem.path, childitem.path]">
                <span class="sidebar-mini-icon">{{ childitem.ab }}</span>
                <span class="sidebar-normal">{{ childitem.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>