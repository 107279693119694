import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export class ChatPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.path === 'chat') {
      return load();
    } else {
      return of(null);
    }
  }
}