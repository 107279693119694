import { EventEmitter, Injectable, Output } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _loading = new BehaviorSubject<boolean>(false);
  private _isDelegatedToInterceptor: boolean = true;

  public readonly loading$ = this._loading.asObservable();
  @Output() emitLoadingFinished = new EventEmitter();

  constructor() { }

  show() {
    setTimeout(() => {
      this._loading.next(true);
    });
  }

  hide() {
    this._loading.next(false);
    this.emitLoadingFinished.emit("loadingFinished");
  }

  /**
   * 
   * @param mode the modality of the behaviour of the interceptor
   * 'Manual': the loader is hided or shown by other components
   * 'Interceptor' the behaviour of the loader is managed by the interceptor
   * @returns true if the mode setting is successful, false otherwise
   */
  setControlOfLoader(mode: 'Manual' | 'Interceptor'): boolean {
    if(mode === 'Manual') {
      this._isDelegatedToInterceptor = false;
      return true;
    } else {
      this._isDelegatedToInterceptor = true;
      return true;
    }
  }

  /**
   * 
   * @returns true if the behaviour of the loader is managed by the interceptor, false otherwise
   */
  isDelegatedToInterceptor(): boolean {
    return this._isDelegatedToInterceptor;
  }
}
