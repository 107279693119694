import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
	NgbDate,
	NgbDateParserFormatter,
	NgbModal,
	NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateCustomParserFormatter} from "../../dateformat";

@Component({
	selector: 'app-generic-modal',
	templateUrl: './generic-modal.component.html',
	styleUrls: ['./generic-modal.component.css'],
	providers: [
		{provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}]
})
export class GenericModalComponent implements OnInit {

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	@ViewChild("genericModal", {static: true}) genericModal: TemplateRef<GenericModalComponent>;

	private modalRef: NgbModalRef = null;

	@Input() data: any = null;

	onSave: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private modalService: NgbModal,
	) { }

	ngOnInit(): void {
	}

	show() {
		this.modalRef = this.modalService.open(this.genericModal)
	}

	hide() {
		this.modalRef.close();
	}

	save() {
		this.onSave.emit({status: "saved"});
	}

}
