import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'pdfFilePipe'
})
export class PdfFilePipe implements PipeTransform {

  constructor(
      private http: HttpClient,
  ) {}

  async transform(filename: string): Promise<string> {
    const fileExt = filename.split('.').pop();
    //TODO add extention control if jpg/jpeg/pdf

    const src = environment.baseUrl + "admin/file/" + filename;
    const currentUser: any = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    const token = currentUser.token;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`, 'Accept':'application/json'});
    const fileBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(fileBlob);
      reader.onload = function(e) {
        let buffer = e.target.result as any;
        let blob = new Blob([new Uint8Array(buffer)], { type: 'application/'+ fileExt});

        return window.URL.createObjectURL(blob);
      }
    });

  }



}
