// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// INSERIRE /api/v1 a FINE URL!!

export const environment = {
  production: false,
  baseUrl: "https://test-rbcoaching-api.dotitsrl.it/api/v1/",
  firebaseConfig: {
    apiKey: "AIzaSyDusr9yCLZmCqx75Vf_irAtR1Hd5r3lcBg",
    authDomain: "rb-coaching.firebaseapp.com",
    projectId: "rb-coaching",
    storageBucket: "rb-coaching.appspot.com",
    messagingSenderId: "202020971615",
    appId: "1:202020971615:web:39ce93c211c586b6bce7ea",
    measurementId: "G-JX6MXYENHL",
    vapidKey: "BGRlBTxn4HRvwgwlW9uUGupQg6nVa1h-o9EXKGyZykoOR5P-eOz4ztYpr0aWVN8-ORakEbluTtnCwsQ3ZzIs5Nc"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
