import { MessageFormatterService } from './../../services/messageFormatter.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UtentiService} from "../utenti/utenti.service";
import { ToastrService } from 'ngx-toastr';

export interface tableRow {
  id: number,
  name: string,
  mail: string,
  user_id: number
}

@Component({
  selector: 'app-invio-notifiche-massive',
  templateUrl: './invio-notifiche-massive.component.html',
  styleUrls: ['./invio-notifiche-massive.component.css']
})

export class InvioNotificheMassiveComponent implements OnInit {

  //table var
  tableRows: tableRow[] = [];	/**
  * First number of the array is the default value for the number of
  * rows to display in a single table page
  */
  perPageOptions: number[] = [50, 10, 20, 30]
  showPerPage: number = this.perPageOptions[0];
  totalPages: number = 1;
  currentPage: number = 1;

	isLoadingUserList: boolean = true;

  massiveSelection: boolean = false;
  rowsChecked: Set<number> = new Set();

  sendingMassiveNotificationsForm: FormGroup;

  usersSelected: number[] = [];
  searchParam = "";


  constructor(
      private utentiService: UtentiService,
      private toastr: ToastrService,
      private messageFormatter: MessageFormatterService,
      private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.setupForm();
    this.getTableData();
  }

  setupForm() {
    this.sendingMassiveNotificationsForm = this.fb.group({
      notificationTitle: new FormControl("", [Validators.required]),
      notificationMsg: new FormControl("", [Validators.required]),
    });
  }

  async getTableData(currentPage: number = 1, nRows: number = this.showPerPage) {
    //check range limits
    if(currentPage < 1 || currentPage > this.totalPages) return;
    this.isLoadingUserList = true;
    //nRows can be a string in runtime, but TS doesn't recognize that
    try {
      let numberRows: number = parseInt(nRows.toString(), 10); 
      let result = await this.utentiService.getUserList(currentPage, numberRows, this.searchParam).toPromise();
      this.totalPages = (Math.floor(result.data.registries.total / numberRows))+1;
      this.currentPage = currentPage;
      this.tableRows = result.data.registries.data;
    } catch (e) {
      console.log('Errore caricamento dati tabella invio notifiche massive', e);
    }
    this.isLoadingUserList = false;
  }

  /**
   * 
   * @returns the enumerated list of the pages [1, 2, ..., n]
   */
  pages(): number[] {
    return [...Array(this.totalPages).keys()];
  }

  checkedAllRow(event:any){
    if (event.target.checked == true) {
      this.tableRows.forEach(row => {
        if (!this.rowsChecked.has(row.id)) {
          this.rowsChecked.add(row.id);
          document.getElementById(row.id as unknown as string).click();
        }
      });
    } else {
      this.tableRows.forEach(row => {
        if (this.rowsChecked.has(row.id)) {
          this.rowsChecked.delete(row.id);
          document.getElementById(row.id as unknown as string).click();
        }
      });
    }
  }

  toggleRow(event, rowId){
    if (event.target.checked == true){
      this.rowsChecked.add(rowId);
    } else {
      this.rowsChecked.delete(rowId);
    }
  }


  buildRequest(title, message, users, massiveNotification) {
    return {
      title: title,
      body: message,
      users: users,
      massive: massiveNotification
    };
  }

  /**
	 * Set n as the number of rows for the table, default is 50
	 * @param n new number of rows to display for each table page
	 */
	changeNumberOfRows(n:number = 50):void {
		this.showPerPage = n as number;
    this.getTableData();
	}

  onSearch(event) {
    this.searchParam = event.target.value;
    this.getTableData();
  }

  async sendNotification() {
    const notificationTitle = this.sendingMassiveNotificationsForm.get("notificationTitle").value || null;
    const notificationMsg = this.sendingMassiveNotificationsForm.get("notificationMsg").value || null;
    this.tableRows.forEach(row => {
      if (this.rowsChecked.has(row.id)){
        this.usersSelected.push(row.user_id);
      }
    });
    const users = this.massiveSelection ? [] : this.usersSelected;
    const buildRequest = this.buildRequest(
        notificationTitle,
        notificationMsg,
        users,
        this.massiveSelection
    );
    let response = await this.utentiService.sendNotification(buildRequest).toPromise();

    if(response.success) {
        this.toastr.success(response.message, this.messageFormatter.getSuccessTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
        this.sendingMassiveNotificationsForm.get("notificationTitle").reset();
        this.sendingMassiveNotificationsForm.get("notificationMsg").reset();
        this.usersSelected = [];
        this.rowsChecked.clear();
      } else {
        this.toastr.error(response.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }
  }

  massiveSelected(checked) {
    this.massiveSelection = checked;
  }

	toIterator(n: number): number[] {
		return Array(n).fill(0);
	}

}
