import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PianoIntegrazioneService {

	private HTTPheaders;
  	private options;

  	subjectNotifier: Subject<null> = new Subject<null>();
	singlePlanActivated$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  	constructor(private http: HttpClient) {
		this.HTTPheaders = ({
			"Content-Type": "application/json",
		  });
	  
		  this.options = ({
			headers: this.HTTPheaders,
		  });
	  }

	getSupplementPlanGroup({trainerId}:{trainerId?:string}={}):ServerDataSource {
		return new ServerDataSource(this.http, {
		  endPoint: environment.baseUrl + "admin/supplement_plan/?"+(trainerId ? 'trainer_id='+trainerId : ''),
		  dataKey: "data",
		  //   totalKey: "total",
		  //   perPage: "per_page",
		  //   pagerPageKey: "page",
		  //   pagerLimitKey: "per_page",
		});
	}

	getShortListUsers():ServerDataSource {
		return new ServerDataSource(this.http, {
		  endPoint: environment.baseUrl + "admin/registry/get-list",
		  dataKey: "data",
		  //   totalKey: "total",
		  //   perPage: "per_page",
		  //   pagerPageKey: "page",
		  //   pagerLimitKey: "per_page",
		});
	}

	getTemplateList():ServerDataSource {
		return new ServerDataSource(this.http, {
		  endPoint: environment.baseUrl + "admin/supplement_plan/get-templates",
		  dataKey: "data",
		  //   totalKey: "total",
		  //   perPage: "per_page",
		  //   pagerPageKey: "page",
		  //   pagerLimitKey: "per_page",
		});
	}

	getSupplements():ServerDataSource {
		return new ServerDataSource(this.http, {
		  endPoint: environment.baseUrl + "admin/supplement",
		  filterFieldKey: 'name',
		  dataKey: "data",
		  //   totalKey: "total",
		  //   perPage: "per_page",
		  //   pagerPageKey: "page",
		  //   pagerLimitKey: "per_page",
		});
	}

	getIndexSupplements(typeId:number = null):ServerDataSource {
		let query = typeId ? ('?type=' + typeId) : '';
		return new ServerDataSource(this.http, {
		  filterFieldKey: 'name',
		  endPoint: environment.baseUrl + "admin/supplement" + query,
		  dataKey: "data",
		});
	}

	getTypesSupplements(): Observable<Object> {
		return this.http.get(environment.baseUrl + "admin/supplement/get-types", this.options);
	}

	deleteSupplementPlan(id:number) {
		return this.http.delete(environment.baseUrl + 'admin/supplement_plan/'+id, this.options);
	}

	newSupplementPlan(request: any): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/", request, this.options);
	}

	assignClonePlan(request: any, planId:number): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/"+planId+"/clone-assign/", request, this.options);
	}

	showSupplementPlanUser(supplementPlanId:number): Observable<Object> {
		  // http://dev-rbcoaching-api.dotitsrl.it/api/v1/admin/supplement_plan/:supplement-plan-id
		return this.http.get(environment.baseUrl + "admin/supplement_plan/" + supplementPlanId , this.options);
	}

	showSupplementPlan(planId:number): Observable<Object> {
		return this.http.get(environment.baseUrl + "admin/supplement_plan/" + planId, this.options);
	}

	updateSupplementPlan(request: any, planId:number): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/"+planId, request, this.options);
	}

	addSupplement(request: any, planId:number, mealId:number): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/"+planId+"/meal/"+mealId+"/add-supplement", request, this.options);
	}

	newSupplement(request): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement", request, this.options);
	}

	getDetailMeal(planId: number, mealId:number): Observable<Object> {
		return this.http.get(environment.baseUrl + "admin/supplement_plan/" + planId + "/meal/" + mealId, this.options);
	}

	updateSinglePlanActivated(value: number) {
		this.singlePlanActivated$.next(value);
	}

	newMeal(planId:number, request: any): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/" + planId + "/meal", request, this.options);
	}

	dissociateSupplement(planId: number, mealId:number, supplementId:number): Observable<ArrayBuffer> {
		return this.http.delete(environment.baseUrl + "admin/supplement_plan/"+planId+"/meal/"+mealId+"/remove-supplement/"+supplementId, this.options);
	}

	updateAssociatedSupplement(request: any, planId: number, mealId:number, supplementId:number): Observable<ArrayBuffer> {
		return this.http.put(environment.baseUrl + "admin/supplement_plan/"+planId+"/meal/"+mealId+"/update-supplement/"+supplementId, request, this.options);
	}

	updateMealSingle(request: any, supplementPlanId: number, mealId:number) {
		return this.http.put(environment.baseUrl + "admin/supplement_plan/" + supplementPlanId + "/meal/" + mealId, request, this.options);
	}

	deleteMealSingle(supplementPlanId: number, mealId:number) {
		return this.http.delete(environment.baseUrl + 'admin/supplement_plan/'+ supplementPlanId + '/meal/' + mealId, this.options);
	}

	deleteSupplement(id:number) {
		return this.http.delete(environment.baseUrl + 'admin/supplement/'+ id, this.options);
	}

	getDetailsSupplement(idSupplement:number){
		return this.http.get(environment.baseUrl + "admin/supplement/" + idSupplement, this.options);
	}

	updateSupplement(request: any, supplementId: number) {
		return this.http.put(environment.baseUrl + "admin/supplement/" + supplementId, request, this.options);
	}

	getTemplate(): ServerDataSource {
		return new ServerDataSource(this.http, {
			endPoint: environment.baseUrl + "admin/supplement_plan/get-templates",
			dataKey: "data",
		});
	}

	assignTemplateSupplementPlan(request: any, supplementPlanId:number): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/"+supplementPlanId+"/clone-assign/", request, this.options);
	}

	assignEmptySupplementPlan(request: any): Observable<ArrayBuffer> {
		return this.http.post(environment.baseUrl + "admin/supplement_plan/", request, this.options);
	}

	getBannerSupplementPlan(){
		return this.http.get(environment.baseUrl + "admin/banner", this.options);
	}

	postBannerSupplementPlan(request: any): Observable<ArrayBuffer> {
		const option_image = this.options;
		option_image.headers = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};
		return this.http.post(environment.baseUrl + "admin/banner", request, option_image);
	}
}
