<button class="btn btn-primary border-radius-large mb-4 d-flex align-items-center justify-content-between btn-shadow" (click)="open(modal1)" *ngIf="insidePlan">
    <p class="m-0">Aggiungi alimento</p>
    <span class="plus">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs>
            <g transform="translate(-944 -347)">
                <line class="a" y2="12" transform="translate(951 348)"/>
                <line class="a" x2="12" transform="translate(945 354)"/>
            </g>
        </svg>
    </span>
</button>

<button class="btn btn-primary border-radius-large m-0 w-100" id="auto_trigger" *ngIf="!insidePlan" (click)="open(modal2)">
    <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143" class="svg-icons dark mb-1">
        <g transform="translate(4.433 3.071)"><path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571" transform="translate(-5.576 -3.071)"/>
            <path class="a" d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z" transform="translate(-5.576 -3.071)"/>
            <line class="a" y2="3.429" transform="translate(1.281 -1.928)"/><line class="a" y2="3.429" transform="translate(5.853 -1.928)"/>
            <line class="a" y2="3.429" transform="translate(10.424 -1.928)"/>
        </g>
    </svg>
    <span class="ml-1">Nuovo alimento</span>
</button>
