<div class="widget-container">
	<div class="widget-item">
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<rect class="a" width="24" height="24"/><g transform="translate(1.29 2.499)">
				<path class="b" d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" transform="translate(-1.29 -2.499)"/>
				<circle class="b" cx="4" cy="4" r="4" transform="translate(3.71 0.501)"/>
				<path class="b" d="M23,21V19a4,4,0,0,0-3-3.87" transform="translate(-1.29 -2.499)"/>
				<path class="b" d="M16,3.13a4,4,0,0,1,0,7.75" transform="translate(-1.29 -2.499)"/>
			</g>
		</svg>
		<div class="w-info">
			<div class="widget-label" style="align-self: center;">
				<span>Clienti totali</span>
			</div>
			<div class="widget-stats">
				<span>{{widgetInfo?.registries_count}}</span>
			</div>
		</div>
	</div>
	
	<div class="widget-item">
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
			<g transform="translate(0 0.101)"><rect class="a" width="17" height="17" transform="translate(0 -0.101)"/>
				<g transform="translate(1.408 1.404)"><path class="b" d="M15.416,7.727v.647A7.042,7.042,0,1,1,11.24,1.939" transform="translate(-1.333 -1.329)"/>
					<path class="b" d="M15.154,2.667,8.112,9.715,6,7.6" transform="translate(-1.071 -1.254)"/>
				</g>
			</g>
		</svg>
		<div class="w-info">
			<div class="widget-label">
				<span>Piani di allenamento assegnati</span>
			</div>
			<div class="widget-stats">
				<span>{{widgetInfo?.assigned_training_plans}}</span>
			</div>
		</div>
	</div>
	
	<div class="widget-item">
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
			<g transform="translate(1 1)"><rect class="a" width="15" height="15"/>
				<circle class="b" cx="7.5" cy="7.5" r="7.5"/>
				<path class="c" d="M12,6v6l4,2" transform="translate(-5.125 -3.667)"/>
			</g>
		</svg>
		<div class="w-info">
			<div class="widget-label">
				<span>Piani di allenamento in scadenza</span>
			</div>
			<div class="widget-stats">
				<span>{{widgetInfo?.exipiring_training_plans}}</span>
			</div>
		</div>
	</div>

	<a *ngIf="currentUser.is_admin" class="btn btn-primary border-radius-large btn-aggiungi-cliente" routerLink="/nuovo-utente">
		<svg _ngcontent-evw-c91="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="svg-icons size-20 dark"><g _ngcontent-evw-c91="" transform="translate(1 0.101)"><rect _ngcontent-evw-c91="" width="17" height="17" transform="translate(0 -0.101)" class="a"></rect><g _ngcontent-evw-c91="" transform="translate(1.408 1.404)"><path _ngcontent-evw-c91="" d="M12.364,16.227V14.682a3.091,3.091,0,0,0-3.091-3.091H3.864A3.091,3.091,0,0,0,.773,14.682v1.545" transform="translate(-2.181 -2.278)" class="b"></path><circle _ngcontent-evw-c91="" cx="3.091" cy="3.091" r="3.091" transform="translate(1.296 0.041)" class="b"></circle><line _ngcontent-evw-c91="" y2="4.636" transform="translate(13.274 3.904)" class="b"></line><line _ngcontent-evw-c91="" x1="4.636" transform="translate(10.956 6.222)" class="b"></line></g></g></svg>
		<p>Aggiungi nuovo cliente</p>
	</a>
</div>
