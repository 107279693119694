import {Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, OnDestroy} from "@angular/core";
import {environment} from "../../../environments/environment";
import {PdfFilePipe} from "../pipe/pdfFile.pipe";
import {ConfirmDialogService} from "../confirm-dialog/confirm-dialog.service";
import { NgbdModalBasic } from "../modal/modal.component";
import { ImageUploadService } from "../../services/imageUpload.service";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
  providers: [PdfFilePipe],
})
export class ImageUploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isRound: boolean = false;
  @Input() image: string;
  /**
   * @var buttonSaveClicked Every time it change the image is saved on the server
   */
  @Input() buttonSaveClicked: boolean = false;
  /**
   * @var true when the image is saved on the server, it never return to false
   */
  imageSaved: boolean = false;
  state: any = {};
  @ViewChild('input') input: ElementRef;

  isLoadingImage = true;

  assetURL = environment.baseUrl + "admin/file/";

  @Input() label: string;
  @Input() formCtrlName: string;

  previousImage: string;
  placeholder: string = "assets/img/image_placeholder.jpg";

  @Input() cardMemory: boolean = false;

  /**
   * @emits null if the file uploaded is removed, the file uploaded otherwise
   */
  @Output() onFileChanged = new EventEmitter<any>();

  //modal variables
  @ViewChild(NgbdModalBasic) ngbdModalBasic: NgbdModalBasic;
  path: string;

  constructor(
      private confirmDialogService: ConfirmDialogService,
      private pdfFilePipe: PdfFilePipe,
      private imageCardService: ImageUploadService
  ) {
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  ngOnInit() {
    //if a previous state is already saved and there isn't an image given in input
    if(this.imageCardService.isPresentImageCardState(this.label) && !this.image && this.cardMemory) {
      let previousCardState = this.imageCardService.getImageCardStateByName(this.label);
      this.previousImage = previousCardState.url;
      this.state = previousCardState.newFile;
    } else {
      this.previousImage = this.previousImage ? this.previousImage : this.image;
      if(this.state.imageUploaded == null && !this.imageSaved) {
        this.state = {
          file: null,
          imageUploaded: null//this.image != null ? this.image : "assets/img/image_placeholder.jpg"
        };
      }
    }
  }

  ngOnDestroy(): void {
    if(this.cardMemory) this.imageCardService.saveImageCardState(this.label, this.previousImage, this.state);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.buttonSaveClicked && !changes.buttonSaveClicked.isFirstChange()) {
      //reset card image upload state
      this.state.file = null;
      this.imageSaved = true;
      //this.state.imageUploaded = null;
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let extensionAllowed: any = { "png": true, "jpeg": true, "jpg": true };
    if (extensionAllowed) {
      var nam = file.name.split('.').pop();
      if (!extensionAllowed[nam]) {
        alert("Puoi caricare uno di questi formati img: " + Object.keys(extensionAllowed))
        return;
      }
    }

    reader.onloadend = () => {
      this.state.file = file;
      //this.image = null;
      this.state.imageUploaded = reader.result;
    };
    reader.readAsDataURL(file);

    this.onFileChanged.emit(file);

  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  handleClick() {
    this.input.nativeElement.value = "";
    this.input.nativeElement.click();
  }

  handleRemove() {
    //this.image = this.previousImage;
    this.state.file = null;
    this.state.imageUploaded = null;
    //if(this.image) this.state.imageUploaded = this.image
    //else this.state.imageUploaded = "assets/img/image_placeholder.jpg";
    
    this.onFileChanged.emit(null);
  }

  downloadFile() {
    this.pdfFilePipe.transform(this.image)
        .then(value => {
          const link = document.createElement('a');
          link.href = value;
          const fileExt = this.image.split('.').pop();
          link.download = this.label + '.' + fileExt;
          link.click();
        }, error => {
          this.confirmDialogService.confirm("ATTENZIONE", "Problema nel recuperare il file");
        })
  }

  /**
   * Open a modal with an image
   * @param path the path of the image
   * @returns false if the path doesn't contain filename 
   */
  openModal(path: string): boolean{
    let filename: string = path.split('/').slice(-1)[0];
    if(filename === 'null' || filename === 'undefined') {
      return false;
    } else {
      this.path = path;
      this.ngbdModalBasic.open();
      return true;
    }
  }

  imageLoaded() {
    this.isLoadingImage=false;
  }
}
