<ng-template #editPlanModal let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="hide()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Duplica piano</h6>
    </div>
<div class="modal-body">
    <div class="modal-body">
            <div class="row align-items-end">
                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <input type="text" name="name" [(ngModel)]="planName" id="name" class="form-control square-input" placeholder="Nome piano allenamento" required/>
                        <svg *ngIf="planWorkout" xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons primary form-group__svg">
                            <g id="Raggruppa_160123" data-name="Raggruppa 160123" transform="translate(-941.5 -862)">
                                <line id="Linea_62" data-name="Linea 62" x2="12.07" transform="translate(946.465 868.299)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                                <line id="Linea_63" data-name="Linea 63" y2="10.597" transform="translate(946.465 863)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                                <line id="Linea_64" data-name="Linea 64" y2="10.597" transform="translate(958.535 863)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                                <line id="Linea_66" data-name="Linea 66" y2="5.376" transform="translate(962.5 865.611)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                                <line id="Linea_67" data-name="Linea 67" y2="5.376" transform="translate(942.5 865.611)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                            </g>
                        </svg>
                        <svg *ngIf="planSupplement" xmlns="http://www.w3.org/2000/svg" width="23.14" height="23.14" viewBox="0 0 23.14 23.14" class="svg-icons primary form-group__svg">
                            <g id="Raggruppa_160163" data-name="Raggruppa 160163" transform="translate(21.726 21.726) rotate(180)">
                                <rect id="Rettangolo_140008" data-name="Rettangolo 140008" width="8.188" height="19.897" rx="4.094" transform="translate(14.069 0.453) rotate(45)" fill="none" stroke="#a7061e" stroke-width="2"/>
                                <path id="Rettangolo_140009" data-name="Rettangolo 140009" d="M4.383,0h0A4.383,4.383,0,0,1,8.766,4.383v6.268a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.383A4.383,4.383,0,0,1,4.383,0Z" transform="translate(14.113 0) rotate(45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                            </g>
                        </svg>
                        <svg *ngIf="planFood" xmlns="http://www.w3.org/2000/svg" width="18.157" height="23.24" viewBox="0 0 18.157 23.24" class="svg-icons primary form-group__svg">
                            <g id="Raggruppa_160162" data-name="Raggruppa 160162" transform="translate(1.414 1.414)">
                                <circle id="Ellisse_279" data-name="Ellisse 279" cx="7.638" cy="7.638" r="7.638" transform="translate(0.467 5.55)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                                <path id="Tracciato_83326" data-name="Tracciato 83326" d="M2.185,0A7.226,7.226,0,0,1,4.371,4.618,7.231,7.231,0,0,1,2.185,9.235,7.22,7.22,0,0,1,0,4.618,7.07,7.07,0,0,1,2.185,0Z" transform="translate(0 3.091) rotate(-45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                                <path id="Tracciato_83327" data-name="Tracciato 83327" d="M1.64,0A5.423,5.423,0,0,1,3.28,3.465,5.426,5.426,0,0,1,1.64,6.931,5.418,5.418,0,0,1,0,3.465,5.305,5.305,0,0,1,1.64,0Z" transform="translate(12.017 2.015) rotate(45)" fill="#fff" stroke="#a7061e" stroke-width="2"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <div class="col-12 px-0">
                            <div class="dp-hidden position-absolute">
                                <div class="input-group">
                                    <input
                                            name="datepickerClone"
                                            class="invisible"
                                            ngbDatepicker
                                            #datepicker="ngbDatepicker"
                                            [autoClose]="'outside'"
                                            (dateSelect)="onDateSelection($event, datepicker)"
                                            [displayMonths]="2"
                                            [dayTemplate]="t"
                                            outsideDays="hidden"
                                            [startDate]="fromDate!"
                                            tabindex="-1">
                                    <ng-template #t let-date let-focused="focused">
                                        <span class="custom-day"
                                              [class.focused]="focused"
                                              [class.range]="isRange(date)"
                                              [class.faded]="isHovered(date) || isInside(date)"
                                              (mouseenter)="hoveredDate = date"
                                              (mouseleave)="hoveredDate = null">
                                        {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    #dpFromDate
                                    class="form-control square-input" placeholder="Inizio"
                                    name="dpFromDate"
                                    [value]="formatter.format(fromDate)"
                                    (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="col-12 px-0">
                            <div class="form-group">
                                <input
                                    #dpToDate
                                    class="form-control square-input" placeholder="Fine"
                                    name="dpToDate"
                                    [value]="formatter.format(toDate)"
                                    (input)="toDate = validateInput(toDate, dpToDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-8 ">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="note" [(ngModel)]="planNote" id="note" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="save()">
                        <span class="btn__text m-0">Salva</span>
                    </button>
                </div>
            </div>
    </div>
</div>
</ng-template>
