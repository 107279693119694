<ng-template #genericModal let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="hide()">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Duplica piano</h6>
    </div>
<div class="modal-body">
    <div class="modal-body">
            <div class="row align-items-end">
                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <input type="text" name="name" formControlName="name" id="name" class="form-control square-input" placeholder="Nome piano allenamento" required/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons primary form-group__svg">
                            <g transform="translate(-941.5 -862)">
                                <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
                                <line class="a" y2="10.597" transform="translate(946.465 863)"/>
                                <line class="a" y2="10.597" transform="translate(958.535 863)"/>
                                <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
                                <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <div class="col-12 px-0">
                            <div class="dp-hidden position-absolute">
                                <div class="input-group">
                                    <input
                                            name="datepickerWorkout"
                                            class="invisible"
                                            ngbDatepicker
                                            #datepicker="ngbDatepicker"
                                            [autoClose]="'outside'"
                                            (dateSelect)="onDateSelection($event, datepicker)"
                                            [displayMonths]="2"
                                            [dayTemplate]="t"
                                            outsideDays="hidden"
                                            [startDate]="fromDate!"
                                            tabindex="-1">
                                    <ng-template #t let-date let-focused="focused">
                                        <span class="custom-day"
                                              [class.focused]="focused"
                                              [class.range]="isRange(date)"
                                              [class.faded]="isHovered(date) || isInside(date)"
                                              (mouseenter)="hoveredDate = date"
                                              (mouseleave)="hoveredDate = null">
                                        {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    #dpFromDate
                                    class="form-control square-input" placeholder="Inizio"
                                    name="dpWorkoutFromDate"
                                    [value]="formatter.format(startDate)"
                                    (input)="startDate = validateInput(startDate, dpFromDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="col-12 px-0">
                            <div class="form-group">
                                <input
                                    #dpToDate
                                    class="form-control square-input" placeholder="Fine"
                                    name="dpWorkoutToDate"
                                    [value]="endDate"
                                    (input)="endDate = validateInput(endDate, dpToDate.value)"
                                    (click)="datepicker.toggle()"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                    <rect class="a" width="20" height="20"/>
                                    <g transform="translate(2.5 1.667)">
                                        <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                        <line class="b" y2="3.333" transform="translate(10.833)"/>
                                        <line class="b" y2="3.333" transform="translate(4.167)"/>
                                        <line class="b" x2="15" transform="translate(0 6.667)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-8 ">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="note" formControlName="note" id="note" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="save()">
                        <span class="btn__text m-0">Salva</span>
                    </button>
                </div>
            </div>
    </div>
</div>
</ng-template>
