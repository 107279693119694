import { MessageFormatterService } from "./../../services/messageFormatter.service";
import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PianoAlimentareService } from "../../pages/piano-alimentare/piano-alimentare.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ActionTableComponent } from "../action-table/action-table.component";
import { ServerDataSource } from "ng2-smart-table";
import { UtilitiesService } from "../../services/utilities.service";

@Component({
  template: `
    <div class="modal-header justify-content-between">
      <h6 class="modal-title">Aggiungi alimento</h6>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="now-ui-icons ui-1_simple-remove"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-9">
          <span
            class="badge badge-pill badge-outline mr-1 badge-type-food"
            [class.active]="selectedGroup == item.id"
            *ngFor="let item of typesFood"
            id="{{ item.id }}"
            (click)="loadIndexFood(item.id)">
            {{ item.name }}
          </span>
        </div>
        <div *ngIf="currentUser.is_admin" class="col-sm-3">
          <button class="btn btn-primary border-radius-large m-0 w-100" (click)="open(modal2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143" class="svg-icons dark mb-1">
              <g transform="translate(4.433 3.071)">
                <path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571" transform="translate(-5.576 -3.071)" />
                <path
                  class="a"
                  d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z"
                  transform="translate(-5.576 -3.071)" />
                <line class="a" y2="3.429" transform="translate(1.281 -1.928)" />
                <line class="a" y2="3.429" transform="translate(5.853 -1.928)" />
                <line class="a" y2="3.429" transform="translate(10.424 -1.928)" />
              </g>
            </svg>
            <p>Nuovo alimento</p>
          </button>
        </div>
      </div>
      <div class="">
        <ng2-smart-table
          [settings]="settings"
          [source]="source | filter : selectedGroup"
          (rowSelect)="deselectFirstRow()"
          class="w-100"></ng2-smart-table>
      </div>
    </div>
  `,
})
export class NgbdModal1Content implements OnInit {
  //current user actually logged
  currentUser: any;

  typesFood = [];
  indexFood = [];
  modal2 = NgbdModal2Content;
  modal3 = NgbdModal3Content;
  dataFood = [];
  source: ServerDataSource;
  settings: any;
  typeToFood = {};
  selectedGroup = 0;

  notifierSubscription: Subscription = this.pianoAlimentareService.subjectNotifier.subscribe((notified) => {
    this.loadIndexFoodList();
  });

  constructor(
    private modalService: NgbModal,
    private messageFormatter: MessageFormatterService,
    public activeModal: NgbActiveModal,
    private pianoAlimentareService: PianoAlimentareService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    this.loadTableSettings();
    this.loadTypesFood();
    this.loadIndexFoodList();
    this.pianoAlimentareService.item$.subscribe((dataFood) => (this.dataFood = dataFood));
  }

  loadTypesFood() {
    this.pianoAlimentareService.getTypesFood().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.typesFood = res.data;
          this.typesFood.forEach((t) => (this.typeToFood[t.id] = t.name));
          this.typesFood.unshift({ id: 0, name: "Tutti" });
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
  }
  loadIndexFoodList() {
    this.source = this.pianoAlimentareService.getIndexFood();
  }

  loadIndexFood(idType: number = null) {
    this.selectedGroup = idType;
  }

  open(modal) {
    const modalRef = this.modalService.open(modal, { size: "lg", backdrop: "static", windowClass: "mediumModal", centered: true });
    if (modal === this.modal2) {
      modalRef.componentInstance.typesFood = this.typesFood;
      modalRef.result.then((result) => {
        if (result) {
          this.loadIndexFood();
        }
      });
    }
  }

  sendData(data: any[]) {
    this.pianoAlimentareService.changeTitle(data);
  }

  loadTableSettings() {
    this.settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      hideHeader: true,
      actions: false,
      columns: {
        name: {
          title: "Alimento",
          type: "html",
          filter: true,
          sort: true,
        },
        type_id: {
          title: "tipo",
          type: "html",
          valuePrepareFunction: (value) => {
            return this.typeToFood[value];
          },
          filter: false,
          sort: true,
        },
        dettaglio: {
          title: "",
          type: "custom",
          filter: false,
          sort: false,
          position: "right",
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            instance.actionEmit.subscribe((value) => {
              if (value === "aggiungi") {
                this.sendData([instance.rowData.name, instance.rowData.id, instance.rowData.weight_type, instance.rowData.note]);
                this.open(this.modal3);
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const actions = [
              {
                text: "aggiungi",
                link: "",
                emit: "aggiungi",
                title: "Aggiungi alimenti",
                btnId: "aggiungi_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-edit ",
                classList: "action-btn btn",
              },
            ];
            return actions;
          },
        },
      },
    };
  }

  deselectFirstRow() {
    setTimeout(function () {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach((t) => {
        for (let i = 0, row; (row = t.rows[i]); i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }
}

@Component({
  template: `
    <div class="modal-header justify-content-between">
      <div class="d-flex align-items-center">
        <svg class="mr-2" id="Livello_1" data-name="Livello 1" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 421.03 510.93">
          <path
            d="M413.8,225.05c-9.2-35.73-27.47-65.77-58.64-86.64-25.99-17.4-53.96-24.22-84.62-13.68-12.28,4.22-23.51,10.68-35.19,16.2-2.1,1-4.16,2.8-6.68,1.35,.44-1.53,.9-2.95,1.25-4.39,7.3-29.54,21.04-55.71,41.11-78.58,10.7-12.19,23-22.5,36.26-31.79,8.78-6.16,9.24-18.09,1.21-24.24-1.64-1.26-3.53-2.2-5.31-3.28h-7.98c-37.03,23.13-63.95,55.08-82.31,94.94-1.01-2.06-1.76-3.72-2.63-5.31C184.64,42.3,145.09,17.1,91.15,15.03c-10.16-.39-16.48,6.21-16.37,16.4,.16,14.73,2.69,29.04,7.56,42.96,5.99,17.13,15.24,32.32,27.41,46.07-1.1,.4-1.83,.77-2.61,.94-36.86,7.75-63.89,28.98-82.82,60.95C7.29,211.08,.78,242.78,.06,275.74c-1.09,50.2,11.55,97.26,34.2,141.74,14.46,28.4,32.59,54.13,58.65,73.21,30.6,22.4,62.96,27.62,97.32,8.45,3.61-2.02,7.43-3.66,11.1-5.58,6.25-3.26,12.4-3.03,18.58,.18,5.29,2.75,10.62,5.44,15.94,8.12,20.47,10.32,41.54,11.7,63.23,4.3,19.53-6.66,35.34-18.83,49.27-33.62,27.16-28.82,44.86-63.15,57.16-100.38,15.93-48.23,21.1-97.29,8.27-147.12ZM107.69,54.67c-2.21-9.53-3.2-9.12,6.98-6.74,39.62,9.27,72.39,44.32,78.1,83.37,.07,.46,0,.94,0,1.8-39.58-4.76-75.76-38.18-85.09-78.43Zm228.51,386.33c-10.96,14.41-24.02,26.67-40.74,34.39-16.28,7.52-32.39,7.46-48.25-1.32-3.77-2.09-7.63-4-11.44-6.02-17.19-9.12-34.27-8.87-51.38,.32-8.45,4.54-16.67,9.67-26.31,11.52-17.8,3.42-33.03-2.83-47.03-12.96-19.94-14.44-34.13-33.84-45.85-55.1-23.34-42.36-36.36-87.55-35.18-136.2,.73-30.16,6.85-59.07,24.32-84.43,14.56-21.13,33.65-36.12,59.52-40.88,12.23-2.25,23.77,.97,34.83,5.83,9.25,4.06,18.25,8.69,27.38,13.02,24.13,11.44,48.08,10.92,71.88-1.05,7.84-3.94,15.75-7.76,23.65-11.6,21.57-10.47,42.42-8.42,62.62,3.74,26.21,15.78,41.25,39.74,49.66,68.41,5.13,17.5,6.94,35.49,7.16,60.41-1.99,51.79-19.23,105.08-54.85,151.92Z"
            style="fill: #A7061E;" />
        </svg>
        <div>
          <h6 class="modal-title" *ngIf="!isUpdate">Aggiungi nuovo alimento</h6>
          <h6 class="modal-title" *ngIf="isUpdate && foodSelectedForUpdate">Modifica alimento: {{ foodSelectedForUpdate.name }}</h6>
        </div>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="now-ui-icons ui-1_simple-remove"></i>
      </button>
    </div>
    <form [formGroup]="formNewFood">
      <div class="modal-body general-modal">
        <p>Inserisci i valori nutrizionali per 100 g/ml</p>
        <div class="d-flex flex-column justify-content-end">
          <!-- name -->
          <div class="form-group bckg-body custom-color pl-3">
            <input
              type="text"
              formControlName="nameNewFood"
              id="nameNewFood"
              name="nameNewFood"
              class="form-control square-input"
              placeholder="Nome alimento" />
          </div>
          <!---->
          <div class="d-flex justify-content-start wrap-multiselect">
            <angular2-multiselect
              class="col-5 pl-0"
              name="typeFoodList"
              formControlName="typeFoodList"
              [data]="dropdownTypesFoodList"
              [(ngModel)]="selectedItemstypesFood"
              [settings]="dropdownSettingstypesFood"
              (change)="setTypeFoodList()"></angular2-multiselect>

            <angular2-multiselect
              class="col-5 pl-0"
              name="weightType"
              formControlName="weightType"
              [data]="weightTypeList"
              [(ngModel)]="selectedItemsWeightType"
              [settings]="dropdownSettingsWeightType"
              (change)="setWeightType()"></angular2-multiselect>
          </div>
          <div class="d-flex custom-color">
            <div class="form-group bckg-body w-100 rounded pt-2 pl-3">
              <span>Calorie:</span>
              <input
                type="number"
                formControlName="caloriesNewFood"
                id="caloriesNewFood"
                name="caloriesNewFood"
                class="form-control square-input"
                placeholder="Calorie" />
            </div>

            <div class="form-group bckg-body ml-2 w-100 rounded pt-2 pl-3">
              <span>Carboidrati:</span>
              <input
                type="number"
                formControlName="carbohydratesNewFood"
                id="carbohydratesNewFood"
                name="carbohydratesNewFood"
                class="form-control square-input"
                placeholder="Carboidrati" />
            </div>
          </div>
          <div class="d-flex custom-color">
            <div class="form-group bckg-body w-100 rounded pt-2 pl-3">
              <span>Proteine:</span>
              <input
                type="number"
                formControlName="proteinsNewFood"
                id="proteinsNewFood"
                name="proteinsNewFood"
                class="form-control square-input"
                placeholder="Proteine" />
            </div>
            <div class="form-group bckg-body ml-2 w-100 rounded pt-2 pl-3">
              <span>Grassi:</span>
              <input
                type="number"
                formControlName="fatsNewFood"
                id="fatsNewFood"
                name="fatsNewFood"
                class="form-control square-input"
                placeholder="Grassi" />
            </div>
          </div>
          <div class="d-flex custom-color">
            <div class="form-group bckg-body w-100 rounded">
              <label class="label-textarea">Note</label>
              <textarea
                class="custom-textarea bckg-body"
                formControlName="noteNewFood"
                id="noteNewFood"
                name="noteNewFood"
                rows=""
                cols=""
                placeholder="Scrivi qui..."
                style="border:0px;"></textarea>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                <g transform="translate(0 1)">
                  <rect class="a" width="15" height="15" transform="translate(0 0)" />
                  <g transform="translate(1.518 0)">
                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)" />
                    <path
                      class="b"
                      d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z"
                      transform="translate(-3 -1.5)" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-2 d-flex custom-color">
            <div class="form-group bckg-body w-100 mt-2 rounded pt-2 pl-3">
              <span>Link:</span>
              <input
                type="text"
                name="url"
                formControlName="url"
                id="url"
                class="form-control square-input bckg-body mr-2"
                placeholder="Link integratore"
                (ngModelChange)="checkUrl($event)"
                required />
              <small class="d-block mt-0" *ngIf="showConstraint">inserire url completa. ES: "https://www.link.it</small>
            </div>
          </div>
          <div class="d-flex justify-content-end w-100">
            <button class="btn btn-primary border-radius-small border-radius-small d-flex justify-content-center pl-5 pr-5" (click)="addFood()">
              <p *ngIf="!isUpdate" class="m-0">Aggiungi</p>
              <p *ngIf="isUpdate" class="m-0">Modifica</p>
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class NgbdModal2Content implements OnInit, OnDestroy {
  @Input() public typesFood;
  @Input() public foodId: number = null;
  isUpdate: boolean = false;

  dropdownTypesFoodList = [];
  selectedItemstypesFood = [];
  dropdownSettingstypesFood = {};
  showConstraint: boolean = true;

  weightTypeList = [];
  selectedItemsWeightType = [];
  dropdownSettingsWeightType = {};

  formNewFood: FormGroup;

  subscriptions: Subscription[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  foodSelectedForUpdate;

  constructor(
    public activeModal: NgbActiveModal,
    private messageFormatter: MessageFormatterService,
    private fb: FormBuilder,
    private pianoAlimentareService: PianoAlimentareService,
    private toastr: ToastrService,
    private utilites: UtilitiesService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.isUpdate = this.foodId != null;
    this.initialize();

    this.setupForm();

    if (this.foodId) {
      this.getDetailFood(this.foodId);
    }

    // const sub = this.pianoAlimentareService.$updateFood.subscribe(val => {
    //   console.log("MODAL2 UPDATE FOOD", val, this.isUpdate)
    //   if (val) {
    //     this.foodId = val.foodId;
    //     this.isUpdate = true;
    //     this.getDetailFood(this.foodUpdateId);
    // }
    // else {
    //   this.foodUpdateId = null;
    //   this.isUpdate = false;
    // }
    // });

    // this.subscriptions.push(sub);
  }

  initialize() {
    this.dropdownTypesFoodList = this.typesFood
      .filter((tf) => tf.id != 0)
      .map((tf) => {
        return { id: tf.id, itemName: tf.name };
      });

    this.selectedItemstypesFood = [];
    this.dropdownSettingstypesFood = {
      singleSelection: true,
      text: "Tipologia",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: false,
      classes: "custom-input-fill",
      lazyLoading: true,
      position: "bottom",
      autoPosition: false,
      maxHeight: 250,
    };
    this.selectedItemsWeightType = [];
    this.weightTypeList = [
      { id: 1, itemName: "g" },
      { id: 2, itemName: "ml" },
      { id: 3, itemName: "unità" },
      { id: 4, itemName: "cucchiaio" },
      { id: 5, itemName: "bustina" },
      { id: 6, itemName: "fiala" },
      { id: 7, itemName: "capsule/compresse" },
      { id: 8, itemName: "misurino" },
    ];
    this.dropdownSettingsWeightType = {
      singleSelection: true,
      text: "Misura",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: false,
      classes: "custom-input-fill",
      lazyLoading: true,
      position: "bottom",
      autoPosition: false,
    };
  }

  // open(modal, newFood: boolean = false) {
  //   console.log(" MODAL 2", modal, newFood, this.isUpdate)
  //   const modalRef =  this.modalService.open(modal, {size: 'lg', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  // if (modal === this.modal2) {
  //   console.log("SONO NELL IF MODAL 2")
  //   modalRef.componentInstance.typesFood = this.typesFood;
  // }
  // }

  setTypeFoodList() {
    this.formNewFood.get("typeFoodList").setValue(this.selectedItemstypesFood);
  }

  setWeightType() {
    this.formNewFood.get("weightType").setValue(this.selectedItemsWeightType);
  }

  getDetailFood(idFood) {
    this.pianoAlimentareService.getDetailsFood(idFood).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.foodSelectedForUpdate = res.data;

          this.formNewFood.patchValue({
            nameNewFood: this.foodSelectedForUpdate.name,
            caloriesNewFood: this.foodSelectedForUpdate.calories || 0,
            carbohydratesNewFood: this.foodSelectedForUpdate.carbohydrates || 0,
            proteinsNewFood: this.foodSelectedForUpdate.proteins || 0,
            fatsNewFood: this.foodSelectedForUpdate.fats || 0,
            weightType: this.foodSelectedForUpdate.weight_type,
            url: this.foodSelectedForUpdate.url,
            noteNewFood: this.foodSelectedForUpdate.note,
          });

          this.selectedItemstypesFood = this.dropdownTypesFoodList.filter((i) => i.id == this.foodSelectedForUpdate.type.id);
          this.selectedItemsWeightType = this.weightTypeList.filter((i) => i.itemName == this.foodSelectedForUpdate.weight_type);
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
  }

  checkUrl(url) {
    this.showConstraint = !this.utilites.checkUrl(url, true);
  }

  setupForm() {
    this.formNewFood = this.fb.group({
      nameNewFood: new FormControl("", [Validators.required]),
      typeFoodList: new FormControl("", [Validators.required]),
      caloriesNewFood: new FormControl("0", [Validators.required]),
      carbohydratesNewFood: new FormControl("0", [Validators.required]),
      proteinsNewFood: new FormControl("0", [Validators.required]),
      fatsNewFood: new FormControl("0", [Validators.required]),
      noteNewFood: new FormControl(""),
      url: new FormControl(""),
      weightType: new FormControl("", [Validators.required]),
    });
  }

  buildRequestNewFood(type_id, name, calories, carbohydrates, proteins, fats, weight_type, note, url) {
    return {
      type_id: type_id,
      name: name,
      calories: calories,
      carbohydrates: carbohydrates,
      proteins: proteins,
      fats: fats,
      weight_type: weight_type,
      note: note,
      url: url,
    };
  }

  addFood() {
    const nameNewFood = this.formNewFood.get("nameNewFood").value || null;
    let typeFoodList = this.formNewFood.get("typeFoodList").value;
    let idFood: number;
    typeFoodList.forEach((food) => {
      if (food && food.id) idFood = food.id;
    });
    let weightType = this.formNewFood.get("weightType").value;
    let idWeight = "";
    weightType.forEach((weight) => {
      if (weight && weight.itemName) idWeight = weight.itemName;
    });
    const caloriesNewFood = this.formNewFood.get("caloriesNewFood").value || 0;
    const carbohydratesNewFood = this.formNewFood.get("carbohydratesNewFood").value || 0;
    const proteinsNewFood = this.formNewFood.get("proteinsNewFood").value || 0;
    const fatsNewFood = this.formNewFood.get("fatsNewFood").value || 0;
    const url = this.formNewFood.get("url").value || "";
    const noteNewFood = this.formNewFood.get("noteNewFood").value || null;

    const request = this.buildRequestNewFood(
      idFood,
      nameNewFood,
      caloriesNewFood,
      carbohydratesNewFood,
      proteinsNewFood,
      fatsNewFood,
      idWeight,
      noteNewFood,
      url
    );

    this.isUpdate ? this.edit(this.foodId, request) : this.add(request);
  }

  add(request: any) {
    const sub = this.pianoAlimentareService.newFood(request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.activeModal.close(res.data);
          this.pianoAlimentareService.notifyAboutChange(res.data);
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          });
        }
      },
      error: (err) => {
        let message = err.error.message + ": </br>";
        if (err.error.data) {
          for (const error in err.error.data.errors) {
            if (typeof err.error.data.errors[error] !== "undefined") {
              message += err.error.data.errors[error][0] + " </br>";
            }
          }
        }
        this.toastr.error(message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
          enableHtml: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }

  edit(foodId: number, request: any) {
    const sub = this.pianoAlimentareService.editFood(foodId, request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.activeModal.close(res.data);
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          });
        }
      },
      error: (err) => {
        let message = err.error.message + ": </br>";
        if (err.error.data) {
          for (const error in err.error.data.errors) {
            if (typeof err.error.data.errors[error] !== "undefined") {
              message += err.error.data.errors[error][0] + " ";
            }
          }
        }
        this.toastr.error(message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
          enableHtml: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }
}

@Component({
  template: `
    <div class="modal-header justify-content-between">
      <h6 class="modal-title">{{ title }}</h6>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="now-ui-icons ui-1_simple-remove"></i>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="formAssociatesFood">
        <div class="col-sm-8 p-0">
          <div class="form-group bckg-body">
            <input type="hidden" formControlName="idFood" id="idFood" name="idFood" class="form-control square-input" />
            <div class="d-flex">
              <input
                type="number"
                formControlName="quantityFood"
                id="quantityFood"
                name="quantityFood"
                class="form-control square-input"
                placeholder="Quantità" />
              <span class="text-uppercase p-2">{{ weight_type }}</span>
            </div>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-sm-8 pr-0">
            <div class="form-group bckg-body">
              <label class="label-textarea">Note</label>
              <textarea
                class="custom-textarea bckg-body"
                formControlName="noteFoodAssociates"
                id="noteFoodAssociates"
                name="noteFoodAssociates"
                placeholder="Scrivi qui..."
                style="border:0px;"></textarea>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                <g transform="translate(0 1)">
                  <rect class="a" width="15" height="15" transform="translate(0 0)" />
                  <g transform="translate(1.518 0)">
                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)" />
                    <path
                      class="b"
                      d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z"
                      transform="translate(-3 -1.5)" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="col-sm-4">
            <button class="btn btn-primary border-radius-small w-100" (click)="associatesFoodtoSinglePlan()">
              <span class="btn__text m-0">Aggiungi</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  `,
})
export class NgbdModal3Content implements OnInit, OnDestroy {
  title: string;
  idFood: number;
  dataFood = [];
  formAssociatesFood: FormGroup;
  subscriptions: Subscription[] = [];
  subplanGroupId: Subscription;
  subSinglePlanActivated: Subscription;
  subMealActivated: Subscription;
  planGroupId: number;
  foodPlanId: number;
  mealId: number;
  associatedFoodBool: boolean = false;
  @Output() eventUpdateListFood = new EventEmitter<boolean>();
  weight_type: string;
  noteFood: string;

  constructor(
    public activeModal: NgbActiveModal,
    private pianoAlimentareService: PianoAlimentareService,
    private fb: FormBuilder,
    private messageFormatter: MessageFormatterService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.subplanGroupId = pianoAlimentareService.planGroupId$.subscribe((val) => (this.planGroupId = val));
    this.subSinglePlanActivated = pianoAlimentareService.singlePlanActivated$.subscribe((val) => (this.foodPlanId = val));
    this.subMealActivated = pianoAlimentareService.mealActivated$.subscribe((val) => (this.mealId = val));
  }

  ngOnInit(): void {
    this.pianoAlimentareService.item$.subscribe((dataFood) => (this.dataFood = dataFood));
    this.title = this.dataFood[0];
    this.idFood = this.dataFood[1];
    this.weight_type = this.dataFood[2];
    this.noteFood = this.dataFood[3];
    this.setupForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setupForm() {
    this.formAssociatesFood = this.fb.group({
      idFood: new FormControl(this.idFood, [Validators.required]),
      quantityFood: new FormControl("", [Validators.required]),
      noteFoodAssociates: new FormControl(this.noteFood),
    });
  }

  buildRequestAssociatesFood(food_id, quantity, note) {
    return {
      food_id: food_id,
      quantity: quantity,
      note: note,
    };
  }

  associatesFoodtoSinglePlan() {
    const idFood = this.formAssociatesFood.get("idFood").value || null;
    const quantityFood = this.formAssociatesFood.get("quantityFood").value || 0;
    const noteFoodAssociates = this.formAssociatesFood.get("noteFoodAssociates").value || null;

    const associatesFoodRequest = this.buildRequestAssociatesFood(idFood, quantityFood, noteFoodAssociates);

    const sub = this.pianoAlimentareService.associatesFoodToMeal(this.planGroupId, this.foodPlanId, this.mealId, associatesFoodRequest).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.modalService.dismissAll();
          this.pianoAlimentareService.notifyAboutChange(res.data);
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }
}

@Component({
  selector: "app-associates-food",
  templateUrl: "./associates-food.component.html",
  styleUrls: ["./associates-food.component.css"],
})
export class AssociatesFoodComponent implements OnInit, OnDestroy {
  //current user actually logged
  currentUser: any;

  modalsNumber = 0;
  @Input() insidePlan: boolean = true;
  modal1 = NgbdModal1Content;
  modal2 = NgbdModal2Content;
  typesFood = [];

  subscriptions: any[] = [];
  idfoodSelectedForUpdate;

  notifierSubscription: Subscription = this.pianoAlimentareService.subjectUpdateNotifier.subscribe((notified) => {
    this.open(this.modal2, null);
  });

  constructor(private modalService: NgbModal, private pianoAlimentareService: PianoAlimentareService) {
    const sub = this.modalService.activeInstances.subscribe((list) => {
      this.modalsNumber = list.length;
    });
    this.subscriptions.push(sub);
  }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    //QUEST: why load food types here?
    if (this.currentUser.is_admin) {
      this.loadTypesFood();
    }
  }

  subscribeUpdateFood() {
    const sub = this.pianoAlimentareService.$updateFood.subscribe((val) => {
      if (val) {
        this.idfoodSelectedForUpdate = val;
        this.open(this.modal2, val.foodId);

        this.pianoAlimentareService.clearUpdateFoodQueue();
        this.subscribeUpdateFood();
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  loadTypesFood() {
    this.pianoAlimentareService.getTypesFood().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.typesFood = res.data;
          this.subscribeUpdateFood();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  open(modal, foodId: number = null) {
    const modalRef = this.modalService.open(modal, { size: "lg", backdrop: "static", windowClass: "mediumModal", centered: true });
    if (modal === this.modal2) {
      modalRef.componentInstance.typesFood = this.typesFood;
      modalRef.componentInstance.foodId = foodId;
    }
  }
}
