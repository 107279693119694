import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private title$ = new BehaviorSubject<string>('Utenti');
  private btnConf$ = new BehaviorSubject<any>({
    disabled: true,
    path: null
  });
  public currentTitle$ = this.title$.asObservable();
  public backBtnConf$ = this.btnConf$.asObservable();
  
  constructor() { }

  /**
   * Set the title of the navbar component
   * @param title The title to set in the navbar
   */
  setHeaderTitle(title: string): void {
    this.title$.next(title);
  }

  /**
 * Set the configuration of the navbar back button
 * To unset the conf use {disabled: true, path: null}
 * @param backBtnConf the configuration:
 *                    {disabled: boolean, path: string}
 */
  setHeaderBackBtnConf(backBtnConf: any): void {
    this.btnConf$.next(backBtnConf);
  }
}
