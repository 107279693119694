import { HeaderService } from './../../../services/header.service';
import { Component, OnInit } from '@angular/core';
import {ServerDataSource} from "ng2-smart-table";
import {PianoAllenamentoService} from "../piano-allenamento.service";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../../../components/confirm-dialog/confirm-dialog.service";
import {ActionTableComponent} from "../../../components/action-table/action-table.component";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { ValueFilterComponent } from '../../../components/value-filter/value-filter.component';

@Component({
  selector: 'app-lista-esercizi',
  templateUrl: './lista-esercizi.component.html',
  styleUrls: ['./lista-esercizi.component.css']
})
export class ListaEserciziComponent implements OnInit {

	//current user actually logged
	currentUser:any;

  source:  ServerDataSource;
	isLoadingTableData: boolean = true;
  settings: any;
  notifierSubscription: Subscription = this.pianoAllenamentoService.subjectNotifier.subscribe(notified => {
    this.loadExercisesList(); // refresh my data after creation excercise
    this.modalService.dismissAll();
  });
  idExercise;

  constructor(
      private pianoAllenamentoService: PianoAllenamentoService,
      private toastr: ToastrService,
      private confirmDialogService: ConfirmDialogService,
      private modalService: NgbModal,
      private headerService: HeaderService
  ) { }

  ngOnInit(): void {
		this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    this.loadExercisesList();
    this.loadTableSettings();
    this.pianoAllenamentoService.itemExercise$.subscribe(idExerciseForUpdate => this.idExercise = idExerciseForUpdate);
		this.headerService.setHeaderTitle('Lista esercizi');
  }

  loadExercisesList(){
    this.source = this.pianoAllenamentoService.getExercise();
		this.source.onChanged().subscribe(() => {
			this.isLoadingTableData=false;
		});
  }

  loadTableSettings() {
    this.settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      columns: {
        name: {
          title: 'Nome esercizio',
          type: "html",
					filter: {
						type: 'custom',
						component: ValueFilterComponent
					},
          sort: false,
          valuePrepareFunction: (cell) => {
            if (cell) {
              return '<span class="black-bold"><img src="../../../assets/img/coffie.png" alt="">' + cell + '</span>';
            }
          },
        },
        dettaglio: {
          title: this.currentUser.is_admin ? "Azioni" : '',
          type: "custom",
          filter: false,
          sort: false,
          position: "right",
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            instance.actionEmit.subscribe(value => {
              if (value === "elimina") {
                this.deleteExercise(instance.rowData.id, instance.rowData.name);
              } else if (value === "modifica") {
                this.pianoAllenamentoService.clickBtnUpdateExercise(instance.rowData.id);
                this.pianoAllenamentoService.notifyExerciseChange();
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const actions = [
              {
                text: "modifica",
                link: "",
                emit: "modifica",
                title: "Modifica piano",
                btnId: "modifica_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-edit ",
                classList: "action-btn btn",
              },
              {
                text: "elimina",
                link: "",
                emit: "elimina",
                title: "Elimina alimento",
                btnId: "elimina_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-times ",
                classList: "action-btn btn",
              },
            ];
            if(this.currentUser.is_admin) {
              return actions;
            } else {
              return null;
            }
          },
        },
      }
    };
  }

  deleteExercise(idExercise, nameExercise) {
    this.confirmDialogService.confirm(
        'Stai per eliminare l\'esercizio: '+ nameExercise,
        'Sei sicuro di voler continuare?'
    )
        .then((res) => {
          if(res){
            this.pianoAllenamentoService.deleteExerciseSelected(idExercise).subscribe({
              next: (resp: any) => {
                if (resp.success) {
                  this.toastr.success(resp.message, 'Operazione eseguita con successo!',{
                    positionClass: 'toast-bottom-left',
                    tapToDismiss: true,
                  });
                  this.loadExercisesList();
                } else {
                  this.confirmDialogService.confirm('ATTENZIONE', resp.message);
                }
              }
            });
          }
        })
        .catch(() => console.log('Error'));
  }

  deselectFirstRow() {
    setTimeout( function() {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach(t => {
        for (let i = 0, row; row = t.rows[i]; i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }  
  
	toIterator(n: number): number[] {
		return Array(n).fill(0);
	}

}
