import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {PianoAllenamentoService} from "../../pages/piano-allenamento/piano-allenamento.service";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../confirm-dialog/confirm-dialog.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-store-exercise',
  templateUrl: './store-exercise.component.html',
  styleUrls: ['./store-exercise.component.css']
})
export class StoreExerciseComponent implements OnInit, OnDestroy {

  @ViewChild('createExerciseModal') createExerciseModal: ElementRef;
  createExerciseForm: FormGroup;
  subscriptions: Subscription[] = [];
  fileName: string = "";
  file: null;
  gruppiMuscolari = [];
  idExerciseForUpdate;
  stateUpdateModal: boolean = false;
  exerciseSelectedForUpdate;
  assetURL = environment.baseUrl + "admin/file/";

  constructor(
      private modalService: NgbModal,
      private fb: FormBuilder,
      private pianoAllenamentoService: PianoAllenamentoService,
      private toastr: ToastrService,
      private messageFormatter: MessageFormatterService,
      private confirmDialogService: ConfirmDialogService,
  ) {

  }

  ngOnInit(): void {
     this.setupForm();
     this.loadTypesExercise();
     this.subscribeExerciseNotifier();
    // this.pianoAllenamentoService.itemExercise$.subscribe(idExerciseForUpdate => this.idExerciseForUpdate = idExerciseForUpdate);
  }

  subscribeExerciseNotifier() {
    this.subscriptions.push(this.pianoAllenamentoService.exerciseNotifier.subscribe(notified => {
      this.subscriptions.push(this.pianoAllenamentoService.itemExercise$.subscribe(idExerciseForUpdate => this.idExerciseForUpdate = idExerciseForUpdate));
      if(this.idExerciseForUpdate !== null){
        this.stateUpdateModal = true;
        this.modalService.open(this.createExerciseModal, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
        this.getDetailExercise(this.idExerciseForUpdate);
      }
    }));
  }

  openModal(){
    this.loadTypesExercise();
    const sub = this.pianoAllenamentoService.itemExercise$.subscribe(idExerciseForUpdate => this.idExerciseForUpdate = idExerciseForUpdate);
    this.subscriptions.push(sub);
    this.idExerciseForUpdate = null;
    this.stateUpdateModal = false;
    this.setupForm();
    this.fileName= null;
    this.modalService.open(this.createExerciseModal, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onFileChanged(e: any) {
    let reader = new FileReader();
    let file = e.target.files[0];
    this.fileName = e.target.files[0].name;
    let extensionAllowed: any = { "mp4": true};
    if (extensionAllowed) {
      var nam = file.name.split('.').pop();
      if (!extensionAllowed[nam]) {
        alert("Puoi caricare uno di questi formati: " + Object.keys(extensionAllowed))
        return;
      }
    }
    reader.onloadend = () => {
      this.file = file;
    };
    reader.readAsDataURL(file);
  }

  loadTypesExercise() {
    this.pianoAllenamentoService.getTypesExercise().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.gruppiMuscolari = res.data;
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }
    });
  }

  isValidFile(field: any) {
    if (field == null || field.hasOwnProperty('file_name')) return false
    return true
  }

  buildRequest(nomeEsercizio, exercise_video, gruppoMuscolare, note): FormData {
    let formData = new FormData();
    formData.append("name", nomeEsercizio);
    if(this.isValidFile(exercise_video)) formData.append("video", exercise_video);
    formData.append("exercise_type_id", gruppoMuscolare);
    formData.append("note", note);
    return formData;
  }

  createNewExercise() {
    const nomeEsercizio = this.createExerciseForm.get("name").value;
    const exercise_video = this.file || null;
    const gruppoMuscolare = this.createExerciseForm.get("gruppoMuscolare").value || null;
    const note = this.createExerciseForm.get("note").value;

    let request = this.buildRequest(
        nomeEsercizio,
        exercise_video,
        gruppoMuscolare,
        note,
    );

    if(this.idExerciseForUpdate){
      //update esercizio
      const sub = this.pianoAllenamentoService.updateExerciseSelected(request, this.idExerciseForUpdate).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.modalService.dismissAll();
            this.toastr.success(res.message, 'Operazione eseguita con successo!',{
              positionClass: 'toast-bottom-left',
              tapToDismiss: true,
            });
            this.pianoAllenamentoService.notifyAboutChange();
            this.createExerciseForm.reset();
          } else {
            this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
          }
        }});
      this.subscriptions.push(sub);
    }else{
      //creazione esercizio
      const sub = this.pianoAllenamentoService.newExercise(request).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.pianoAllenamentoService.notifyAboutChange();// caricare lista esercizi
            this.createExerciseForm.reset();
            this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(),{
              positionClass: 'toast-bottom-left',
              tapToDismiss: true,
            });
          } else{
            this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
          }
        }});
      this.subscriptions.push(sub);
    }
  }

  setupForm() {
    this.createExerciseForm = this.fb.group({
      name: new FormControl("", [Validators.required]),
      exercise_video: new FormControl(""),
      gruppoMuscolare: new FormControl("0", [Validators.required]),
      note: new FormControl(""),
    });
  }

  getDetailExercise(idExercise){
    this.subscriptions.push(this.pianoAllenamentoService.getDetailsExercise(idExercise).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.exerciseSelectedForUpdate = res.data;
          this.createExerciseForm.patchValue({
            name: this.exerciseSelectedForUpdate.name,
            gruppoMuscolare: this.exerciseSelectedForUpdate.type?.id,
            note: this.exerciseSelectedForUpdate.note,
          });
          this.fileName = this.exerciseSelectedForUpdate.video;
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }}));
  }

}
