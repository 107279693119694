import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, ElementRef, OnInit, ViewChild,Output, EventEmitter, Input} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PianoAlimentareService } from "../../pages/piano-alimentare/piano-alimentare.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import {ActivatedRoute, NavigationEnd} from '@angular/router';

interface mealPlanSingleFood {
  id: number;
  name: string;
  nutritional_values: {
    calories: string;
    carbohydrates: string;
    proteins: string;
    fats: string;
  };
}

@Component({
  selector: 'app-add-meal',
  templateUrl: './add-meal.component.html',
  styleUrls: ['./add-meal.component.css']
})
export class AddMealComponent implements OnInit {

  @ViewChild('theModalMeal') theModalMeal: ElementRef;

  formNewMeal: FormGroup;

  subscriptions: Subscription[] = [];

  updateMeal: boolean;
  @Output() updateEventMeal = new EventEmitter<any>();

  @Input() planActive : number;

  planGroupId: number;

  newMealPlanSingleFood: mealPlanSingleFood[];

  constructor(
      private modalService: NgbModal,
      private fb: FormBuilder,
      private messageFormatter: MessageFormatterService,
      private pianoAlimentareService: PianoAlimentareService,
      private toastr: ToastrService,
      private route: ActivatedRoute,
      ) { }

  ngOnInit(): void {

    this.setupForm();

    this.route.params.subscribe(params => {
      this.planGroupId = params["id"];
    });

  }

  open(){
    this.modalService.open(this.theModalMeal, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  }

  setupForm() {
    this.formNewMeal = this.fb.group({
      nameNewMeal: new FormControl("", [Validators.required]),
      noteNewMeal: new FormControl(""),
    });
  }

  buildRequest(name, note) {
    return {
      name: name,
      note: note
    };
  }

  createMeal() {
    const nameNewMeal = this.formNewMeal.get("nameNewMeal").value || null;
    const noteNewMeal = this.formNewMeal.get("noteNewMeal").value || null;

    const mealRequest = this.buildRequest(
        nameNewMeal,
        noteNewMeal
    );

    const sub = this.pianoAlimentareService.newMealPlanSingleFood(this.planGroupId, this.planActive, mealRequest).subscribe({
      next: (res: any) => {
        this.newMealPlanSingleFood = res.data;
        this.updateEventMeal.emit(this.newMealPlanSingleFood);
        this.modalService.dismissAll();
        this.formNewMeal.reset();
        if (res.success) {
          this.toastr.success(res.message, 'Operazione eseguita con successo!',{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
        }
      },
      error: err => {
        console.log(err);
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }
    });
    this.subscriptions.push(sub);

  }


}
