import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {Subscription} from "rxjs";
import {PianoAlimentareService} from "../../pages/piano-alimentare/piano-alimentare.service";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-store-piano-alimentare',
  templateUrl: './store-piano-alimentare.component.html',
  styleUrls: ['./store-piano-alimentare.component.css'],
})
export class StorePianoAlimentareComponent implements OnInit {

  @ViewChild('theModalPlanTemplate') theModalPlanTemplate: ElementRef;

  formNewPlan: FormGroup;

  subscriptions: Subscription[] = [];

  @Input() title : number;

  newGroupPlan: boolean;
  newSinglePlan: boolean;
  subplanGroupId: Subscription;

  planGroupId: number;

  updatePlan: boolean;
  @Output() updateEvent = new EventEmitter<boolean>();


  constructor(
      private modalService: NgbModal,
      private fb: FormBuilder,
      private messageFormatter: MessageFormatterService,
      private pianoAlimentareService: PianoAlimentareService,
      private router: Router,
      private route: ActivatedRoute,
      private toastr: ToastrService
      ) {
  }

  ngOnInit(): void {

    switch(this.title) {
      case 1: {
        this.newGroupPlan = true;
        this.newSinglePlan = false;
        break;
      }
      case 2: {
        this.newGroupPlan = false;
        this.newSinglePlan = true;

        this.route.params.subscribe(params => {
          this.planGroupId = params["id"];
        });
        // this.subplanGroupId = this.pianoAlimentareService.planGroupId$.subscribe(val => this.planGroupId = val);


        break;
      }
      default: {
        break;
      }
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });

    this.setupForm();

  }

  open(){
    this.modalService.open(this.theModalPlanTemplate, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  }

  setupForm() {
    this.formNewPlan = this.fb.group({
      titleNewPlan: new FormControl("", [Validators.required]),
      noteNewPlan: new FormControl(""),
    });
  }

  buildRequestGroup(name, note) {
    return {
      name: name,
      note: note
    };
  }

  buildRequestSingle(name, note) {
    return {
      name: name,
      note: note
    };
  }

  createPlan(){

    const titleNewPlan = this.formNewPlan.get("titleNewPlan").value || null;
    const noteNewPlan = this.formNewPlan.get("noteNewPlan").value || null;

    if (this.title === 1) {

      // create new group food plan
      const planRequest = this.buildRequestGroup(
          titleNewPlan,
          noteNewPlan
      );

      const sub = this.pianoAlimentareService.newPlanGroupFood(planRequest).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.router.navigate(['/piano-alimentare/dettaglio-gruppo-piano-alimentare/' + res.data.id]);
            this.toastr.success(res.message, 'Operazione eseguita con successo!',{
              positionClass: 'toast-bottom-left',
              tapToDismiss: true,
            });
          }
        },
        error: err => {
          let message = err.error.message + ": </br>";
          if (err.error.data) {
            for (const error in err.error.data.errors) {
              if (typeof (err.error.data.errors[error]) !== "undefined") {
                message += err.error.data.errors[error][0] + " ";
              }
            }
          }
          this.toastr.error(message, this.messageFormatter.getErrorTitle(),{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
            enableHtml: true
          });
        }
      });
      this.subscriptions.push(sub);

    } else if (this.title === 2){

      // create new single food plan
      const planRequest = this.buildRequestSingle(
          titleNewPlan,
          noteNewPlan
      );

      const sub = this.pianoAlimentareService.newPlanSingleFood(planRequest, this.planGroupId).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.toastr.success(res.message, 'Operazione eseguita con successo!',{
              positionClass: 'toast-bottom-left',
              tapToDismiss: true,
            });
            this.updateEvent.emit(this.updatePlan = true);
            this.modalService.dismissAll();
          }
        },
        error: err => {
					let message = err.error.message + ": </br>";
					if (err.error.data) {
						for (const error in err.error.data.errors) {
							if (typeof (err.error.data.errors[error]) !== "undefined") {
								message += err.error.data.errors[error][0] + " ";
							}
						}
					}
          this.toastr.error(message, this.messageFormatter.getErrorTitle(),{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
            enableHtml: true
          });
        }
      });
      this.subscriptions.push(sub);

    }
  }
}
