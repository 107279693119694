<div class="main-section">
    <div class="row justify-content-end">
        <div class="col-md-4 col-lg-3">
            <app-store-exercise *ngIf="currentUser.is_admin"></app-store-exercise>
        </div>
    </div>
    <div class="row">
        <div class="container-table-custom col-sm-12 mt-3">
            <ng2-smart-table [ngClass]="{'d-none': isLoadingTableData}" [settings]="settings" [source]="source" (rowSelect)="deselectFirstRow()"></ng2-smart-table>

            <ng-container *ngIf="isLoadingTableData">
                <div *ngFor="let i of toIterator(10)" class="skeleton-text mb-2"></div>
            </ng-container>    
        </div>
    </div>
</div>
