<ng-template #theModalImage let-d="dismiss">
    <div class="modal-header justify-content-end">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body d-flex align-items-center justify-content-center">
        <img *ngIf="useFilePipe" [src]="path | filePipe | async" class="modal-img" />
        <img *ngIf="!useFilePipe" [src]="path" class="modal-img" />
    </div>
</ng-template>