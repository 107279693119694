<div class="w-100">
    <div class="dp-hidden position-absolute">
        <div class="input-group">
            <input  
                    name="datepicker"
                    class="invisible"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1">
            <ng-template #t let-date let-focused="focused">
                    <span class="custom-day"
                          [class.focused]="focused"
                          [class.range]="isRange(date)"
                          [class.faded]="isHovered(date) || isInside(date)"
                          (mouseenter)="hoveredDate = date"
                          (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                    </span>
            </ng-template>
        </div>
    </div>
    <div class="form-group dark-border">
        <input
            readonly
            #dpFromDate
            class="form-control square-input" placeholder="inizio"
            name="dpFromDate"
            [value]="startDate"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            (click)="datepicker.toggle()"
            />
            <!-- [value]="formatter.format(fromDate)" -->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 dark form-group__svg" (click)="datepicker.toggle()">
            <rect class="a" width="20" height="20"/>
            <g transform="translate(2.5 1.667)">
                <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#3C3C3C;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                <line class="b" y2="3.333" transform="translate(10.833)"/>
                <line class="b" y2="3.333" transform="translate(4.167)"/>
                <line class="b" x2="15" transform="translate(0 6.667)"/>
            </g>
        </svg>
    </div>
</div>
<div class="form-group dark-border">
    <input
        readonly
        #dpToDate
        class="form-control square-input" placeholder="fine"
        name="dpToDate"
        [value]="endDate"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        (click)="datepicker.toggle()"
        />
        <!-- [value]="formatter.format(toDate)" -->
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 dark form-group__svg" (click)="datepicker.toggle()">
        <rect class="a" width="20" height="20"/>
        <g transform="translate(2.5 1.667)">
            <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#3C3C3C;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
            <line class="b" y2="3.333" transform="translate(10.833)"/>
            <line class="b" y2="3.333" transform="translate(4.167)"/>
            <line class="b" x2="15" transform="translate(0 6.667)"/>
        </g>
    </svg>
</div>
