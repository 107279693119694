import { UtentiService } from './../../pages/utenti/utenti.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-info',
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.css']
})
export class WidgetInfoComponent implements OnInit {

	//current user actually logged
	currentUser:any;

	subscriptions: Subscription[] = [];
	widgetInfo: any;

	constructor(
        private utentiService: UtentiService,
	) { }

	ngOnInit(): void {
		this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
		this.loadWidgetInfo();
	}

	loadWidgetInfo(){
		const sub = this.utentiService.widgetInfo().subscribe({
			next: (res: any) => {
				if (res.success === true) {
					this.widgetInfo = res.data;
					
				} else {
					console.log("Error: " + res)
				}
			},
			error: err => {
			  console.log("Error: " + err)
		  }});
		  this.subscriptions.push(sub);
	}

}
