import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { PianoAlimentareService } from "../../pages/piano-alimentare/piano-alimentare.service";
import { ToastrService } from "ngx-toastr";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-clone-plan-food',
  templateUrl: './clone-plan-food.component.html',
  styleUrls: ['./clone-plan-food.component.css']
})
export class ClonePlanFoodComponent implements OnInit {

  subplanGroupId: Subscription;
  planGroupId: number;
  subscriptions: Subscription[] = [];
  @Output() refreshAfterClone = new EventEmitter<any>();

  constructor(
      private pianoAlimentareService: PianoAlimentareService,
      private messageFormatter: MessageFormatterService,
      private toastr: ToastrService
  ) {
    this.subplanGroupId = pianoAlimentareService.planGroupId$.subscribe(val => this.planGroupId = val);
  }

  ngOnInit(): void {
  }

  buildRequestClonePlan(food_plan_id) {
    return {
        food_plan_id
    };
  }

  clonePreviousPlan() {
    const request = this.buildRequestClonePlan('');

    const sub = this.pianoAlimentareService.clonePreviousPlan(this.planGroupId, request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.refreshAfterClone.emit({success: true, newId: res.data.id});
          this.toastr.success(res.message, 'Operazione eseguita con successo!',{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }
    });
    this.subscriptions.push(sub);
  }

}
