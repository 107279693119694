import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DefaultFilter } from 'ng2-smart-table';

@Component({
  selector: 'app-value-filter',
  templateUrl: './value-filter.component.html',
  styleUrls: ['./value-filter.component.css']
})
export class ValueFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    filterVal: string;

    constructor() {
        super();
    }

    ngOnInit() { }

    startSearch() {
      this.query = this.filterVal;
      this.setFilter();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.filterVal = this.query;
        }
    }
}