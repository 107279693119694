<nav
  class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary "
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#">{{ getTitle() }}</a>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
  </div>
</nav>
<div class="wrapper wrapper-full-page ">
  <div class="full-page section-image" filter-color="black">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
