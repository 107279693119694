import { MessageFormatterService } from './../../services/messageFormatter.service';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {Subscription} from "rxjs";
import { ToastrService } from "ngx-toastr";
import {PianoAllenamentoService} from "../../pages/piano-allenamento/piano-allenamento.service";

@Component({
  selector: 'app-store-session',
  templateUrl: './store-session.component.html',
  styleUrls: ['./store-session.component.css']
})
export class StoreSessionComponent implements OnInit {

  @ViewChild('theModalSession') theModalSession: ElementRef;
  workoutPlanNewSession: FormGroup;
  subscriptions: Subscription[] = [];
  idPlanWorkout: number;
  subIdPlanWorkout: Subscription;
  updatePlan: boolean;
  @Output() updateEvent = new EventEmitter<boolean>();

  constructor(
      private modalService: NgbModal,
      private fb: FormBuilder,
      private messageFormatter: MessageFormatterService,
      private toastr: ToastrService,
      private planWorkoutSv: PianoAllenamentoService,
  ) {
    this.subIdPlanWorkout = planWorkoutSv.planWorkoutId$.subscribe(val => this.idPlanWorkout = val);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  openModalSession() {
    this.setupForm();
    this.modalService.open(this.theModalSession, { size: 'md', backdrop: 'static', windowClass: 'mediumModal', centered: true});
  }

  setupForm() {
    this.workoutPlanNewSession = this.fb.group({
      nameNewSession: new FormControl("", [Validators.required]),
      noteNewSession:  new FormControl(""),
    });
  }

  request(name,note){
    return {
      name: name,
      note: note,
    }
  }

  createNewSession() {
    const nameNewSession = this.workoutPlanNewSession.get("nameNewSession").value || null;
    const noteNewSession = this.workoutPlanNewSession.get("noteNewSession").value || null;
    const request = this.request(nameNewSession,noteNewSession);
    const sub = this.planWorkoutSv.newSessionToPlanWorkout(this.idPlanWorkout, request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.updateEvent.emit(this.updatePlan = true);
          this.modalService.dismissAll();
          this.toastr.success(res.message, 'Operazione eseguita con successo!',{
            positionClass: 'toast-bottom-left',
            tapToDismiss: true,
          });
        }
      },
      error: err => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(),{
          positionClass: 'toast-bottom-left',
          tapToDismiss: true,
        });
      }
    });
    this.subscriptions.push(sub);
  }

}
