import { MessageFormatterService } from './../../../services/messageFormatter.service';
import { ValueFilterComponent } from "../../../components/value-filter/value-filter.component";
import { HeaderService } from './../../../services/header.service';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ServerDataSource } from "ng2-smart-table";
import { Subscription } from "rxjs";
import { PianoAlimentareService } from "../piano-alimentare.service";
import { ActionTableComponent } from "../../../components/action-table/action-table.component";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogService} from "../../../components/confirm-dialog/confirm-dialog.service";

@Component({
  selector: 'app-alimenti',
  templateUrl: './alimenti.component.html',
  styleUrls: ['./alimenti.component.css']
})
export class AlimentiComponent implements OnInit {

	//current user actually logged
	currentUser:any;

  source:  ServerDataSource;
	isLoadingTableData: boolean = true;
  settings: any;
  subscriptions: Subscription[] = [];
  foodUpdateId = [];

  constructor(
      private pianoAlimentareService: PianoAlimentareService,
      private toastr: ToastrService,
      private messageFormatter: MessageFormatterService,
      private confirmDialogService: ConfirmDialogService,
      private headerService: HeaderService
  ) { }

  ngOnInit(): void {
		this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
		this.headerService.setHeaderTitle('Lista alimenti');
    this.loadListFood();
    this.loadTableSettings();
    //this.pianoAlimentareService.itemFood$.subscribe(updateFood => this.foodUpdateId = updateFood);
  }

  loadListFood() {
    this.source = this.pianoAlimentareService.getIndexFood();
		this.source.onChanged().subscribe(() => {
			this.isLoadingTableData=false;
		});
  }

  loadTableSettings() {
    this.settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      columns: {
        name: {
          title: 'Nome alimento',
          type: "string",
					filter: {
						type: 'custom',
						component: ValueFilterComponent
					},
          sort: false,
        },
        dettaglio: {
          title: this.currentUser.is_admin ? "Azioni" : '',
          type: "custom",
          filter: false,
          sort: false,
          position: "right",
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            instance.actionEmit.subscribe(value => {
               if (value === "elimina") {
                 this.deleteFood(instance.rowData.id, instance.rowData.name);
               } else if (value === "modifica") {
                 this.pianoAlimentareService.clickBtnUpdateFood(instance.rowData.id, true);
                 // this.pianoAlimentareService.notifyUpdateChange();
               }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const actions = [
              {
                text: "modifica",
                link: "",
                emit: "modifica",
                title: "Modifica piano",
                btnId: "modifica_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-edit ",
                classList: "action-btn btn",
              },
              {
                text: "elimina",
                link: "",
                emit: "elimina",
                title: "Elimina alimento",
                btnId: "elimina_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-times ",
                classList: "action-btn btn",
              },
            ];
            if(this.currentUser.is_admin) {
              return actions;
            } else {
              return null;
            }
          },
        },
      }
    };
  }

  deleteFood(idFood, nameFood) {
    this.confirmDialogService.confirm(
        'Stai per eliminare l\'alimento: '+ nameFood,
        'Sei sicuro di voler continuare?'
    )
        .then((res) => {
          if(res){
            this.pianoAlimentareService.deleteFood(idFood).subscribe({
              next: (resp: any) => {
                if (resp.success) {
                  this.toastr.success(resp.message, this.messageFormatter.getSuccessTitle(),{
                    positionClass: 'toast-bottom-left',
                    tapToDismiss: true,
                  });
                  this.loadListFood();
                } else {
                  this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), resp.message)
                }
              }
            });
          }
        })
        .catch(() => console.log('Error'));
  }
  
	toIterator(n: number): number[] {
		return Array(n).fill(0);
	}

  deselectFirstRow() {
    setTimeout( function() {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach(t => {
        for (let i = 0, row; row = t.rows[i]; i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }

}
