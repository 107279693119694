import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Pipe({
  name: 'filePipe'
})
export class FilePipePipe implements PipeTransform {
  mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';

  constructor(
      private http: HttpClient,
  ) {}

  async transform(src: string): Promise<string> {
    const currentUser: any = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    const token = currentUser.token;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`, 'Accept':'application/json'});
    const fileBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(fileBlob);
      reader.onload = function(e){
        let buffer = e.target.result as any;
        let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
        return window.URL.createObjectURL(videoBlob);
      }
    });

  }



}
