import { ConfirmDialogService } from './../components/confirm-dialog/confirm-dialog.service';
import { MessageFormatterService } from './../services/messageFormatter.service';
import { Injectable } from "@angular/core";
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from "@angular/common/http";
import { Observable, throwError} from "rxjs";
import {catchError, finalize, map} from "rxjs/operators";
import { share } from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {LoadingService} from "../loading.service";

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(
        private messageFormatter: MessageFormatterService,
        private loader: LoadingService,
        private confirmDialogService: ConfirmDialogService,
        private toastr: ToastrService) 
    { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with jwt token if available
        const currentUser: any = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
        if (currentUser) {
            request = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + currentUser.token),
            });
        }
  
        request = request.clone({ headers: request.headers.set("Accept", "application/json") });
  
        // to add cookie
        // request = request.clone({
        //   withCredentials: true
        // });

        //spinner
        //disabled for messages sent in the chat with the endpoint 'send-push-notification'
        let endpoint = "api/v1/admin/send-push-notification";
        if(request.url && !(request.url.includes(endpoint))) {
            if(this.loader.isDelegatedToInterceptor()) {
                this.loader.show();
            }
        }

        // return next.handle(request).pipe(
        //     finalize(() => {
        //         this.loader.hide();
        //     })
        // );
        //end spinner
  
        // const req: Observable<HttpEvent<any>> = next.handle(request).pipe(share(), catchError((err) => {
        const req: Observable<HttpEvent<any>> = next.handle(request).pipe(
        catchError((err) => {
            if (err.status === 401 && !window.location.href.includes("/pages/login")) {
                localStorage.removeItem("currentUser");
                window.location.href = window.location.origin + "/pages/login";
            } else if(err.status === 422) {
                this.confirmDialogService.confirm(
                    this.messageFormatter.getErrorTitle(),
                    this.messageFormatter.getFormattedMessage(err)
                );
            } else { //generic error
                this.toastr.error("Problema di rete", err.error?.message, {
                    positionClass: 'toast-bottom-left'
                });
            }
            if(this.loader.isDelegatedToInterceptor()) {
                this.loader.hide();
            }
            return throwError(err);
        })).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
            if (evt instanceof HttpResponse) {
                if(this.loader.isDelegatedToInterceptor()) {
                    this.loader.hide();
                }
            }
            return evt;
        }));
        return req;
      }
}
