<div class="main-section">
    <div class="row justify-content-end">
        <app-associates-food *ngIf="currentUser.is_admin" [insidePlan]="false"></app-associates-food>
    </div>
    <div class="row">
        <div class="container-table-custom col-sm-12 mt-3">
            <ng2-smart-table [ngClass]="{'d-none': isLoadingTableData}" [settings]="settings" [source]="source" (rowSelect)="deselectFirstRow()"></ng2-smart-table>
            
            <ng-container *ngIf="isLoadingTableData">
                <div *ngFor="let i of toIterator(10)" class="skeleton-text mb-2"></div>
            </ng-container>
        </div>
    </div>
</div>

<!--
<ng-template #theModalUpdateFood let-d="dismiss">
    <div class="modal-header justify-content-between">
        <h6 class="modal-title">Modifica alimento: {{nameFood}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"><i class="now-ui-icons ui-1_simple-remove"></i></button>
    </div>
    <div class="modal-body">
        <p>Inserisci i valori nutrizionali per 100 g/ml</p>
        <form [formGroup]="formUpdateFood">
            <div class="row align-items-end">
                <div class="col-sm-8">
                    <div class="form-group bckg-body ">
                        <input type="text" formControlName="nameNewFood" id="nameNewFood" name="nameNewFood" class="form-control square-input" placeholder="Nome alimento"/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143" class="svg-icons primary form-group__svg">
                            <g transform="translate(4.433 3.071)"><path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571" transform="translate(-5.576 -3.071)"/>
                                <path class="a" d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z" transform="translate(-5.576 -3.071)"/>
                                <line class="a" y2="3.429" transform="translate(1.281 -1.928)"/><line class="a" y2="3.429" transform="translate(5.853 -1.928)"/>
                                <line class="a" y2="3.429" transform="translate(10.424 -1.928)"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4 wrap-multiselect">
                    <angular2-multiselect
                            name="typeFoodList"
                            [data]="typesFoodList"
                            [(ngModel)]="selectedItemstypesFood"
                            [settings]="dropdownSettingstypesFood"
                    >
                    </angular2-multiselect>
                </div>
                <div class="col-sm-4">
                    <div class="form-group bckg-body">
                        <input type="number" formControlName="caloriesNewFood" id="caloriesNewFood" name="caloriesNewFood" class="form-control square-input" placeholder="Calorie"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group bckg-body">
                        <input type="number" formControlName="carbohydratesNewFood" id="carbohydratesNewFood" name="carbohydratesNewFood" class="form-control square-input" placeholder="Carboidrati"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <angular2-multiselect
                            name="weightType"
                            [data]="weightTypeList"
                            [(ngModel)]="selectedItemsWeightType"
                            [settings]="dropdownSettingsWeightType"
                    >
                    </angular2-multiselect>
                </div>
                <div class="col-sm-4">
                    <div class="form-group bckg-body">
                        <input type="number" formControlName="proteinsNewFood" id="proteinsNewFood" name="proteinsNewFood" class="form-control square-input" placeholder="Proteine"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group bckg-body">
                        <input type="number" formControlName="fatsNewFood" id="fatsNewFood" name="fatsNewFood" class="form-control square-input" placeholder="Grassi"/>
                    </div>
                </div>
                <div class="col-sm-4"></div>
                <div class="col-sm-8">
                    <div class="form-group bckg-body w-100">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" formControlName="noteNewFood" id="noteNewFood" name="noteNewFood" rows="" cols="" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button class="btn btn-primary border-radius-large d-flex align-items-center justify-content-between w-100" (click)="addFood()">
                        <p class="m-0">Aggiungi</p>
                        <span class="plus">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs>
                    <g transform="translate(-944 -347)">
                        <line class="a" y2="12" transform="translate(951 348)"/>
                        <line class="a" x2="12" transform="translate(945 354)"/>
                    </g>
                </svg>
              </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>-->
