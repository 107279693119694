<div class="main-section sending-massive-notifications">
    <div class="col-sm-12">
        <form [formGroup]="sendingMassiveNotificationsForm" class="w-100" (ngSubmit)="sendNotification()">
            <div class="form-group bckg-body">
                <label for="notificationTitle">Titolo messaggio<sup>*</sup>:</label>
                <input type="text" name="notificationTitle" formControlName="notificationTitle" id="notificationTitle"
                    class="form-control with-shadow" required autofocus />
            </div>
            <div class="form-group bckg-body mt-3">
                <label for="notificationMsg">Testo messaggio<sup>*</sup>:</label>
                <textarea class="custom-textarea with-shadow" formControlName="notificationMsg" id="notificationMsg"
                    name="notificationMsg" rows="5" required></textarea>
            </div>
            <div class="card with-shadow mt-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-2 sending-massive-notifications-filter-row">
                        <!--<div class="checkAllBox">
                            <input role="button" type="checkbox" (change)="checkedAllRow($event)">
                            <span class="text-uppercase ml-2">Invia a tutti</span>
                        </div>-->
                        <div class="d-flex">
                            <div class="form-check form-check-radio">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="massive" id="massiveFalse"
                                        value="false" (change)="massiveSelected(false)" checked>
                                    <span class="form-check-sign"></span>
                                    Seleziona manualmente
                                </label>
                            </div>
                            <div class="form-check form-check-radio">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="massive" id="massiveTrue"
                                        value="true" (change)="massiveSelected(true)">
                                    <span class="form-check-sign"></span>
                                    Invia a tutti
                                </label>
                            </div>
                        </div>
                        <div class="d-flex">
                            <span class="mr-1">Righe per pagina:</span>
                            <select name="filter" id="filter" (change)="changeNumberOfRows($event.target.value)">
                                <option *ngFor="let n of perPageOptions" [value]="n">{{n}}</option>
                            </select>
                            <div class="filter-search ml-4">
                                <i class="now-ui-icons ui-1_zoom-bold"></i>
                                <input class="search" type="text" name="searchParam" placeholder="Cerca atleta"
                                    (keydown.enter)="onSearch($event)">
                            </div>
                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Nome</th>
                                <th scope="col">email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of tableRows" id="{{user.id}}">
                                <td>
                                    <input role="button" [id]="user.id" type="checkbox" [disabled]="massiveSelection"
                                        (change)="toggleRow($event, user.id)"
                                        [checked]="rowsChecked.has(user.id) || massiveSelection">
                                </td>
                                <td><span class="black-bold text-capitalize"><img src="../../../assets/img/user.png"
                                            alt="">{{user.name}}</span></td>
                                <td><span class="d-flex align-items-center text-primary"><i
                                            class="now-ui-icons ui-1_email-85 mr-2"></i>{{user.email}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <!--start pagination component-->
                    <nav *ngIf='!(currentPage === 1 && currentPage === totalPages)'
                        aria-label="Page navigation example">
                        <ul class="pagination justify-content-end">
                            <li class="page-item" [ngClass]="{'disabled': currentPage === 1}"
                                (click)="getTableData(currentPage-1)">
                                <a class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li *ngFor="let page of pages()" [ngClass]="{'active': currentPage === page+1}"
                                class="page-item" (click)="getTableData(page+1)"><a class="page-link">{{page+1}}</a>
                            </li>
                            <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages}"
                                (click)="getTableData(currentPage+1)">
                                <a class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <!--end pagination component-->

                    <ng-container *ngIf="isLoadingUserList">
                        <div *ngFor="let i of toIterator(10)" class="skeleton-text mb-2"></div>
                    </ng-container>
                </div>
            </div>
            <button class="btn btn-primary border-radius-small d-flex align-items-center float-right"
                [disabled]="(!sendingMassiveNotificationsForm.valid || !(rowsChecked.size > 0)) && (!massiveSelection || !sendingMassiveNotificationsForm.valid)">
                <span class="w-75 mr-5">Invia</span>
                <i class="fas fa-arrow-right"></i>
            </button>
        </form>
    </div>
</div>