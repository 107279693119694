<button class="btn btn-primary border-radius-large float-right pr-5" (click)="openModal()">
    <svg xmlns="http://www.w3.org/2000/svg" width="25.755" height="25.755" viewBox="0 0 25.755 25.755">
        <g id="Raggruppa_160157" data-name="Raggruppa 160157" transform="translate(24.34 24.34) rotate(180)">
            <rect id="Rettangolo_140008" data-name="Rettangolo 140008" width="9.242" height="22.458" rx="4.621" transform="translate(15.88 0.511) rotate(45)" fill="none" stroke="#fff" stroke-width="2"/>
            <path id="Rettangolo_140009" data-name="Rettangolo 140009" d="M4.947,0h0A4.947,4.947,0,0,1,9.895,4.947v7.074a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.947A4.947,4.947,0,0,1,4.947,0Z" transform="translate(15.93) rotate(45)" fill="none" stroke="#fff" stroke-width="2"/>
        </g>
    </svg>
    <p>Aggiungi piano <br> di integrazione</p>
</button>

<!-- Modal to choose how to assign food plan -->
<ng-template #theModal let-c="close" let-d="dismiss">
    <div class="modal-header" style="display: inline;">
        <h5 style="float: left; margin-top: 10px;">Aggiungi nuovo piano di integrazione</h5>
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-12 col-md-10 mx-auto">
            <button class="btn btn-primary border-radius-small w-100 mb-3 d-flex justify-content-between align-items-center" (click)="openModalToPassData()">
                <span class="btn__text">Personalizzato</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" class="svg-icons primary bg-white"><g class="a"><rect class="d" width="48" height="48" rx="24"/><rect class="e" x="1" y="1" width="46" height="46" rx="23"/></g><g transform="translate(14 14)"><g class="b"><rect class="d" width="20" height="20"/><rect class="e" x="0.5" y="0.5" width="19" height="19"/></g><g transform="translate(0.833 3.333)"><line class="c" y1="6.364" transform="translate(1.894 8.485)"/><line class="c" y1="6.364" transform="translate(1.894 -1.515)"/><line class="c" y1="8.182" transform="translate(9.167 6.667)"/><line class="c" y1="4.545" transform="translate(9.167 -1.515)"/><line class="c" y1="4.545" transform="translate(16.44 10.303)"/><line class="c" y1="8.182" transform="translate(16.44 -1.515)"/><line class="c" x2="5.455" transform="translate(-0.833 8.485)"/><line class="c" x2="5.455" transform="translate(6.44 3.031)"/><line class="c" x2="5.455" transform="translate(13.712 10.303)"/></g></g></svg>
            </button>
            <button type=" button" class="btn btn-primary border-radius-small w-100 d-flex justify-content-between align-items-center" (click)="closeModal(); openModalToAssigntemplate()">
                <span class="btn__text">Template</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"  class="svg-icons primary bg-white"><g class="a"><rect class="d" width="48" height="48" rx="24"/><rect class="e" x="1" y="1" width="46" height="46" rx="23"/></g><g transform="translate(14 14)"><rect style="fill:#fff;stroke: #fff;" width="20" height="20"/><g transform="translate(0.833 3.333)"><path class="c" d="M20.918,15.938V7.969a1.989,1.989,0,0,0-1-1.723L12.949,2.261a1.991,1.991,0,0,0-1.992,0L3.984,6.246a1.993,1.993,0,0,0-1,1.723v7.969a1.993,1.993,0,0,0,1,1.723l6.973,3.984a1.991,1.991,0,0,0,1.992,0l6.973-3.984A1.989,1.989,0,0,0,20.918,15.938Z" transform="translate(-2.786 -5.327)"/><path class="c" d="M3.257,6.933l8.7,5.03,8.7-5.03" transform="translate(-2.786 -5.327)"/><line class="c" y1="10.041" transform="translate(9.167 6.626)"/></g></g></svg>
            </button>
        </div>
    </div>
</ng-template>

<!-- Modal to choose template food plan -->
<ng-template #AssignTemplateModal let-c="close" let-d="dismiss">
    <div class="modal-header" style="display: inline;">
        <h5 style="float: left; margin-top: 10px;">Seleziona template da assegnare</h5>
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body pt-0">
        <div class="container-table-modal  pt-0">
            <ng2-smart-table *ngIf="templateSettings" [settings]="templateSettings" [source]="templateSource" (rowSelect)="deselectFirstRow()"></ng2-smart-table>
        </div>
    </div>
</ng-template>

<!-- Modal to assign template food plan -->
<ng-template #DataTemplateModal let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Assegna nuovo piano alimentare</h6>
    </div>
    <div class="modal-body">
        <form [formGroup]="dataTemplatePlan">
            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <input type="text" name="name" formControlName="name" id="name" class="form-control square-input" placeholder="Nome piano" required/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143" class="svg-icons primary form-group__svg">
                        <g transform="translate(4.433 3.071)"><path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571" transform="translate(-5.576 -3.071)"/>
                            <path class="a" d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z" transform="translate(-5.576 -3.071)"/>
                            <line class="a" y2="3.429" transform="translate(1.281 -1.928)"/><line class="a" y2="3.429" transform="translate(5.853 -1.928)"/>
                            <line class="a" y2="3.429" transform="translate(10.424 -1.928)"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <div class="col-12 px-0">
                        <div class="dp-hidden position-absolute">
                            <div class="input-group">
                                <input
                                        name="datepicker"
                                        class="invisible"
                                        ngbDatepicker
                                        #datepicker="ngbDatepicker"
                                        [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event, datepicker)"
                                        [displayMonths]="2"
                                        [dayTemplate]="t"
                                        outsideDays="hidden"
                                        [startDate]="fromDate!"
                                        tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                          [class.focused]="focused"
                                          [class.range]="isRange(date)"
                                          [class.faded]="isHovered(date) || isInside(date)"
                                          (mouseenter)="hoveredDate = date"
                                          (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-group">
                            <input
                                #dpFromDate
                                class="form-control square-input" placeholder="Inizio"
                                name="dpFromDate"
                                [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                (click)="datepicker.toggle()"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                <rect class="a" width="20" height="20"/>
                                <g transform="translate(2.5 1.667)">
                                    <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                    <line class="b" y2="3.333" transform="translate(10.833)"/>
                                    <line class="b" y2="3.333" transform="translate(4.167)"/>
                                    <line class="b" x2="15" transform="translate(0 6.667)"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="form-group">
                            <input
                                #dpToDate
                                class="form-control square-input" placeholder="Fine"
                                name="dpToDate"
                                [value]="formatter.format(toDate)"
                                (input)="toDate = validateInput(toDate, dpToDate.value)"
                                (click)="datepicker.toggle()"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                <rect class="a" width="20" height="20"/>
                                <g transform="translate(2.5 1.667)">
                                    <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                    <line class="b" y2="3.333" transform="translate(10.833)"/>
                                    <line class="b" y2="3.333" transform="translate(4.167)"/>
                                    <line class="b" x2="15" transform="translate(0 6.667)"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row align-items-end">
                <div class="col-sm-8 pr-0">
                    <div class="form-group bckg-body mr-1">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="note" formControlName="note" id="note"  rows="" cols="" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="assignFoodPlanToUser()">
                        <span class="btn__text m-0">Assegna</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
