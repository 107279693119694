import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any, search: number): any[] {
        if (!items) return [];
        if (!search) return items;
        items = items.data
        return items.filter(item => {
            if(item.hasOwnProperty("type") && item.type && item.type.id) //check exercise
                return item.type.id == search
            else
                return item.type_id == search;
        });
    }
}