<button
  class="btn btn-primary border-radius-small mb-4 d-flex align-items-center justify-content-between p-2 px-3 add-button pb-1"
  (click)="openModal('addSuperserieModal')">
  <p class="m-0" styel="font-size: 14px; font-weight:600">Crea superserie</p>
  <svg xmlns="http://www.w3.org/2000/svg" width="9.032" height="24.151" viewBox="0 0 9.032 24.151" class="svg-icons dark ml-1">
    <g transform="translate(123 3)">
      <g transform="translate(-122 -2)">
        <circle class="a" cx="3.516" cy="3.516" r="3.516" transform="translate(0 15.119)" />
        <circle class="a" cx="3.516" cy="3.516" r="3.516" transform="translate(0 0)" />
        <path class="b" d="M0,0V6.848" transform="translate(3.516 7.58)" />
      </g>
    </g>
  </svg>
</button>

<ng-template #addSuperserieModal let-c="close" let-d="dismiss">
  <div class="modal-header justify-content-between">
    <h6 class="modal-title">Aggiungi esercizi per creare una superserie</h6>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"><i class="now-ui-icons ui-1_simple-remove"></i></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-9">
        <!--<span class="badge badge-pill badge-outline mr-1 badge-type-food" (click)="loadIndexExercise()">Tutti</span>-->
        <span
          class="badge badge-pill badge-outline mr-1 badge-type-food"
          [class.active]="selectedGroup == item.id"
          *ngFor="let item of gruppiMuscolari"
          id="{{ item.id }}"
          (click)="loadIndexExercise(item.id)">
          {{ item.name }}
        </span>
      </div>
      <div class="col-sm-3">
        <button class="btn btn-primary border-radius-large m-0 w-100 pb-0" (click)="openModal('completeSuperserie')">
          <svg xmlns="http://www.w3.org/2000/svg" width="9.032" height="24.151" viewBox="0 0 9.032 24.151" class="svg-icons dark mb-1">
            <g transform="translate(123 3)">
              <g transform="translate(-122 -2)">
                <circle class="a" cx="3.516" cy="3.516" r="3.516" transform="translate(0 15.119)" />
                <circle class="a" cx="3.516" cy="3.516" r="3.516" transform="translate(0 0)" />
                <path class="b" d="M0,0V6.848" transform="translate(3.516 7.58)" />
              </g>
            </g>
          </svg>
          <p>
            Crea
            <br />
            superserie
          </p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <ng2-smart-table
          *ngIf="addSuperserie_Settings"
          [settings]="addSuperserie_Settings"
          [source]="addSuperserie_Source | filter : selectedGroup"
          (rowSelect)="deselectFirstRow()"></ng2-smart-table>
      </div>
      <div class="col-3 mt-3 pt-5">
        <small class="title-superset"><b>Esercizi Superserie inseriti</b></small>
        <div>
          <ul class="p-1">
            <!--                        <li *ngFor="let exercise of superserieList" class="exercise-superset d-flex justify-content-around align-items-center mt-1">-->
            <li *ngFor="let exercise of exerciseArrayRequest" class="exercise-superset d-flex justify-content-around align-items-center mt-1">
              <small class="text-truncate">
                {{ exercise.name }}
              </small>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.718" height="15.928" viewBox="0 0 20.718 19.928">
                <defs>
                  <style>
                    .aa {
                      fill: #fcfcfc;
                      opacity: 0;
                    }
                    .bb {
                      fill: none;
                      stroke: #fff;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-width: 2.201px;
                    }
                  </style>
                </defs>
                <g transform="translate(1.555)">
                  <rect class="aa" width="17.607" height="19.928" transform="translate(0.001)" />
                  <g transform="translate(0 3.171)"><path class="bb" d="M22.008,6.6,9.9,18.707,4.4,13.2" transform="translate(-4.401 -5.862)" /></g>
                </g>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoExercise let-c="close" let-d="dismiss">
  <div class="modal-header dark-modal border-top-rad">
    <h6 style="float: left; margin-top: 10px">{{ exerciseName }}</h6>
    <button type="button" class="close" (click)="d('Cross click')" style="color: #fff">
      <i class="now-ui-icons ui-1_simple-remove"></i>
    </button>
  </div>
  <div class="modal-body dark-modal border-bottom-rad">
    <form>
      <div class="my-2">
        <select
          class="dark-input"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="selectedSeries"
          (change)="selectSeries()"
          *ngIf="exerciseArrayRequest.length == 0">
          <option value="0" selected disabled>Numero di serie</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>

      <div *ngFor="let n of arraySelectedSeries; let i = index">
        <div class="row align-items-center mt-1">
          <div class="col-md-2">
            <input type="number" placeholder="Serie {{ i + 1 }}" readonly class="dark-input w-100" />
          </div>
          <div class="col-md-3">
            <div class="form-check w-100">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  [(ngModel)]="exercisesData[i].is_time"
                  [ngModelOptions]="{ standalone: true }"
                  type="checkbox"
                  id="s{{ i }}_time" />
                <span class="form-check-sign"></span>
                a tempo
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <input
              type="text"
              min="0"
              [(ngModel)]="exercisesData[i].repetitions"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Ripetizioni"
              class="mx-1 white-input w-100" />
          </div>
          <div class="col-md-4">
            <angular2-multiselect
              id="s{{ i }}_technique"
              [data]="dropdownListHowTo"
              [(ngModel)]="exercisesData[i].technique"
              [ngModelOptions]="{ standalone: true }"
              [settings]="dropdownSettingsHowTo"></angular2-multiselect>
          </div>
        </div>
      </div>

      <div class="form-group bckg-body mt-4">
        <label class="label-textarea">Note</label>
        <textarea
          class="custom-textarea bckg-body"
          name="note"
          formControlName="note"
          id="note"
          placeholder="Scrivi qui..."
          value="{{ exerciseNote }}"
          style="border: 0px"></textarea>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
          <g transform="translate(0 1)">
            <rect class="a" width="15" height="15" transform="translate(0 0)" />
            <g transform="translate(1.518 0)">
              <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)" />
              <path
                class="b"
                d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z"
                transform="translate(-3 -1.5)" />
            </g>
          </g>
        </svg>
      </div>

      <div class="mt-2 float-right">
        <!--                <button type="button" class="btn dark-btn border-radius-small" (click)="pushExerciseIntoSuperset()">-->
        <button type="button" class="btn dark-btn border-radius-small" (click)="assign()">
          <span class="btn__text m-0">Assegna</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #completeSuperserie let-c="close" let-d="dismiss">
  <div class="modal-header dark-modal border-top-rad">
    <h5 style="float: left; margin-top: 10px">Conferma superserie</h5>
    <button type="button" class="close" (click)="d('Cross click')" style="color: #fff">
      <i class="now-ui-icons ui-1_simple-remove"></i>
    </button>
  </div>
  <div class="modal-body dark-modal border-bottom-rad pt-0">
    <div class="row px-3 mb-2">
      <small style="color: #1a0707; border-bottom: 1px solid #1a0707" class="col-12 pl-1">Esercizi selezionati</small>
    </div>

    <div *ngFor="let exercise of exerciseArrayRequest" class="row px-3">
      <small class="col-5 text-truncate pt-1 pl-1" style="border-bottom: 1px solid #fff; font-weight: 600">{{ exercise.name }}:</small>
      <div class="d-flex col-7" style="border-bottom: 1px solid #fff">
        <div *ngFor="let serie of exercise.series">
          <span>{{ serie.is_time === true ? "Tempo" : "Ripetizioni" }}</span>
          <span class="px-2">{{ serie.repetitions }}</span>
        </div>
      </div>
      <div class="w-100 text-right">{{ exercise.note }}</div>
    </div>

    <form>
      <div class="mt-lg-4">
        <div class="d-flex justify-content-between align-items-center mt-1" *ngFor="let n of cooldownSeries; let i = index; let first = first">
          <input type="number" placeholder="Serie: {{ i + 1 }}" readonly class="dark-input" />
          <input
            *ngIf="first"
            type="number"
            placeholder="Recupero (in secondi)"
            name="s{{ i }}_cooldown"
            min="0"
            [(ngModel)]="cooldownSeries[i].cooldown"
            [ngModelOptions]="{ standalone: true }"
            class="mx-1 white-input w-75" />
        </div>
      </div>

      <div class="mt-3 float-right">
        <button type="button" class="btn dark-btn border-radius-small" (click)="confirmSuperserie()">
          <span class="btn__text m-0">Conferma</span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
