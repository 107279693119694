import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order = 'asc', column: string = ''): any[] {
        if (order.toLowerCase() == 'desc') {
            return value.sort((a,b) => (a[column] > b[column]) ? -1 : ((b[column] > a[column]) ? 1 : 0));
        }
        return value.sort((a,b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0));
    }
}
