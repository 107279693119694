import { Injectable } from '@angular/core';

interface imgCardState {
  name: string;
  newFile: {
    file: any,
    imageUploaded: any
  },
  url: string
}

@Injectable({
  providedIn: 'root'
})

export class ImageUploadService {

  private imageCardStates: imgCardState[] = [];

  constructor() { }

  isPresentImageCardState(name: string): boolean {
    return this.imageCardStates.some((item: imgCardState) => item.name === name);
  }

  getImageCardStateByName(name: string): imgCardState {
    return this.imageCardStates.find((item: imgCardState) => item.name === name);
  }

  saveImageCardState(name: string, url: string, newFile?: any): void {
    const newImgCardState: imgCardState = { name, url, newFile };
    this.removeImageCardState(name);
    this.imageCardStates.push(newImgCardState);
  }

  resetImageCardState() {
    this.imageCardStates = [];
  }

  removeImageCardState(name:string): void {
    this.imageCardStates = this.imageCardStates.filter((item: imgCardState) => {
      return !(item.name === name);
    });
  }

}
