<button class="btn btn-primary border-radius-large m-0 d-flex align-items-center justify-content-between btn-clone" (click)="openModalSession()">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons dark mb-1">
        <g transform="translate(-941.5 -862)">
            <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
            <line class="a" y2="10.597" transform="translate(946.465 863)"/>
            <line class="a" y2="10.597" transform="translate(958.535 863)"/>
            <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
            <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
        </g>
    </svg>
    <p class="mb-0 ml-2 small">Nuova sessione</p>
</button>

<ng-template #theModalSession let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Aggiungi nuova sessione all'allenamento</h6>
    </div>
    <div class="modal-body">
        <form [formGroup]="workoutPlanNewSession">
            <div class="row align-items-end">
                <div class="col-sm-8">
                    <div class="form-group bckg-body">
                        <input type="text" name="nameNewSession" formControlName="nameNewSession" id="nameNewSession" class="form-control square-input" placeholder="Nome piano allenamento" required/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons primary form-group__svg">
                            <g transform="translate(-941.5 -862)">
                                <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
                                <line class="a" y2="10.597" transform="translate(946.465 863)"/>
                                <line class="a" y2="10.597" transform="translate(958.535 863)"/>
                                <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
                                <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div class="col-sm-8 ">
                    <div class="form-group bckg-body">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="noteNewSession" formControlName="noteNewSession" id="noteNewSession" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="createNewSession()">
                        <span class="btn__text m-0">Aggiungi</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>