<button class="btn btn-primary border-radius-large float-right pr-5" (click)="openChoosePlan()">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons dark mb-1">
        <g transform="translate(-941.5 -862)">
            <line class="a" x2="12.07" transform="translate(946.465 868.299)"/>
            <line class="a" y2="10.597" transform="translate(946.465 863)"/>
            <line class="a" y2="10.597" transform="translate(958.535 863)"/>
            <line class="a" y2="5.376" transform="translate(962.5 865.611)"/>
            <line class="a" y2="5.376" transform="translate(942.5 865.611)"/>
        </g>
    </svg>
    <p>Aggiungi piano <br> di allenamento</p>
</button>

<!-- Modal to choose how to assign workout plan -->
<ng-template #theModalChoose let-c="close" let-d="dismiss">
    <div class="modal-header" style="display: inline;">
        <h6>Aggiungi nuovo piano di allenamento</h6>
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-12 col-md-10 mx-auto">
            <app-store-piano-allenamento [typePlanWorkout]="typePlanWorkout"></app-store-piano-allenamento>
            <button type=" button" class="btn btn-primary border-radius-small w-100 d-flex justify-content-between align-items-center" (click)="closeModal(); openModalToAssigntemplate()">
                <span class="btn__text">Template</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"  class="svg-icons primary bg-white"><g class="a"><rect class="d" width="48" height="48" rx="24"/><rect class="e" x="1" y="1" width="46" height="46" rx="23"/></g><g transform="translate(14 14)"><rect style="fill:#fff;stroke: #fff;" width="20" height="20"/><g transform="translate(0.833 3.333)"><path class="c" d="M20.918,15.938V7.969a1.989,1.989,0,0,0-1-1.723L12.949,2.261a1.991,1.991,0,0,0-1.992,0L3.984,6.246a1.993,1.993,0,0,0-1,1.723v7.969a1.993,1.993,0,0,0,1,1.723l6.973,3.984a1.991,1.991,0,0,0,1.992,0l6.973-3.984A1.989,1.989,0,0,0,20.918,15.938Z" transform="translate(-2.786 -5.327)"/><path class="c" d="M3.257,6.933l8.7,5.03,8.7-5.03" transform="translate(-2.786 -5.327)"/><line class="c" y1="10.041" transform="translate(9.167 6.626)"/></g></g></svg>
            </button>
            <button
                type=" button"
                class="btn btn-primary border-radius-small w-100 d-flex justify-content-between align-items-center"
                (click)="closeModal(); openModalPDF()">
                <span class="btn__text">PDF</span>
                <svg
                    fill="#A7061E"
                    height="48px"
                    width="48px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="-192.86 -192.86 867.86 867.86"
                    xml:space="preserve"
                    stroke="#A7061E"
                    stroke-width="0.0048214"
                    class="bg-white"
                    style="border-radius: 100%">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                    <g>
                        <path
                        d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69 c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"></path>
                        <path
                        d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394 c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"></path>
                        <path
                        d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404 c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223 c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796 c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646 c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811 c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748 c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233 c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282 c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222 C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42 v19.875H314.892z"></path>
                    </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</ng-template>

<!-- Modal to choose template workout plan -->
<ng-template #AssignTemplateModal let-c="close" let-d="dismiss">
    <div class="modal-header" style="display: inline;">
        <h5 style="float: left; margin-top: 10px;">Seleziona template da assegnare</h5>
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
    </div>
    <div class="modal-body pt-0">
        <div class="container-table-modal  pt-0">
            <ng2-smart-table *ngIf="templateSettings" [settings]="templateSettings" [source]="templateSource" (rowSelect)="deselectFirstRow()"></ng2-smart-table>
        </div>
    </div>
</ng-template>

<!-- Modal to assign template workout plan -->
<ng-template #DataTemplateModal let-d="dismiss">
    <div class="modal-header justify-content-between">
        <button type="button" class="close" (click)="d('Cross click')">
            <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <h6 class="title">Assegna nuovo piano di allenamento</h6>
    </div>
    <div class="modal-body">
        <form [formGroup]="dataTemplatePlan">
            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <input type="text" name="name" formControlName="name" id="name" class="form-control square-input" placeholder="Nome piano" required/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12.597" viewBox="0 0 22 12.597" class="svg-icons primary form-group__svg">
                        <g id="Raggruppa_160123" data-name="Raggruppa 160123" transform="translate(-941.5 -862)">
                          <line id="Linea_62" data-name="Linea 62" x2="12.07" transform="translate(946.465 868.299)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                          <line id="Linea_63" data-name="Linea 63" y2="10.597" transform="translate(946.465 863)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                          <line id="Linea_64" data-name="Linea 64" y2="10.597" transform="translate(958.535 863)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                          <line id="Linea_66" data-name="Linea 66" y2="5.376" transform="translate(962.5 865.611)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                          <line id="Linea_67" data-name="Linea 67" y2="5.376" transform="translate(942.5 865.611)" fill="none" stroke="#a7061e" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div class="col-sm-8 p-0">
                <div class="form-group bckg-body">
                    <div class="col-12 px-0">
                        <div class="dp-hidden position-absolute">
                            <div class="input-group">
                                <input 
                                    name="datepicker"
                                    class="invisible"
                                    ngbDatepicker
                                    #datepicker="ngbDatepicker"
                                    [autoClose]="'outside'"
                                    (dateSelect)="onDateSelection($event, datepicker)"
                                    [displayMonths]="2"
                                    [dayTemplate]="t"
                                    outsideDays="hidden"
                                    [startDate]="fromDate!"
                                    tabindex="-1">
                                <ng-template #t let-date let-focused="focused">
                                    <span class="custom-day"
                                        [class.focused]="focused"
                                        [class.range]="isRange(date)"
                                        [class.faded]="isHovered(date) || isInside(date)"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-group">
                            <input
                                #dpFromDate
                                class="form-control square-input" placeholder="Inizio"
                                name="dpFromDate"
                                [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                (click)="datepicker.toggle()"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                <rect class="a" width="20" height="20"/>
                                <g transform="translate(2.5 1.667)">
                                    <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                    <line class="b" y2="3.333" transform="translate(10.833)"/>
                                    <line class="b" y2="3.333" transform="translate(4.167)"/>
                                    <line class="b" x2="15" transform="translate(0 6.667)"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="col-12 px-0">
                        <div class="form-group">
                            <input
                                #dpToDate
                                class="form-control square-input" placeholder="Fine"
                                name="dpToDate"
                                [value]="formatter.format(toDate)"
                                (input)="toDate = validateInput(toDate, dpToDate.value)"
                                (click)="datepicker.toggle()"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                                <rect class="a" width="20" height="20"/>
                                <g transform="translate(2.5 1.667)">
                                    <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                                    <line class="b" y2="3.333" transform="translate(10.833)"/>
                                    <line class="b" y2="3.333" transform="translate(4.167)"/>
                                    <line class="b" x2="15" transform="translate(0 6.667)"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row align-items-end">
                <div class="col-sm-8 pr-0">
                    <div class="form-group bckg-body mr-1">
                        <label class="label-textarea">Note</label>
                        <textarea class="custom-textarea bckg-body" name="note" formControlName="note" id="note"  rows="" cols="" placeholder="Scrivi qui..." style="border:0px;"></textarea>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg-icons size-20 primary form-group__svg">
                            <g transform="translate(0 1)"><rect class="a" width="15" height="15" transform="translate(0 0)"/>
                                <g transform="translate(1.518 0)">
                                    <path class="b" d="M3,14.625A1.874,1.874,0,0,1,4.875,12.75H15" transform="translate(-3 -1.5)"/>
                                    <path class="b" d="M4.875,1.5H15v15H4.875A1.874,1.874,0,0,1,3,14.625V3.375A1.874,1.874,0,0,1,4.875,1.5Z" transform="translate(-3 -1.5)"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary border-radius-small w-100" (click)="assignFoodPlanToUser()">
                        <span class="btn__text m-0">Assegna</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<!-- Modal to add PDF -->
<ng-template #AssignPDFModal let-c="close" let-d="dismiss">
    <div class="modal-header" style="display: inline">
      <h5 style="float: left; margin-top: 10px">Aggiungi PDF</h5>
      <button type="button" class="close" (click)="d('Cross click')">
        <i class="now-ui-icons ui-1_simple-remove"></i>
      </button>
    </div>
    <div class="modal-body pt-0 fileinput">
      <div class="col-sm-8 p-0">
        <div class="form-group bckg-body">
          <input type="text" name="name" [(ngModel)]="PDFPlanName" id="name" class="form-control square-input" placeholder="Nome piano" required />
          <svg xmlns="http://www.w3.org/2000/svg" width="26.286" height="25.143" viewBox="0 0 26.286 25.143" class="svg-icons primary form-group__svg">
            <g transform="translate(4.433 3.071)">
              <path class="a" d="M20.571,9.143h1.143a4.571,4.571,0,1,1,0,9.143H20.571" transform="translate(-5.576 -3.071)" />
              <path
                class="a"
                d="M2.286,9.143H20.571V19.429A4.571,4.571,0,0,1,16,24H6.857a4.571,4.571,0,0,1-4.571-4.571Z"
                transform="translate(-5.576 -3.071)" />
              <line class="a" y2="3.429" transform="translate(1.281 -1.928)" />
              <line class="a" y2="3.429" transform="translate(5.853 -1.928)" />
              <line class="a" y2="3.429" transform="translate(10.424 -1.928)" />
            </g>
          </svg>
        </div>
      </div>

      <div class="col-sm-8 p-0">
        <div class="form-group bckg-body">
            <div class="col-12 px-0">
                <div class="dp-hidden position-absolute">
                    <div class="input-group">
                        <input 
                            name="datepicker"
                            class="invisible"
                            ngbDatepicker
                            #datepicker="ngbDatepicker"
                            [autoClose]="'outside'"
                            (dateSelect)="onDateSelection($event, datepicker)"
                            [displayMonths]="2"
                            [dayTemplate]="t"
                            outsideDays="hidden"
                            [startDate]="fromDate!"
                            tabindex="-1">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="form-group">
                    <input
                        #dpFromDate
                        class="form-control square-input" placeholder="Inizio"
                        name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                        (click)="datepicker.toggle()"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                        <rect class="a" width="20" height="20"/>
                        <g transform="translate(2.5 1.667)">
                            <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                            <line class="b" y2="3.333" transform="translate(10.833)"/>
                            <line class="b" y2="3.333" transform="translate(4.167)"/>
                            <line class="b" x2="15" transform="translate(0 6.667)"/>
                        </g>
                    </svg>
                </div>
                <div class="form-group">
                    <input
                        #dpToDate
                        class="form-control square-input" placeholder="Fine"
                        name="dpToDate"
                        [value]="formatter.format(toDate)"
                        (input)="toDate = validateInput(toDate, dpToDate.value)"
                        (click)="datepicker.toggle()"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"class="svg-icons size-20 primary form-group__svg" (click)="datepicker.toggle()">
                        <rect class="a" width="20" height="20"/>
                        <g transform="translate(2.5 1.667)">
                            <rect class="b" width="15" height="15" rx="2" transform="translate(0 1.667)" style="fill:none;stroke:#a7061e;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.667px;"/>
                            <line class="b" y2="3.333" transform="translate(10.833)"/>
                            <line class="b" y2="3.333" transform="translate(4.167)"/>
                            <line class="b" x2="15" transform="translate(0 6.667)"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="col-12 px-0">
            </div>
        </div>
    </div>
      <div class="d-flex align-items-center justify-content-start">
        <button id="file-btn" class="btn btn-primary btn-round btn-icon" (click)="fileInput.click()">
          <i class="now-ui-icons ui-1_simple-add"></i>
        </button>
        <label for="file-btn" class="font-weight-normal text-primary ml-2" style="margin: 0">{{ filePDFName ? filePDFName : "Scegli File" }}</label>
      </div>
      <input #fileInput type="file" (change)="onFileSelected($event)" accept=".pdf" style="display: none" />
    </div>
    <div class="col-sm-4">
      <button type="button" class="btn btn-primary border-radius-small w-100" (click)="uploadPDF()">
        <span class="btn__text m-0">Assegna</span>
      </button>
    </div>
  </ng-template>