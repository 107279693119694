import { MessageFormatterService } from "./../../services/messageFormatter.service";
import { NgbDateCustomParserFormatter } from "./../../dateformat";
import { ConfirmDialogService } from "./../confirm-dialog/confirm-dialog.service";
import { PianoAlimentareService } from "./../../pages/piano-alimentare/piano-alimentare.service";
import { ActionTableComponent } from "./../action-table/action-table.component";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal, NgbDateStruct, NgbDate, NgbCalendar, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ServerDataSource } from "ng2-smart-table";
import { Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-modal-food-plan",
  templateUrl: "./modal-food-plan.component.html",
  styleUrls: ["./modal-food-plan.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }, // formatta la data in dd/mm/yyyy
  ],
})
export class ModalFoodPlanComponent implements OnInit {
  @Output() updateList: EventEmitter<any> = new EventEmitter();
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  dataTemplatePlan: FormGroup;

  templateSource: ServerDataSource;
  templateSettings: any;

  subscriptions: Subscription[] = [];
  routeSub: Subscription;
  userId: number;
  foodPlanId: number;
  initialTemplateName: string;
  filePDF: File;
  PDFPlanName = "";
  filePDFName = null;
  @ViewChild("theModal") theModal: ElementRef;
  @ViewChild("AssignTemplateModal") AssignTemplateModal: ElementRef;
  @ViewChild("AssignPDFModal") AssignPDFModal: ElementRef;
  @ViewChild("DataTemplateModal") DataTemplateModal: ElementRef;
  @ViewChild("input") input: ElementRef;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private messageFormatter: MessageFormatterService,
    private pianoAlimentareService: PianoAlimentareService,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.routeSub = this.route.params.subscribe((params) => {
      this.userId = params["idUser"];
    });
    this.loadTemplateList();
    this.loadTableSettings();
    this.setupForm();
  }

  loadTableSettings() {
    this.templateSettings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      sort: false,
      columns: {
        name: {
          title: "Nome",
          filter: true,
        },
        dettaglio: {
          type: "custom",
          filter: false,
          sort: false,
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            const ref = this;
            instance.actionEmit.subscribe((value) => {
              if (value === "seleziona") {
                // ref.assignFoodPlanToUser(instance.rowData.id, instance.rowData.name);
                ref.openModalToPassData();
                this.foodPlanId = instance.rowData.id;
                this.initialTemplateName = instance.rowData.name;
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const ritorno = [
              {
                text: "seleziona",
                link: "",
                emit: "seleziona",
                title: "Seleziona template",
                btnId: "seleziona_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-edit ",
                classList: "action-btn btn",
              },
            ];
            return ritorno;
          },
        },
      },
    };
  }

  setupForm() {
    this.dataTemplatePlan = this.fb.group({
      name: new FormControl("", [Validators.required]),
      dpFromDate: new FormControl("", [Validators.required]),
      dpToDate: new FormControl("", [Validators.required]),
      note: new FormControl(""),
    });
  }

  loadTemplateList(): void {
    this.templateSource = this.pianoAlimentareService.getTemplate();
  }

  openModal() {
    this.modalService.open(this.theModal, {
      size: "md",
      backdrop: "static",
      windowClass: "mediumModal",
      centered: true,
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openModalToAssigntemplate() {
    this.modalService.open(this.AssignTemplateModal, {
      size: "md",
      backdrop: "static",
      windowClass: "mediumModal",
      centered: true,
    });
  }

  openModalPDF() {
    this.modalService.open(this.AssignPDFModal, {
      size: "md",
      backdrop: "static",
      windowClass: "mediumModal",
      centered: true,
    });
  }

  handleClick() {
    if (this.input) {
      this.input.nativeElement.value = "";
      this.input.nativeElement.click();
    }
  }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.filePDF = event.target.files[0];
      this.filePDFName = this.filePDF.name;
    }
  }

  async uploadPDF(): Promise<void> {
    if (typeof this.fromDate != "undefined") {
      const a = this.fromDate;
      let anno_from = a.year;
      let mese_from = a.month.toString();
      if (mese_from.length < 2) {
        mese_from = "0" + mese_from;
      }
      let giorno_from = a.day.toString();
      if (giorno_from.length < 2) {
        giorno_from = "0" + giorno_from;
      }
      var start = anno_from + "-" + mese_from + "-" + giorno_from;
    }

    if (typeof this.toDate != "undefined") {
      const b = this.toDate;
      let anno_to = b.year;
      let mese_to = b.month.toString();
      if (mese_to.length < 2) {
        mese_to = "0" + mese_to;
      }
      let giorno_to = b.day.toString();
      if (giorno_to.length < 2) {
        giorno_to = "0" + giorno_to;
      }
      var end = anno_to + "-" + mese_to + "-" + giorno_to;
    }
    let formData = new FormData();
    formData.append("pdf", this.filePDF, this.filePDF.name);
    formData.append("name", this.PDFPlanName);
    formData.append("pdf_template", "1");
    formData.append("start", start);
    formData.append("end", end);

    try {
      const sub = this.pianoAlimentareService.assignPDFFoodPlan(formData, this.userId).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
              positionClass: "toast-bottom-left",
              tapToDismiss: true,
            });
            this.modalService.dismissAll();
            this.updateList.emit(true);
          } else {
            this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
          }
        },
      });
      this.subscriptions.push(sub);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  }

  openModalToPassData() {
    this.modalService.open(this.DataTemplateModal, {
      size: "md",
      backdrop: "static",
      windowClass: "mediumModal",
      centered: true,
    });
  }

  assignFoodPlanToUser() {
    const name = this.dataTemplatePlan.get("name").value || null;
    const note = this.dataTemplatePlan.get("note").value || null;

    if (typeof this.fromDate != "undefined") {
      const a = this.fromDate;
      let anno_from = a.year;
      let mese_from = a.month.toString();
      if (mese_from.length < 2) {
        mese_from = "0" + mese_from;
      }
      let giorno_from = a.day.toString();
      if (giorno_from.length < 2) {
        giorno_from = "0" + giorno_from;
      }
      var start = anno_from + "-" + mese_from + "-" + giorno_from;
    }

    if (typeof this.toDate != "undefined") {
      const b = this.toDate;
      let anno_to = b.year;
      let mese_to = b.month.toString();
      if (mese_to.length < 2) {
        mese_to = "0" + mese_to;
      }
      let giorno_to = b.day.toString();
      if (giorno_to.length < 2) {
        giorno_to = "0" + giorno_to;
      }
      var end = anno_to + "-" + mese_to + "-" + giorno_to;
    }

    const request = this.buildRequest(Number(this.userId), name, start, end, note);

    if (this.foodPlanId > 0) {
      const sub = this.pianoAlimentareService.assignTemplateFoodPlan(request, this.foodPlanId).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
              positionClass: "toast-bottom-left",
              tapToDismiss: true,
            });
            this.router.navigate(["/dettaglio-utente/" + this.userId + "/gruppo-alimentare/" + res.data.id]);
          } else {
            this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
          }
        },
      });
      this.subscriptions.push(sub);
    } else {
      const sub = this.pianoAlimentareService.assignEmptyFoodPlan(request, this.userId).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.modalService.dismissAll();
            this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
              positionClass: "toast-bottom-left",
              tapToDismiss: true,
            });
            this.router.navigate(["/dettaglio-utente/" + this.userId + "/gruppo-alimentare/" + res.data.id]);
          } else {
            this.confirmDialogService.confirm(this.messageFormatter.getErrorTitle(), res.message);
          }
        },
      });
      this.subscriptions.push(sub);
    }
  }

  // convertStringToHTML(str:string) {
  // 	var parser = new DOMParser();
  // 	var doc = parser.parseFromString(str, 'text/html');
  // 	return doc.body;
  // };

  buildRequest(registry_id, name, start, end, note) {
    return {
      registry_id: registry_id,
      name: name,
      start: start,
      end: end,
      note: note,
    };
  }

  onDateSelection(date: NgbDate, datepicker: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  deselectFirstRow() {
    setTimeout(function () {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach((t) => {
        for (let i = 0, row; (row = t.rows[i]); i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }
}
