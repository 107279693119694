import { NgbDateCustomParserFormatter } from './../../dateformat';
import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-range-data',
  templateUrl: './range-data.component.html',
  styleUrls: ['./range-data.component.css'],
  providers: [
	{provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter} // formatta la data in dd/mm/yyyy
]
})
export class RangeDataComponent implements OnInit {


	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	@Input() startDate: any = '';
	@Input() endDate: any = '';


	constructor(
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter
	) {
	  this.fromDate = calendar.getToday();
	  this.toDate = calendar.getNext(calendar.getToday(), 'd', 30);
	}
	
	ngOnInit(): void {
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
		  this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
		  this.toDate = date;
		} else {
		  this.toDate = null;
		  this.fromDate = date;
		}
	}
	
	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
		date.before(this.hoveredDate);
	}
	
	isInside(date: NgbDate) { 
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}
	
	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
		this.isHovered(date);
	}
	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	onDateSelect(event){
		console.log(event);
	}

}
