<!--<nav
  class="navbar navbar-expand-lg navbar-transparent navbar-absolute bg-primary "
>-->
<nav class="navbar navbar-expand-lg bg-white navbar-fixed">
  <div class="container-fluid">
    <div class="navbar-wrapper">

      <div class="logo">
        <a class="simple-text logo-link" href="#">
          <div class="logo-mini">
            <img src="./assets/img/logo-mini.svg" />
          </div>
          <div class="logo-name">
            <img src="./assets/img/logo-name-dark.svg" />
          </div>
        </a>
        <div class="navbar-minimize">
          <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-neutral btn-round">
            <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
            <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
          </button>
        </div>
      </div>



      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>

      <div class="d-flex align-items-center">
        <button *ngIf="true" class="back-button py-2" [ngClass]="{'d-none': backBtnConf.disabled}"
          (click)="goToPage(backBtnConf.path)">
          <i class="now-ui-icons arrows-1_minimal-left"></i>
          <span class="pr-1">Indietro</span>
        </button>

        <a class="navbar-brand text-capitalize">{{title}}</a>
      </div>

    </div>

    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="!isCollapsed">

      <ul class="navbar-nav">
        <li class="nav-item">
          <a id="bellNotificationsSidebar" class="nav-link btn-notificationsSidebar" href="javascript:void(0)"
            (click)="openNav()">
            <i class="now-ui-icons ui-1_bell-53"></i>
            <span *ngIf="newNotifications === true" class="newNotifications"></span>
            <p><span class="d-lg-none d-md-block">Notifiche</span></p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" (click)="logout()">
            <i class="now-ui-icons arrows-1_share-66"></i>
            <p><span class="d-lg-none d-md-block">LogOut</span></p>
          </a>
        </li>

        <!-- <li class="nav-item dropdown" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <i class="now-ui-icons ui-1_bell-53"></i>
            <p><span class="d-lg-none d-md-block">Some Actions</span></p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
          >
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</nav>