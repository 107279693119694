<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
</div>
<div class="modal-footer float-right" *ngIf="title == 'COMPLIMENTI' || title == 'ATTENZIONE'">
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>
<div class="modal-footer" *ngIf="title != 'COMPLIMENTI' && title != 'ATTENZIONE'">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>
