import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  /**
   * 
   * @param url 
   * @param http: if url must include "http" or not 
   * @returns {boolean} true if url is correct, false otherwise
   */
  checkUrl(url: string, http: boolean = false): boolean {
    let httpPattern;

    if (http) {
      httpPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    } else {
      httpPattern = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
    }

    return httpPattern.test(url);
  }

}
