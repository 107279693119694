import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-table',
  templateUrl: './action-table.component.html',
  styleUrls: ['./action-table.component.css']
})
export class ActionTableComponent implements OnInit {

  @Output() actionEmit: EventEmitter<string> = new EventEmitter<string>();
  @Input() value: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  routeTo(e: any) {

    if (e.link) {
      this.router.navigate([e.link]);
    } else if (e.emit != null && e.emit !== "") {
      this.actionEmit.emit(e.emit);
    }

  }

}
