import { MessageFormatterService } from "./../../services/messageFormatter.service";
import { PianoAllenamentoService } from "./../../pages/piano-allenamento/piano-allenamento.service";
import { ActionTableComponent } from "./../action-table/action-table.component";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

// declare global {
// 	interface Window {
// 		test: any;
// 		data: any;
// 	}
// }

@Component({
  selector: "app-associate-exercise",
  templateUrl: "./associate-exercise.component.html",
  styleUrls: ["./associate-exercise.component.css"],
})
export class AssociateExerciseComponent implements OnInit, OnDestroy {
  //current user actually logged
  currentUser: any;

  @ViewChild("addExerciseModal") addExerciseModal: ElementRef;
  @ViewChild("selectExerciseModal") selectExerciseModal: ElementRef;
  addExercise_Settings: any;
  addExercise_Source: any;
  modalOpenedRef: NgbModalRef;
  selectExercise_Settings: any;

  @ViewChild("assignExerciseModal") assignExerciseModal: ElementRef;
  assignExerciseForm: FormGroup;
  exerciseTitleId: number;
  exerciseName: string;
  exerciseNote: string;
  exerciseId: number;
  exerciseTypeId: number;
  dropdownDisabled: boolean = false;
  @Input() workoutPlanId: number;

  exerciseForm: FormGroup;
  selectedSeries: any;
  arraySelectedSeries: any;
  gruppiMuscolari = [];
  subscriptions: Subscription[] = [];

  planWorkoutId: number;
  sessionActivated: number;

  // select Video HowTo
  dropdownListHowTo = [];
  dropdownSettingsHowTo = {};

  exercisesData = {};
  edit = false;
  exercises = [];
  selectedGroup = 0;

  notifierSubscription: Subscription = this.pianoAllenamentoService.subjectNotifier.subscribe((notified) => {
    this.loadExerciseList();
    this.modalService.dismissAll();
    this.openModal("addExerciseModal");
  });
  dropdownExerciseType: any;
  settingsExerciseType: any;
  mapTypesTitles: Map<string, any> = new Map();
  exTitles: any;

  constructor(
    private modalService: NgbModal,
    private pianoAllenamentoService: PianoAllenamentoService,
    private fb: FormBuilder,
    private messageFormatter: MessageFormatterService,
    private toastr: ToastrService
  ) {
    pianoAllenamentoService.planWorkoutId$.subscribe((val) => (this.planWorkoutId = val));
    pianoAllenamentoService.sessionActivated$.subscribe((val) => (this.sessionActivated = val));
    this.subscribeEditExercise();
  }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : "";
    this.selectExerciseSettings();
  }

  subscribeEditExercise() {
    let sub = this.pianoAllenamentoService.editExercises$.subscribe(async (val) => {
      if (val != null) {
        this.loadExerciseSettings();
        let videoList: any = await this.pianoAllenamentoService.listVideoHowTo().toPromise();
		if(videoList.success) this.dropdownListHowTo = videoList.data;		
        this.openModal("assignExerciseModal");
        this.edit = true;
        this.exerciseId = val.id;
        this.exerciseName = val.name;
        this.exerciseNote = val.note;
        this.selectedSeries = val.series.length;
        this.selectSeries();
        val.series.forEach((v, k) => {
          const splittedCooldown = v.cooldown.split(":");
          this.exercisesData[k] = {
            is_time: !!v.is_time,
            repetitions: v.repetitions,
            cooldown: splittedCooldown[0] * 60 + parseInt(splittedCooldown[1]),
            technique: this.dropdownListHowTo.filter((d) => d.id == v.howtovideo_id),
          };
        });

        this.pianoAllenamentoService.clearEditExercisesQueue();
        this.subscribeEditExercise();
      }
    });
    this.subscriptions.push(sub);
  }

  loadExerciseSettings() {
    this.addExercise_Settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      sort: false,
      columns: {
        name: {
          title: "Nome",
          filter: true,
        },
        dettaglio: {
          type: "custom",
          filter: false,
          sort: false,
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            const ref = this;
            instance.actionEmit.subscribe((value) => {
              if (value === "seleziona") {
                this.exerciseName = instance.rowData.name;
                this.exerciseNote = instance.rowData.note;
                this.exerciseId = instance.rowData.id;
                this.exerciseTypeId = instance.rowData.type?.id;
                this.dropdownDisabled = instance.rowData.type?.id == 13; //13 -> cardio type
                this.selectedSeries = this.exerciseTypeId == 13 ? 1 : 0;
                ref.openModal("assignExerciseModal");
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const ritorno = [
              {
                text: "seleziona",
                link: "",
                emit: "seleziona",
                title: "Seleziona esercizio",
                btnId: "seleziona_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-plus",
                classList: "action-btn btn",
              },
            ];
            return ritorno;
          },
        },
      },
    };
  }

  selectExerciseSettings() {
    this.selectExercise_Settings = {
      noDataMessage: "Nessun dato da visualizzare",
      mode: "external",
      hideSubHeader: false,
      actions: false,
      sort: false,
      columns: {
        name: {
          title: "Nome",
          filter: true,
        },
        dettaglio: {
          type: "custom",
          filter: false,
          sort: false,
          renderComponent: ActionTableComponent,
          onComponentInitFunction: (instance) => {
            instance.actionEmit.subscribe((value) => {
              if (value === "seleziona") {
                this.exerciseName = instance.rowData.name;
                this.exerciseTitleId = instance.rowData.id;
                this.modalOpenedRef.dismiss();
              }
            });
          },
          valuePrepareFunction: (cell, row) => {
            const ritorno = [
              {
                text: "selziona",
                link: "",
                emit: "seleziona",
                title: "Seleziona esercizio",
                btnId: "seleziona_" + row.id,
                stackIcon: false,
                stackClass: null,
                iconClassList: [],
                iconClass: "fas fa-plus",
                classList: "action-btn btn",
              },
            ];
            return ritorno;
          },
        },
      },
    };
  }

  loadExerciseList(): void {
    this.addExercise_Source = this.pianoAllenamentoService.getExercise();
  }

  loadIndexExercise(idType: number = null) {
    this.selectedGroup = idType;
  }

  loadTypesExercise() {
    this.pianoAllenamentoService.getTypesExercise().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.gruppiMuscolari = res.data;
          this.gruppiMuscolari.unshift({ id: 0, name: "Tutti" });
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
  }

  getDropdownData() {
    const sub = this.pianoAllenamentoService.listVideoHowTo().subscribe({
      next: (res: any) => {
        if (res.success) {
          this.dropdownListHowTo = res.data;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
    this.subscriptions.push(sub);
  }

  exerciseSelect() {
    this.openModal("selectExerciseModal");
  }

  initExercise() {
    return {
      is_time: this.exerciseTypeId == 13 ? true : false, //13 == cardio -> is_time = true
      repetitions: null,
      cooldown: null,
      technique: null,
    };
  }

  openModal(whichModal: string) {
    this.selectedGroup = 0;
    if (whichModal == "addExerciseModal") {
      this.modalService.open(this.addExerciseModal, { size: "lg", backdrop: "static", windowClass: "mediumModal", centered: true });
      this.loadExerciseSettings();
      this.loadExerciseList();
      this.loadTypesExercise();
    } else if (whichModal == "selectExerciseModal") {
      this.modalOpenedRef = this.modalService.open(this.selectExerciseModal, {
        size: "lg",
        backdrop: "static",
        windowClass: "mediumModal",
        centered: true,
      });
      this.loadExerciseSettings();
      this.loadExerciseList();
      this.loadTypesExercise();
    } else if (whichModal == "assignExerciseModal") {
      this.edit = false;
      this.exercisesData = {};
      this.modalService.open(this.assignExerciseModal, { size: "xl", backdrop: "static", windowClass: "largeModal", centered: true });
      this.dropdownSettingsHowTo = {
        singleSelection: true,
        text: "Tecnica",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        enableSearchFilter: false,
        classes: "custom-input-fill",
        lazyLoading: true,
        position: "bottom",
        tagToBody: false,
        autoPosition: false,
        maxHeight: "300",
        primaryKey: "id",
        labelKey: "name",
        disabled: this.dropdownDisabled,
      };
      this.getDropdownData();
      this.selectedSeries = this.exerciseTypeId == 13 ? 1 : 0;
      this.selectSeries();
    }
  }

  deselectFirstRow() {
    setTimeout(function () {
      const tabelle = Array.from(document.getElementsByTagName("table"));
      tabelle.forEach((t) => {
        for (let i = 0, row; (row = t.rows[i]); i++) {
          if (row.classList.contains("selected")) {
            row.classList.remove("selected");
          }
        }
      });
    }, 200);
  }

  selectSeries() {
    this.arraySelectedSeries = [...Array(parseInt(this.selectedSeries)).keys()];
    this.arraySelectedSeries.forEach((i) => {
      if (!this.exercisesData.hasOwnProperty(i)) {
        this.exercisesData[i] = this.initExercise();
      }
    });
    const exceeded = Object.keys(this.exercisesData).filter((k) => !this.arraySelectedSeries.includes(parseInt(k)));
    exceeded.forEach((k) => delete this.exercisesData[k]);
  }

  getRequest() {
    const series = Object.values(this.exercisesData).map((v: any) => {
      if (v.technique != null && v.technique.length > 0) {
        v["howtovideo_id"] = v.technique[0].id;
        delete v["technique"];
      }
      v.cooldown = this.exercisesData[0].cooldown || 0; //for cardio exercise cooldown is 0
      return v;
    });

    const note = document.getElementById("note") as HTMLInputElement | null;
    const valueNote = note?.value;

    let request = {
      exercise_id: this.exerciseId,
      series: series,
      note: valueNote,
    };
    return request;
  }

  assignExercise() {
    let request = this.getRequest();

    const sub = this.pianoAllenamentoService.assignExerciseToUser(this.planWorkoutId, this.sessionActivated, request).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.toastr.success(res.message, "Operazione eseguita con successo!", {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          });
          this.pianoAllenamentoService.notifyAboutChange(); // caricare lista esercizi assegnati ad utente
          this.modalService.dismissAll();
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }

  editExerciseTitle() {
    if (!this.exerciseTitleId) return;
    let request = {
      exercise_id: this.exerciseTitleId,
    };

    const sub = this.pianoAllenamentoService.updateExerciseTitle(this.workoutPlanId, this.sessionActivated, this.exerciseId, request).subscribe({
      next: (res: any) => {
        this.exerciseTitleId = null;
      },
      error: (err) => {},
    });
    this.subscriptions.push(sub);
  }

  editExerciseData() {
    let request = this.getRequest();

    const sub = this.pianoAllenamentoService.updateExercise(request, this.workoutPlanId, this.sessionActivated, this.exerciseId).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.toastr.success(res.message, this.messageFormatter.getSuccessTitle(), {
            positionClass: "toast-bottom-left",
            tapToDismiss: true,
          });
          this.pianoAllenamentoService.notifyAboutChange(); // caricare lista esercizi assegnati ad utente
          this.modalService.dismissAll();
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, this.messageFormatter.getErrorTitle(), {
          positionClass: "toast-bottom-left",
          tapToDismiss: true,
        });
      },
    });
    this.subscriptions.push(sub);
  }

  editExercise() {
    this.editExerciseData();
    this.editExerciseTitle();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
