import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageFormatterService {

  private successTitle: string = 'Ok!';
  private errorTitle: string = 'Errore!';

  constructor() { }

  getSuccessTitle():string {
    return this.successTitle;
  }

  getErrorTitle():string {
    return this.errorTitle;
  }

  getFormattedMessage(err, html: boolean = false):string {
    let message = err.error.message + (html ? ": </br>" : ": ");
    if (err.error.data) {
      for (const error in err.error.data.errors) {
        if (typeof (err.error.data.errors[error]) !== "undefined") {
          message += err.error.data.errors[error][0] + (html ? ": </br>" : " ");
        }
      }
    }
    return message;
  }

}
